import {
  ClubViewModal,
  CustomerDetailModal,
  PosOrderDetailModal,
  StoreCard,
  StoresModal,
} from "@sk/features";
import { useFetchUrlQueryString } from "@sk/hooks";
import { FranchiseService, NavService, RolesService } from "@sk/services";
import { DateInput, PageInfo, PageLoader, Tabs, Toaster } from "@sk/uis";
import { format, startOfMonth } from "date-fns";
import produce from "immer";
import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import PosCashManagement from "../components/cash-management/PosCashManagement";
import ClubRequests from "../components/club-requests/ClubRequests";
import OrderMonitor from "../components/order-monitor/OrderMonitor";
import PosCustomerAnalytics from "../components/pos-orders/PosCustomerAnalytics";
import PosOrders from "../components/pos-orders/PosOrders";
import PosReport from "../components/pos-report/PosReport";
import PosReturns from "../components/pos-returns/PosReturns";
import PosAnalyticsFeature from "./components/PosAnalyticsFeature";
import PosAnalyticsPayment from "./components/PosAnalyticsPayment";
import PosAnalyticsSummary from "./components/PosAnalyticsSummary";
import ExpenseList from "../components/expenses/ExpenseList";

const breadcrumbs = [{ name: "Home", link: "/" }, { name: "Sales Analytics" }];

const dateRangeConfig = {
  mode: "range",
  maxDate: new Date(),
  defaultDate: [
    format(startOfMonth(new Date()), "yyyy-MM-dd"),
    format(new Date(), "yyyy-MM-dd"),
  ],
};

const defaultMainTabs = [
  {
    key: "sales",
    tabName: "All Sales",
    description:
      "View sales analytics for both POS and club orders, providing insights into sales performance across different platforms.",
  },
  {
    key: "customers",
    tabName: "Customers Analytics",
    description:
      "This section provides customer analytics for the selected store, focusing on insights related to coin rewards, which are earned exclusively from orders.",
  },
  {
    key: "clubRequests",
    tabName: "Club Requests",
  },
  {
    key: "quickCommerce",
    tabName: "Quick Commerce",
    description: "View quick commerce analytics",
  },
  {
    key: "orderMonitor",
    tabName: "Order Monitor",
    description: "Monitor and track the status of orders in real-time.",
  },
  {
    key: "clubOrders",
    tabName: "Club Orders",
    description: "View orders placed by customers through the club app",
  },
  {
    key: "orders",
    tabName: "POS Orders",
    description: "View orders placed through the POS application",
  },
  {
    key: "cashManagement",
    tabName: "Payment Collection",
    description: "",
  },
  {
    key: "returns",
    tabName: "Returns",
    description: "View the list of product returns",
  },
  {
    key: "quickCommerceReturn",
    tabName: "Returns",
    description: "View the list of quick commerce product returns",
  },
  {
    key: "reports",
    tabName: "Reports",
    description: "Access detailed reports and analytics for store performance",
  },
  {
    key: "storeExpense",
    tabName: "Store Expenses",
    description: "View the list of store expenses",
  },
];

const PosSalesAnalytics = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const queryParams = useFetchUrlQueryString(searchParams);

  const { control } = useForm({
    defaultValues: {
      dateRange: dateRangeConfig.defaultDate,
    },
  });

  const [mainTabs, setMainTabs] = useState([...defaultMainTabs]);
  const [activeMainTab, setActiveMainTab] = useState(defaultMainTabs[0]);
  const [activeTab, setActiveTab] = useState({ key: "orders" });
  const [showModal, setShowModal] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null);
  const [loading, setLoading] = useState(true);
  const [accessibleStores, setAccessibleStores] = useState([]);
  const [noAccess, setNoAccess] = useState(false);

  const [orderDetailModal, setOrderDetailModal] = useState({
    orderId: null,
    show: false,
  });

  const [customerDetailModal, setCustomerDetailModal] = useState({
    cid: null,
    show: false,
  });

  const [clubViewModal, setClubViewModal] = useState({
    cid: null,
    show: false,
  });

  const [activeModal, setActiveModal] = useState(null);

  const [dateRange] = useWatch({
    name: ["dateRange"],
    control,
  });

  const tabData = [
    { key: "orders", tabName: "Orders" },
    { key: "customers", tabName: "Customers" },
  ];

  useEffect(() => {
    const fetchFranchise = async () => {
      const fid = queryParams.fid;

      // Fetch accessible stores
      const { stores } = await RolesService.getDashboardEnabledStores(
        "DarkStoreSalesDashboard"
      );
      setAccessibleStores(stores);

      // Set main active tab based on queryParams.tab
      const initialMainTabKey = queryParams.tab || "sales"; // Default to "sales" if no tab is specified
      const initialMainTab =
        defaultMainTabs.find((tab) => tab.key === initialMainTabKey) ||
        defaultMainTabs[0];
      setActiveMainTab(initialMainTab);

      if (fid && stores.indexOf(fid) === -1) {
        setSelectedStore(null);
        setNoAccess(true);
        setLoading(false);
        return;
      } else {
        setNoAccess(false);
      }

      if (fid) {
        setLoading(true);
        try {
          const params = {
            select:
              "name,town,district,state,pincode,sk_franchise_details,posFlow",
          };
          const response = await FranchiseService.getFranchise(fid, params);
          if (response.statusCode === 200) {
            setSelectedStore(response.resp);
            const filteredTabs = produce(defaultMainTabs, (draft) => {
              if (!response.resp.posFlow.allowClubAppCustomerRequest) {
                return draft.filter(
                  (tab) =>
                    tab.key !== "cashManagement" &&
                    tab.key !== "clubRequests" &&
                    tab.key !== "clubOrders" &&
                    tab.key !== "orders" &&
                    tab.key !== "returns"
                );
              } else {
                return draft.filter(
                  (tab) =>
                    tab.key !== "quickCommerce" &&
                    tab.key !== "quickCommerceReturn" &&
                    tab.key !== "orderMonitor"
                );
              }
            });
            setMainTabs(filteredTabs);
          } else {
            console.error("Failed to fetch franchise:", response);
          }
        } catch (error) {
          console.error("Error fetching franchise:", error);
        } finally {
          setLoading(false);
        }
      } else {
        setSelectedStore(null);
        setLoading(false);
      }
    };

    fetchFranchise();
  }, [queryParams.fid, queryParams.tab]);

  const handleStoreSelect = (store) => {
    setSelectedStore(store);
    setShowModal(true);
  };

  const handleModalCallback = (action) => {
    if (action && action.action === "selectedStore") {
      const store = action.store[0] || {};
      setSelectedStore(store);
      NavService.to(navigate, "/pos/sales-analytics", {
        fid: store._id,
      });
    }
    setShowModal(false);
    setActiveMainTab(defaultMainTabs[0]);
  };

  const handleTabChange = (selectedTab) => {
    setActiveTab(selectedTab.value);
  };

  const chooseStore = () => {
    if (!accessibleStores.length) {
      Toaster.error("You are not authorized to access any store");
      return;
    }
    setShowModal(true);
  };

  const handleMainTabChange = (selectedTab) => {
    setActiveMainTab(selectedTab.value);
  };

  const onOrderDetailModalCb = (data) => {
    setOrderDetailModal({ show: false });
    if (data.action === "viewCustomer") {
      setActiveModal("customer");
      setCustomerDetailModal({ show: true, cid: data.id });
    } else {
      setActiveModal(null);
    }
  };

  const onCustomerDetailModalCb = (data) => {
    if (data.action === "viewClub") {
      setActiveModal("club");
      setClubViewModal({ show: true, cid: data.id });
    } else if (data.action === "viewOrder") {
      setActiveModal("order");
      setOrderDetailModal({ show: true, orderId: data.id });
    } else {
      setActiveModal(null);
      setCustomerDetailModal({ show: false });
    }
  };

  const onClubViewModalCb = (data) => {
    if (data.action === "viewCustomer") {
      setClubViewModal({ show: false });
      setActiveModal("customer");
      setCustomerDetailModal({ show: true, cid: data.id });
    } else if (data.action === "viewOrder") {
      setActiveModal("order");
      setOrderDetailModal({ show: true, orderId: data.id });
    } else {
      setActiveModal(null);
      setClubViewModal({ show: false });
    }
  };

  const cb = (data) => {
    if (data.action === "viewCustomer") {
      setActiveModal("customer");
      setCustomerDetailModal({ show: true, cid: data.id });
    }
    if (data.action === "viewOrder") {
      setActiveModal("order");
      setOrderDetailModal({ show: true, orderId: data.id });
    }
    if (data.action === "viewClub") {
      setActiveModal("club");
      setClubViewModal({ show: true, cid: data.id });
    }
  };

  return (
    <div>
      <PageInfo
        title="Sales Analytics"
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />

      {loading ? (
        <PageLoader />
      ) : noAccess ? (
        <div className="store-selection text-center my-5 p-5 border rounded shadow-sm bg-light">
          <div className="container py-4 px-3 max-w-md mx-auto">
            <i className="bi bi-shop fs-1 text-primary mb-4 d-block"></i>
            <h4 className="mb-3 fw-bold text-dark">
              You are not authorized to access any store
            </h4>
          </div>
        </div>
      ) : selectedStore === null ? (
        <div className="store-selection text-center my-5 p-5 border rounded shadow-sm bg-light">
          <div className="container py-4 px-3 max-w-md mx-auto">
            <i className="bi bi-shop fs-1 text-primary mb-4 d-block"></i>
            <h4 className="mb-3 fw-bold text-dark">Select Your Store</h4>
            <p className="text-muted mb-4 fs-6">
              To view sales analytics, please select a store from the available
              options.
            </p>
            <button
              className="btn btn-primary btn-lg px-4 py-2 shadow-sm"
              onClick={chooseStore}
            >
              <i className="bi bi-building me-2"></i>
              Choose Store
            </button>
          </div>
        </div>
      ) : (
        <>
          <StoreCard
            fid={queryParams.fid}
            navigate={navigate}
            showViewAnalytics={false}
            showViewInventory={true}
            rbacKey="DarkStoreSalesDashboard"
          />

          <div className="mb-3">
            <Tabs
              data={mainTabs}
              activeTab={activeMainTab}
              callback={handleMainTabChange}
              template={4}
              shadow={true}
            />
          </div>

          <div className="mb-3">
            <div className="text-muted fs-val-sm">
              {activeMainTab.description}
            </div>
          </div>

          {activeMainTab.key === "sales" && (
            <>
              <div className="row mb-3 align-items-end">
                <div className="col">
                  <Tabs
                    data={tabData}
                    activeTab={activeTab}
                    callback={handleTabChange}
                    template={3}
                  />
                </div>
                <div className="col-3 ms-auto">
                  <Controller
                    name="dateRange"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <DateInput
                        placeholder="Select Date Range"
                        value={value}
                        callback={onChange}
                        config={dateRangeConfig}
                        gap={0}
                        hideClose={true}
                        template={2}
                      />
                    )}
                  />
                </div>
              </div>

              <PosAnalyticsSummary
                storeId={selectedStore._id}
                fromDate={dateRange[0]}
                toDate={dateRange[1]}
                tabKey={activeTab.key}
              />

              <div className="mb-2">
                <PosAnalyticsPayment
                  storeId={selectedStore._id}
                  storeName={selectedStore.name}
                  fromDate={dateRange[0]}
                  toDate={dateRange[1]}
                />
              </div>

              <div className="row">
                <div className="col-4">
                  <PosAnalyticsFeature
                    storeId={selectedStore._id}
                    storeName={selectedStore.name}
                    fromDate={dateRange[0]}
                    toDate={dateRange[1]}
                    type="menu"
                  />
                </div>

                <div className="col-4">
                  <PosAnalyticsFeature
                    storeId={selectedStore._id}
                    storeName={selectedStore.name}
                    fromDate={dateRange[0]}
                    toDate={dateRange[1]}
                    type="category"
                  />
                </div>

                <div className="col-4">
                  <PosAnalyticsFeature
                    storeId={selectedStore._id}
                    storeName={selectedStore.name}
                    fromDate={dateRange[0]}
                    toDate={dateRange[1]}
                    type="brand"
                  />
                </div>

                <div className="col-12">
                  <PosAnalyticsFeature
                    storeId={selectedStore._id}
                    storeName={selectedStore.name}
                    fromDate={dateRange[0]}
                    toDate={dateRange[1]}
                    type="product"
                  />
                </div>
              </div>
            </>
          )}

          {activeMainTab.key === "customers" && (
            <PosCustomerAnalytics storeId={selectedStore._id} callback={cb} />
          )}

          {activeMainTab.key === "orders" && (
            <PosOrders storeId={selectedStore._id} type="pos" callback={cb} />
          )}

          {activeMainTab.key === "clubRequests" && (
            <ClubRequests storeId={selectedStore._id} callback={cb} />
          )}

          {activeMainTab.key === "quickCommerce" && (
            <ClubRequests
              storeId={selectedStore._id}
              callback={cb}
              type="quick"
            />
          )}

          {activeMainTab.key === "clubOrders" && (
            <PosOrders storeId={selectedStore._id} type="club" callback={cb} />
          )}

          {activeMainTab.key === "cashManagement" && (
            <PosCashManagement storeId={selectedStore._id} />
          )}

          {activeMainTab.key === "returns" && (
            <PosReturns storeId={selectedStore._id} callback={cb} />
          )}

          {activeMainTab.key === "quickCommerceReturn" && (
            <PosReturns
              storeId={selectedStore._id}
              callback={cb}
              type="quick"
            />
          )}

          {activeMainTab.key === "reports" && (
            <PosReport storeId={selectedStore._id} />
          )}

          {activeMainTab.key === "orderMonitor" && (
            <OrderMonitor storeId={selectedStore._id} callback={cb} />
          )}

          {activeMainTab.key === "storeExpense" && (
            <ExpenseList storeId={selectedStore._id} callback={cb} />
          )}
        </>
      )}

      <StoresModal
        show={showModal}
        callback={handleModalCallback}
        onlyAssignedStore={true}
        accessibleStoreIds={accessibleStores}
        rbackKey="DarkStoreSalesDashboard"
      />

      <PosOrderDetailModal
        orderId={orderDetailModal.orderId}
        show={orderDetailModal.show}
        callback={onOrderDetailModalCb}
      />

      <CustomerDetailModal
        cid={customerDetailModal.cid}
        show={customerDetailModal.show}
        callback={onCustomerDetailModalCb}
        noView={activeModal === "order" || activeModal === "club"}
      />

      <ClubViewModal
        cid={clubViewModal.cid}
        show={clubViewModal.show}
        callback={onClubViewModalCb}
        noView={activeModal === "order" || activeModal === "customer"}
      />
    </div>
  );
};

export default PosSalesAnalytics;
