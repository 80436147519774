import { DateInput, EntitySearchInput, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import PurchaseBasketAdvFilterModal from "../modals/PurchaseBasketAdvFilterModal";

const statusOptions = [
  { value: "", label: "All" },
  { value: "Open", label: "Open" },
  { value: "Closed", label: "Closed" },
];

const PurchaseBasketFilter = ({ callback, filterData }) => {
  const [showFilterModal, setShowFilterModal] = useState(false);
  const { register, getValues, control, setValue } = useForm({
    defaultValues: {
      search: "",
      employee: [],
      dateRange: [],
      status: "",
      menu: [],
      brand: [],
      category: [],
    },
  });

  useEffect(() => {
    setValue("employee", filterData.employee);
    setValue("dateRange", filterData.dateRange);
    setValue("status", filterData.status);
    setValue("menu", filterData.menu);
    setValue("brand", filterData.brand);
    setValue("category", filterData.category);
  }, [filterData, setValue]);

  const onSearch = useCallback(
    debounce(() => {
      triggerCallback();
    }, 800),
    [callback]
  );

  const handleEmployeeChange = (field) => (value) => {
    field.onChange(value);
    triggerCallback();
  };

  const handleDateRangeChange = (field) => (value) => {
    field.onChange(value);
    triggerCallback();
  };

  const handleStatusChange = () => {
    triggerCallback();
  };

  const triggerCallback = () => {
    callback({ formData: getValues() });
  };

  const handleMoreFilterClick = () => {
    setShowFilterModal(true);
  };

  const handleModalCallback = ({ action, formData }) => {
    if (action === "apply") {
      setValue("menu", formData.menu);
      setValue("brand", formData.brand);
      setValue("category", formData.category);
      triggerCallback();
    }
    setShowFilterModal(false);
  };

  return (
    <div>
      <div className="row">
        <div className="col-3">
          <TextInput
            label="Search"
            placeholder="Search..."
            register={register}
            name="search"
            callback={onSearch}
            size="sm"
            gap={2}
          />
        </div>
        <div className="col-3">
          <Controller
            name="employee"
            control={control}
            render={({ field }) => (
              <EntitySearchInput
                type="employee"
                label="Employee"
                uid="employee-input"
                value={field.value}
                callback={handleEmployeeChange(field)}
                isMultiple={false}
                size="sm"
                gap={2}
              />
            )}
          />
        </div>
        <div className="col-3">
          <Controller
            name="dateRange"
            control={control}
            render={({ field }) => (
              <DateInput
                label="Date Range"
                value={field.value}
                callback={handleDateRangeChange(field)}
                template={2}
                size="sm"
                gap={2}
              />
            )}
          />
        </div>
        <div className="col-2">
          <SelectInput
            label="Status"
            name="status"
            register={register}
            options={statusOptions}
            callback={handleStatusChange}
            size="sm"
            gap={2}
          />
        </div>

        <div className="col-1 align-self-end">
          <button
            className="btn btn-outline-primary btn-sm mb-2"
            onClick={handleMoreFilterClick}
          >
            <i className="bi bi-funnel"></i> More Filter
          </button>
        </div>
      </div>
      <PurchaseBasketAdvFilterModal
        show={showFilterModal}
        callback={handleModalCallback}
        formData={getValues()}
      />
    </div>
  );
};

export default PurchaseBasketFilter;
