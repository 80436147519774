import { PosService } from "@sk/services";
import {
  differenceInMilliseconds,
  endOfDay,
  formatDistanceToNow,
  startOfDay,
} from "date-fns";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import "../../../scss/_kanban.scss";
import OrderMonitorItem from "./OrderMonitorItem";
import { DateInput, Spinner } from "@sk/uis";

const containerStyle = {
  overflowY: "auto",
};

const getTimeLeft = (deliveryTime) => {
  const now = new Date();
  const delivery = new Date(deliveryTime);
  if (isNaN(delivery.getTime())) {
    return {
      isDelayed: false,
      str: "Invalid date",
    };
  }
  const diff = differenceInMilliseconds(delivery, now);
  const minutes = Math.floor(Math.abs(diff) / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const seconds = Math.floor((Math.abs(diff) % 60000) / 1000);
  const secondsStr = seconds > 0 ? ` ${seconds} secs` : "";

  let timeStr = "";
  if (days > 0) {
    timeStr = `${days} day${days > 1 ? "s" : ""}`;
  } else if (hours > 0) {
    timeStr = `${hours} hour${hours > 1 ? "s" : ""}`;
  } else {
    timeStr = `${minutes} min${minutes !== 1 ? "s" : ""}`;
  }

  return {
    isDelayed: diff <= 0,
    str: `${diff > 0 ? "" : "-"}${timeStr}`,
  };
};

const formatResponse = (item, type) => {
  let timeDisplay = "";
  let isDelayed = false;

  if (type === "newOrders") {
    timeDisplay = formatDistanceToNow(new Date(item.createdAt), {
      addSuffix: true,
    });
  } else if (type === "unassignedOrders") {
    const r = getTimeLeft(item.createdAt);
    timeDisplay = r.str;
    isDelayed = r.isDelayed;
  } else if (type === "picking") {
    const r = getTimeLeft(item.pickingEstimationTime);
    timeDisplay = r.str;
    isDelayed = r.isDelayed;
  } else if (type === "yetToStartPacking") {
    const r = getTimeLeft(item.packingEstimationTime);
    timeDisplay = r.str;
    isDelayed = r.isDelayed;
  } else if (type === "packing") {
    const r = getTimeLeft(item.packingEstimationTime);
    timeDisplay = r.str;
    isDelayed = r.isDelayed;
  } else if (type === "readyToShip") {
    const r = getTimeLeft(item.readyToShipEstimationTime);
    timeDisplay = r.str;
    isDelayed = r.isDelayed;
  }

  timeDisplay = timeDisplay.replace("minute", "min");

  return {
    id: item.orderId,
    customerName: item.customerName,
    customerPhone: item.customerMobile,
    skuCount: item.itemList.length,
    totalUnits: item.itemList.reduce((sum, item) => sum + item.quantity, 0),
    totalAmount: item.itemList.reduce(
      (sum, item) => sum + item.mrp * item.quantity,
      0
    ),
    isDelayed,
    picker: item.pickerName,
    deliveryAt: "",
    createdAt: item.createdAt,
    timeLeft: 0,
    requestId: item.orderId,
    timeDisplay,
    pickerName: item.pickerName,
    pickerId: item.pickerId,
    deliveryPersonInfo: item.deliveryPersonInfo || {},
  };
};

const OrderMonitor = ({ storeId }) => {
  const [columns, setColumns] = useState([
    {
      name: "New Orders",
      key: "newOrders",
      totalCount: 0,
      list: [],
      page: 1,
      count: 10,
      loading: false,
    },
    {
      name: "Yet to Start Picking",
      key: "unassignedOrders",
      totalCount: 0,
      list: [],
      page: 1,
      count: 10,
      loading: false,
    },
    {
      name: "Picking",
      key: "picking",
      totalCount: 0,
      list: [],
      page: 1,
      count: 10,
      loading: false,
    },
    {
      name: "Yet to Start Packing",
      key: "yetToStartPacking",
      totalCount: 0,
      list: [],
      page: 1,
      count: 10,
      loading: false,
    },
    {
      name: "Packing",
      key: "packing",
      totalCount: 0,
      list: [],
      page: 1,
      count: 10,
      loading: false,
    },
    {
      name: "Ready to Ship",
      key: "readyToShip",
      totalCount: 0,
      list: [],
      page: 1,
      count: 10,
      loading: false,
    },
    {
      name: "In Transit",
      key: "inTransit",
      totalCount: 0,
      list: [],
      page: 1,
      count: 10,
      loading: false,
    },
    {
      name: "Non - Delivered",
      key: "nonDelivered",
      totalCount: 0,
      list: [],
      page: 1,
      count: 10,
      loading: false,
    },
    {
      name: "Return",
      key: "return",
      totalCount: 0,
      list: [],
      page: 1,
      count: 10,
      loading: false,
    },
  ]);

  const [refreshTimer, setRefreshTimer] = useState(5);

  const [dateArray, setDateArray] = useState([]);

  const handleDateInputChange = (date) => {
    setDateArray(date);
    init(date);
  };

  const init = useCallback(
    async (dateArray) => {
      try {
        const params = {
          page: 1,
          count: 300,
          filter: { franchiseId: storeId },
          sort: { createdAt: 1 },
        };

        if (dateArray.length > 0) {
          params.filter.createdAt = {
            $gte: startOfDay(new Date(dateArray[0])),
            $lte: endOfDay(new Date(dateArray[1])),
          };
        }

        const promises = [
          PosService.getRealTimePickerOrders(params).catch(() => ({
            resp: [],
          })),
          PosService.getRealTimeStartPicking(params).catch(() => ({
            resp: [],
          })),
          PosService.getRealTimePickings(params).catch(() => ({ resp: [] })),
          PosService.getRealTimeStartPacking(params).catch(() => ({
            resp: [],
          })),
          PosService.getRealTimePacking(params).catch(() => ({ resp: [] })),
          PosService.getRealTimeReadyToShip(params).catch(() => ({ resp: [] })),
          PosService.getRealTimeIntransit(params).catch(() => ({ resp: [] })),
          PosService.getNonDeliveredOrders(params).catch(() => ({ resp: [] })),
          PosService.getReturnOrders(params).catch(() => ({ resp: [] })),
        ];

        setColumns(
          produce((draft) => {
            draft.forEach((item) => {
              item.loading = true;
            });
          })
        );

        const results = await Promise.all(promises);

        setColumns(
          produce((draft) => {
            results.forEach((data, index) => {
              draft[index].totalCount = data?.resp?.length || 0;
              draft[index].list = (data?.resp || []).map((e) =>
                formatResponse(e, draft[index].key)
              );
              draft[index].loading = false;
            });
          })
        );
      } catch (error) {
        setColumns(
          produce((draft) => {
            draft.forEach((item) => {
              item.loading = false;
            });
          })
        );
      }
    },
    [storeId]
  );

  useEffect(() => {
    init(dateArray);

    const intervalId = setInterval(() => {
      init(dateArray);
      setRefreshTimer(5);
    }, 1000 * 5);

    const countdownId = setInterval(() => {
      setRefreshTimer((prev) => (prev > 0 ? prev - 1 : 5));
    }, 1000);

    return () => {
      clearInterval(intervalId);
      clearInterval(countdownId);
    };
  }, [init, dateArray]);

  return (
    <div className="bg-white p-2 rounded custom-scrollbar">
      <div className="row text-muted fs-val-sm mb-2 align-items-center">
        <div className="col-9">
          Automatically refreshes every 5 seconds. Next refresh in{" "}
          {refreshTimer} seconds.
        </div>
        <div className="col-3">
          <DateInput
            label=""
            isMandatory={false}
            placeholder="Choose a date"
            value={dateArray}
            callback={handleDateInputChange}
            disabled={false}
            error={null}
            gap={0}
            template={2}
          />
        </div>
      </div>
      <div style={containerStyle}>
        <div className="kanban-container">
          {columns.map((e) => (
            <div className="kanban-column" key={e.key}>
              <div className="kanban-column-header">
                <h3>{e.name}</h3>
                <div className="kanban-column-count">{e.totalCount}</div>
              </div>

              <div className="kanban-column-body">
                {e.loading ? (
                  <div className="text-center mt-3">
                    <Spinner size="sm" color="primary" />
                  </div>
                ) : (
                  e.list.map((order) => (
                    <OrderMonitorItem
                      key={order.id}
                      order={order}
                      columnKey={e.key}
                    />
                  ))
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OrderMonitor;
