import { PosService } from "@sk/services";
import { AutoCompleteInput } from "@sk/uis"; // Updated import path
import { useCallback } from "react";

const PosCategoryInput = ({ storeId, menuId, callback, value }) => {
  const fetchCategoryData = useCallback(
    async (search) => {
      try {
        const response = await PosService.getCategory({
          filter: { _id: storeId },
          categoryFilter: {
            name: { $regex: search, $options: "i" },
          },
          ...(menuId && { menuFilter: { menuId } }), // Conditionally add menuFilter
        });
        const options = response.resp.map((category) => ({
          label: category.name,
          value: category._id,
        }));
        return options; // Return options for immediate use
      } catch (error) {
        console.error("Error fetching category data:", error);
        return []; // Return empty array in case of error
      }
    },
    [storeId, menuId] // Added menuId to dependencies
  );

  const onSearch = useCallback(
    async (val, callback) => {
      const options = await fetchCategoryData(val);
      callback(options);
    },
    [fetchCategoryData]
  );

  const handleCategorySelect = (selected) => {
    callback({ selected });
  };

  return (
    <>
      <AutoCompleteInput
        label="Select Category"
        onSearch={onSearch}
        callback={handleCategorySelect}
        name="category"
        size="sm"
        emptyLabel="No categories found"
        value={value}
        uid="category-input"
        gap={0}
      />
    </>
  );
};

export default PosCategoryInput;
