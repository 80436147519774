import { CommonService, PosService } from "@sk/services";
import { SummaryCard } from "@sk/uis";
import { endOfDay, startOfDay } from "date-fns";
import produce from "immer";
import { useEffect, useState } from "react";

const PosAnalyticsSummary = ({ fromDate, toDate, storeId, tabKey }) => {
  const [summaryData, setSummaryData] = useState([
    {
      title: "Avg. Order Value",
      value: 0,
      isValueAmt: true,
      valueColor: "primary",
      icon: "bi bi-cash",
      valuePath: "averageOrderValue", // Path to pick value from response
      tab: "orders", // Updated tab key
    },
    {
      title: "Orders",
      value: 0,
      isValueAmt: false,
      valueColor: "success",
      icon: "bi bi-cart",
      valuePath: "totalOrderCount", // Path to pick value from response
      tab: "orders", // Updated tab key
    },
    {
      title: "Order Value",
      value: 180000,
      isValueAmt: true,
      valueColor: "warning",
      icon: "bi bi-wallet",
      valuePath: "totalOrderValue", // Path to pick value from response
      tab: "orders", // Updated tab key
    },
    {
      title: "Returns Value",
      value: 5000,
      isValueAmt: true,
      valueColor: "danger",
      icon: "bi bi-arrow-return-left",
      valuePath: "totalReturnsValue", // Path to pick value from response
      tab: "orders", // Updated tab key
    },
    {
      title: "Gross Sales Value",
      value: 200000,
      isValueAmt: true,
      valueColor: "info",
      icon: "bi bi-graph-up",
      valuePath: "grossSalesValue", // Path to pick value from response
      tab: "orders", // Updated tab key
    },
    {
      title: "Customers Created",
      value: 300,
      isValueAmt: false,
      valueColor: "success",
      icon: "bi bi-person-plus",
      valuePath: "totalCutomerCreated", // Path to pick value from response
      tab: "customers", // Updated tab key
    },
    {
      title: "Unique Customer Order",
      value: 800,
      isValueAmt: false,
      valueColor: "secondary",
      icon: "bi bi-person-check",
      valuePath: "totalUniqueCustomers", // Path to pick value from response
      tab: "customers", // Updated tab key
    },
    {
      title: "Smartcoins Redeemed",
      value: 1500,
      isValueAmt: false,
      valueColor: "primary",
      icon: "bi bi-currency-exchange",
      valuePath: "totalKCRedeemed", // Path to pick value from response
      tab: "customers", // Updated tab key
    },
    {
      title: "Customer Coins Rewarded",
      value: 2000,
      isValueAmt: false,
      valueColor: "success",
      icon: "bi bi-gift",
      valuePath: "totalKCRewardedCustomer", // Path to pick value from response
      tab: "customers", // Updated tab key
    },
  ]);

  useEffect(() => {
    const fetchSummaryData = async () => {
      const params = {
        filter: {
          $or: [{ "franchiseInfo.id": storeId }, { franchise: storeId }],
          createdAt: {
            $gte: startOfDay(new Date(fromDate)), // Start of day
            $lte: endOfDay(new Date(toDate)), // End of day
          },
        },
        groupbycond: "year",
      };
      const response = await PosService.getPosSalesAnalyticsSummary(params);
      const data = (response.resp || []).map((item) => ({
        ...item,
        averageOrderValue: CommonService.roundedByDecimalPlace(
          item.totalOrderValue / item.totalOrderCount,
          0
        ),
        grossSalesValue: item.totalOrderValue - item.totalReturnsValue,
      }));

      setSummaryData(
        produce((draft) => {
          draft.forEach((item) => {
            item.value = Math.round(data?.[0]?.[item.valuePath] || 0);
          });
        })
      );
    };

    if (fromDate && toDate && storeId) {
      fetchSummaryData();
    }
  }, [fromDate, toDate, storeId, tabKey]);

  return (
    <div className="row">
      {summaryData
        .filter((item) => item.tab === tabKey)
        .map((item, index) => (
          <div className="col" key={index}>
            <SummaryCard
              title={item.title}
              value={item.value}
              isValueAmt={item.isValueAmt}
              template={1}
              valueColor={item.valueColor}
              icon={item.icon}
            />
          </div>
        ))}
    </div>
  );
};

export default PosAnalyticsSummary;
