import { useEffect, useState } from "react";
import { PosService } from "@sk/services";
import { AppCard, KeyVal, PageLoader } from "@sk/uis";

const ClubReqInvoice = ({ orderId }) => {
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      const response = await PosService.getOrders({
        filter: {
          _id: orderId,
        },
      });

      const d = response.resp?.[0] || {};
      setOrder(d);
      setLoading(false);
    };

    if (orderId) {
      fetchOrder();
    }
  }, [orderId]);

  if (loading) {
    return <PageLoader />;
  }

  return (
    <AppCard>
      <KeyVal label="Invoice No.">: {order?.invoiceNo}</KeyVal>
    </AppCard>
  );
};

export default ClubReqInvoice;
