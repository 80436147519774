import { PosService } from "@sk/services";
import {
  AppCard,
  AppSlide,
  AppSwiper,
  DateFormatter,
  DateInput,
  NoDataFound,
  SummaryCard,
  TableHeader,
} from "@sk/uis";
import { endOfDay, set, startOfDay } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { statusOptions } from "../../constant";

const swiperConfig = {
  slidesPerView: "auto",
  spaceBetween: 10,
  mousewheel: {
    forceToAxis: true,
  },
};

// Define the original statuses that were used
const originalStatuses = [
  "PENDING",
  "YET_TO_PROCESS",
  "YET_TO_SHIP",
  "SHIPPED",
  "DELIVERED",
  "REJECTED",
  "RETURNED",
];

// Prepare defaultStatuses from statusOptions, filtering by originalStatuses
const defaultStatuses = statusOptions
  .filter((option) => originalStatuses.includes(option.value))
  .map((option) => ({
    actualStatus: option.value,
    status: option.summaryStatusKey,
    label: option.label,
    color: option.color,
    cellClass: `bg-${option.color}`,
  }));

const ClubReqDeliverySummaryModal = ({ isOpen, callback, storeId }) => {
  const { control, getValues } = useForm({
    defaultValues: { deliveryDate: [new Date()] },
  });
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [statusSummary, setStatusSummary] = useState({});

  const fetchTimeSlots = useCallback(
    async (date) => {
      return await PosService.getDeliveryTimeSlot({
        filter: {
          franchiseId: storeId,
          date: { $gte: startOfDay(date), $lte: endOfDay(date) },
        },
      });
    },
    [storeId]
  );

  const fetchDeliverySummary = useCallback(
    async (date) => {
      return await PosService.getClubReqDeliverySummary({
        filter: {
          franchiseId: storeId,
          "deliveryTimeSlot.date": {
            $gte: startOfDay(date),
            $lte: endOfDay(date),
          },
        },
      });
    },
    [storeId]
  );

  const prepareData = (slots, slotsSummary, date) => {
    const summary = defaultStatuses.map((statusItem) => ({
      label: statusItem.label,
      actualStatus: statusItem.actualStatus,
      status: statusItem.status,
      value: 0,
      color: statusItem.color,
    }));

    const preparedData = slots.map((slot) => {
      const slotData = defaultStatuses.map((statusItem) => {
        const count =
          slotsSummary
            .find((s) => s.from === slot.from && s.to === slot.to)
            ?.statuses?.find((s) => s.status === statusItem.status)?.count || 0;

        if (count > 0) {
          const existingStatus = summary.find(
            (s) => s.actualStatus === statusItem.actualStatus
          );
          if (existingStatus) {
            existingStatus.value += count;
          }
        }

        return { ...statusItem, value: count };
      });

      return {
        ...slot,
        data: slotData,
        _from: set(date, {
          hours: slot._fromHour,
          minutes: slot._fromMins,
          seconds: 0,
        }),
        _to: set(date, {
          hours: slot._toHour,
          minutes: slot._toMins,
          seconds: 0,
        }),
      };
    });

    return { data: preparedData, summary };
  };

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const date = getValues("deliveryDate")[0];
      const timeSlotsResponse = await fetchTimeSlots(date);
      if (timeSlotsResponse?.resp?.length === 0) {
        setStatus([]);
        return;
      }

      const summaryResponse = await fetchDeliverySummary(date);
      const slots = timeSlotsResponse?.resp[0]?.slab || [];
      const slotsSummary = summaryResponse?.resp[0]?.slots || [];
      const { data, summary } = prepareData(slots, slotsSummary, date);

      setStatus(data);
      setStatusSummary(summary);

      setHeaders([
        { label: "Time Slot", key: "timeSlot", width: "100px", isSticky: true },
        ...defaultStatuses.map((statusItem) => ({
          label: statusItem.label,
          key: statusItem.status,
          width: "100px",
          isCentered: true,
        })),
      ]);
    } catch (error) {
      console.error("Error loading data:", error);
      setStatus([]);
    } finally {
      setLoading(false);
    }
  }, [fetchDeliverySummary, fetchTimeSlots, getValues]);

  useEffect(() => {
    if (isOpen && storeId) {
      loadData();
    }
  }, [isOpen, loadData, storeId]);

  const handleDateChange = (onChange) => (date) => {
    onChange(date);
    loadData();
  };

  const onClose = () => {
    callback({ action: "close" });
  };

  const handleSlotClick = (slot, statusItem) => {
    const date = getValues("deliveryDate")[0];

    callback({
      action: "slot",
      data: {
        date,
        from: statusItem.from,
        to: statusItem.to,
        _from: set(date, {
          hours: statusItem._fromHour,
          minutes: statusItem._fromMins,
          seconds: 0,
        }),
        _to: set(date, {
          hours: statusItem._toHour,
          minutes: statusItem._toMins,
          seconds: 0,
        }),
        status: slot.actualStatus,
      },
    });
  };

  return (
    <Modal
      show={isOpen}
      onHide={onClose}
      enforceFocus={false}
      autoFocus={false}
      size="lg"
      scrollable={true}
    >
      <Modal.Header closeButton className="bg-light">
        <Modal.Title className="text-dark mb-0">Delivery Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-bg">
        <div className="mb-3">
          <Controller
            name="deliveryDate"
            control={control}
            render={({ field }) => (
              <DateInput
                label="Select Date"
                value={field.value}
                callback={handleDateChange(field.onChange)}
                hideClose={true}
              />
            )}
          />
        </div>

        {loading ? (
          <div className="text-center py-3">Loading...</div>
        ) : status.length === 0 ? (
          <NoDataFound>No delivery slots found</NoDataFound>
        ) : (
          <>
            <AppSwiper config={swiperConfig}>
              {statusSummary.map((slot, index) => (
                <AppSlide key={index} autoWidth={true}>
                  <SummaryCard
                    title={slot.label}
                    value={slot.value}
                    template={3}
                    valueColor={slot.color}
                  />
                </AppSlide>
              ))}
            </AppSwiper>

            <AppCard>
              <div className="tbl-scroll-container custom-scrollbar thin-scrollbar">
                <table className="table table-sm table-bordered">
                  <TableHeader data={headers} />
                  <tbody className="fs-val-md">
                    {status.map((statusItem, index) => (
                      <tr key={index}>
                        <td className="position-sticky start-0 bg-white">
                          <DateFormatter
                            date={statusItem._from}
                            format="hh:mm a"
                          />{" "}
                          -{" "}
                          <DateFormatter
                            date={statusItem._to}
                            format="hh:mm a"
                          />
                        </td>
                        {statusItem.data.map((slot, slotIndex) => (
                          <td
                            key={slotIndex}
                            className={`text-center ${
                              slot.value > 0
                                ? slot.cellClass + " bg-opacity-10"
                                : ""
                            }`}
                          >
                            {slot.value ? (
                              <button
                                className="btn btn-sm fs-val-md text-dark btn-link p-0"
                                onClick={() =>
                                  handleSlotClick(slot, statusItem)
                                }
                              >
                                {slot.value}
                              </button>
                            ) : (
                              0
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </AppCard>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ClubReqDeliverySummaryModal;
