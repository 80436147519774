import { PosService } from "@sk/services";
import {
  AppCard,
  AppSlide,
  AppSwiper,
  DateFormatter,
  NoDataFound,
  Tabs,
} from "@sk/uis";
import { addDays, endOfDay, set, startOfDay, subDays } from "date-fns";
import { orderBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import ClubReqDeliverySummaryModal from "./modals/club-delivery-summary/ClubReqDeliverySummaryModal";
import { statusOptions } from "./constant";

const tabsData = [
  {
    tabName: "Yesterday",
    key: "yesterday",
  },
  {
    tabName: "Today",
    key: "today",
  },
  {
    tabName: "Tomorrow",
    key: "tomorrow",
  },
  {
    tabName: "View More",
    key: "view-more",
  },
];

const defaultStatus = statusOptions
  .filter((option) =>
    ["Created", "Approved", "Invoiced", "Shipped", "Delivered"].includes(
      option.summaryStatusKey
    )
  )
  .map((e) => ({
    status: e.summaryStatusKey,
    value: 0,
    label: e.label,
    cssClass: `text-${e.color}`,
  }));

const slideConfig = {
  mousewheel: {
    forceToAxis: true,
  },
  slidesPerView: "auto",
  spaceBetween: 10,
  freeMode: true,
};

const ClubReqDeliverySummary = ({ storeId, callback }) => {
  const [activeTab, setActiveTab] = useState(tabsData[1]); // Default to Today
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState([]);
  const [display, setDisplay] = useState("loading");
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const loadTimeSlots = useCallback(
    async (activeTab) => {
      setLoading(true);
      setDisplay("loading");
      setSelectedIndex(-1);

      let date;
      switch (activeTab.key) {
        case "yesterday":
          date = subDays(new Date(), 1);
          break;
        case "tomorrow":
          date = addDays(new Date(), 1);
          break;
        default:
          date = new Date();
      }

      try {
        const timeSlotResponse = await PosService.getDeliveryTimeSlot({
          filter: {
            franchiseId: storeId,
            date: {
              $gte: startOfDay(date),
              $lte: endOfDay(date),
            },
          },
        });

        if (
          Array.isArray(timeSlotResponse.resp) &&
          timeSlotResponse.resp.length > 0
        ) {
          const mappedSlots = orderBy(
            timeSlotResponse.resp[0].slab || [],
            ["from"],
            ["asc"]
          ).map((slot) => ({
            ...slot,
            _from: set(date, {
              hours: slot._fromHour,
              minutes: slot._fromMins,
              seconds: 0,
            }),
            _to: set(date, {
              hours: slot._toHour,
              minutes: slot._toMins,
              seconds: 0,
            }),
            status: [...defaultStatus],
          }));

          setSlots(mappedSlots);

          // Get summary data
          const summaryResponse = await PosService.getClubReqDeliverySummary({
            filter: {
              franchiseId: storeId,
              "deliveryTimeSlot.date": {
                $gte: startOfDay(date),
                $lte: endOfDay(date),
              },
            },
          });

          if (
            Array.isArray(summaryResponse.resp) &&
            summaryResponse.resp.length > 0
          ) {
            const summarySlots = summaryResponse.resp[0]?.slots || [];

            // Update slot status counts
            const updatedSlots = mappedSlots.map((slot) => {
              const matchingSlot = summarySlots.find(
                (s) => s.from === slot.from && s.to === slot.to
              );

              if (matchingSlot?.from) {
                slot.status = slot.status.map((statusItem) => {
                  const matchingStatus = (matchingSlot.statuses || []).find(
                    (s) => s.status === statusItem.status
                  );
                  return {
                    ...statusItem,
                    value: matchingStatus?.count || 0,
                  };
                });
              }
              return slot;
            });

            setSlots(updatedSlots);
            setDisplay("data");
          }
        } else {
          setSlots([]);
          setDisplay("no-slots");
        }
      } catch (error) {
        console.error("Error loading delivery slots:", error);
        setSlots([]);
        setDisplay("no-slots");
      } finally {
        setLoading(false);
      }
    },
    [storeId]
  );

  useEffect(() => {
    loadTimeSlots(tabsData[1]);
  }, [loadTimeSlots]);

  const handleTabChange = ({ value }) => {
    if (value.key === "view-more") {
      setIsModalOpen(true);
      return;
    }

    loadTimeSlots(value);
    setActiveTab(value);
    callback?.({
      from: "",
      to: "",
    });
  };

  const handleSlotClick = (slot, index) => {
    if (selectedIndex === index) {
      setSelectedIndex(-1);
      callback?.({
        from: "",
        to: "",
      });
    } else {
      setSelectedIndex(index);
      callback?.({
        date: new Date(),
        from: slot.from,
        to: slot.to,
        displayFrom: slot._from,
        displayTo: slot._to,
      });
    }
  };

  const summaryModalCallback = ({ action, data }) => {
    setIsModalOpen(false);
    if (action === "slot") {
      callback({
        date: data.date,
        from: data.from,
        to: data.to,
        displayFrom: data._from,
        displayTo: data._to,
        status: data.status,
      });
    }
  };

  return (
    <AppCard noPad={true}>
      <Tabs activeTab={activeTab} data={tabsData} callback={handleTabChange} />
      <div className="px-3 py-2">
        {loading ? (
          <div className="text-center py-2">Loading...</div>
        ) : display === "no-slots" ? (
          <NoDataFound>No delivery slots found</NoDataFound>
        ) : (
          <AppSwiper config={slideConfig}>
            {slots.map((slot, index) => (
              <AppSlide key={index} autoWidth={true}>
                <div
                  className={`border rounded ${
                    selectedIndex === index ? "border-primary" : ""
                  }`}
                  style={{ width: "200px", cursor: "pointer" }}
                  onClick={() => handleSlotClick(slot, index)}
                >
                  <div className="bg-light px-2 py-1 border-bottom d-flex justify-content-between align-items-center">
                    <small className="fw-medium fs-val-sm">
                      <DateFormatter date={slot._from} format="hh:mm a" />
                      {" - "}
                      <DateFormatter date={slot._to} format="hh:mm a" />
                    </small>
                    <span className="badge bg-primary rounded-pill">
                      {slot.status.reduce((sum, s) => sum + s.value, 0)}
                    </span>
                  </div>
                  <div className="px-2 py-1">
                    {slot.status.map((status, statusIndex) => (
                      <div
                        key={statusIndex}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <span className={`fs-val-sm ${status.cssClass}`}>
                          {status.label}
                        </span>
                        <span className="fw-medium fs-val-xs">
                          {status.value}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              </AppSlide>
            ))}
          </AppSwiper>
        )}
      </div>
      <ClubReqDeliverySummaryModal
        isOpen={isModalOpen}
        callback={summaryModalCallback}
        storeId={storeId}
      />
    </AppCard>
  );
};

export default ClubReqDeliverySummary;
