import { SummaryCard } from "@sk/uis";

const PosCashCollectSummary = ({ summaryCards, type }) => {
  return (
    <div className="row g-3">
      {summaryCards
        .filter((x) => (!x.type ? true : x.type === type))
        .map((card, index) => (
          <div key={index} className="col-auto">
            <SummaryCard
              title={card.title}
              value={card.value}
              isValueAmt={card.isValueAmt}
              template={3}
              loading={card.loading}
              valueColor={card.valueColor}
              icon={card.icon}
            />
          </div>
        ))}
    </div>
  );
};

export default PosCashCollectSummary;
