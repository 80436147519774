import { AddToPurchaseBasketModal, StoreCard } from "@sk/features";
import { useFetchUrlQueryString } from "@sk/hooks";
import { NavService, PosService } from "@sk/services";
import {
  AppCard,
  BtnLink,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PageInfo,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
} from "@sk/uis";
import { endOfDay, startOfDay, sub } from "date-fns";
import produce from "immer";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PurchaseBasketAppliedFilters from "./components/PurchaseBasketAppliedFilters";
import PurchaseBasketFilter from "./components/PurchaseBasketFilter";

const headerData = [
  { key: "_slNo", label: "Sl No", width: "5%" },
  { key: "dealInfo.id", label: "ID", enableSort: true, width: "10%" },
  { key: "dealInfo.name", label: "Deal Name", enableSort: true, width: "25%" },
  {
    key: "suggestedBy.name",
    label: "Suggested By",
    enableSort: true,
    width: "10%",
  },
  { key: "quantity", label: "Suggested Qty", width: "10%" },
  { key: "status", label: "Status", width: "10%" },
  { key: "createdAt", label: "Created On", enableSort: true, width: "15%" },
  { key: "dealInfo.menuName", label: "Menu", enableSort: true, width: "15%" },
  {
    key: "dealInfo.categoryName",
    label: "Category",
    enableSort: true,
    width: "15%",
  },
  { key: "dealInfo.brandName", label: "Brand", enableSort: true, width: "15%" },
];

const tableContainerStyle = {
  overflowY: "auto",
  maxHeight: "calc(100vh - 200px)",
};

const tableStyle = {
  minWidth: "1500px",
};

const getData = async (params) => {
  const r = await PosService.getPurchaseBaskets(params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const getCount = async (params) => {
  let p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;
  const r = await PosService.getPurchaseBaskets(p);
  return { count: r.resp?.[0]?.total || 0 };
};

const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {
      franchiseId: filter.fid,
    },
    sort: { [sort.key]: sort.value === "asc" ? 1 : -1 },
  };

  if (filter.search) {
    const search = filter.search.trim();
    const searchRegex = { $regex: search, $options: "gi" };
    p.filter.$or = [
      { _id: search },
      { "dealInfo.id": search },
      { "dealInfo.name": searchRegex },
      { "dealInfo.menuName": searchRegex },
      { "dealInfo.categoryName": searchRegex },
      { "dealInfo.brandName": searchRegex },
      { "suggestedBy.name": searchRegex },
    ];
  }

  if (filter.dateRange?.length) {
    p.filter.createdAt = {
      $gte: startOfDay(filter.dateRange[0]),
      $lte: endOfDay(filter.dateRange[1]),
    };
  }

  if (filter.employee && filter.employee.length > 0) {
    p.filter["suggestedBy.id"] = filter.employee[0]?.value?._id;
  }

  if (filter.status) {
    p.filter.status = filter.status;
  }

  if (filter.menu && filter.menu.length > 0) {
    p.filter["dealInfo.menuId"] = {
      $in: filter.menu?.map((m) => m?.value?._id),
    };
  }

  if (filter.brand && filter.brand.length > 0) {
    p.filter["dealInfo.brandId"] = {
      $in: filter.brand?.map((b) => b?.value?._id),
    };
  }

  if (filter.category && filter.category.length > 0) {
    p.filter["dealInfo.categoryId"] = {
      $in: filter.category?.map((c) => c?.value?._id),
    };
  }

  return p;
};

const breadcrumbs = [{ name: "Home", link: "/" }, { name: "Purchase Basket" }];

const PurchaseBasket = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { fid, fName } = useFetchUrlQueryString(searchParams);

  const [baskets, setBaskets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recordsCount, setRecordsCount] = useState({
    loading: false,
    count: 0,
  });

  const filterDataRef = useRef({
    dateRange: [sub(new Date(), { days: 60 }), new Date()],
    fid,
  });
  const paginationRef = useRef({
    totalRecords: 0,
    rowsPerPage: 50,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 50,
  });
  const sortRef = useRef({ key: "createdAt", value: "desc" });

  const [basketModal, setBasketModal] = useState({
    show: false,
    dealId: null,
  });

  const handleBasketModalCallback = (data) => {
    if (data.action === "addToBasket") {
      setBaskets(
        produce((draft) => {
          const itemToUpdate = draft.find(
            (item) => item._id === data.purchaseDetails.dealInfo.id
          );
          if (itemToUpdate) {
            itemToUpdate.quantity = data.purchaseDetails.quantity;
          }
        })
      );

      Toaster.success(
        `Updated quantity to ${data.purchaseDetails.quantity} for ${data.purchaseDetails.dealInfo.name}`
      );
      setBasketModal({ show: false, dealId: null });
    } else if (data.action === "close") {
      setBasketModal({ show: false, dealId: null });
    }
  };

  const handleEditQuantity = (dealId) => {
    setBasketModal({
      show: true,
      dealId,
    });
  };

  const applyFilter = useCallback(async () => {
    paginationRef.current = {
      totalRecords: 0,
      rowsPerPage: 50,
      activePage: 1,
      startSlNo: 1,
      endSlNo: 50,
    };

    loadList();

    setRecordsCount({ loading: true, count: 0 });
    const r = await getCount(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      )
    );
    paginationRef.current.totalRecords = r.count;
    setRecordsCount({ loading: false, count: r.count });
  }, [loadList]);

  const loadList = useCallback(async () => {
    setLoading(true);
    setBaskets([]);
    const r = await getData(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      )
    );
    setBaskets(r.data);
    setLoading(false);
  }, []);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    filterDataRef.current.fid = fid;
    init();
  }, [init, fid]);

  const onPaginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    applyFilter();
  };

  const onSortCb = (sort) => {
    sortRef.current = sort;
    applyFilter();
  };

  const onFilterCb = (data) => {
    filterDataRef.current = {
      ...filterDataRef.current,
      ...data.formData,
    };
    applyFilter();
  };

  const appliedFilterCallback = ({ action, data }) => {
    console.log(data);
    if (action === "remove") {
      filterDataRef.current = {
        ...filterDataRef.current,
        [data.key]: data.config.resetVal,
      };
      applyFilter();
    }
  };

  const updateFilter = (type, value) => {
    filterDataRef.current = {
      ...filterDataRef.current,
      menu: [],
      brand: [],
      category: [],
      [type]: [{ label: value.label, value: { _id: value._id } }],
    };
    applyFilter();
  };

  return (
    <>
      <PageInfo title="Purchase Basket" breadcrumbs={breadcrumbs} />
      <div className="fs-val-sm text-secondary mb-1">
        The Purchase Basket suggests items to the store manager for adding to
        the cart.
      </div>

      <StoreCard
        fid={fid}
        navigate={navigate}
        rbacKey="DarkStoreInventoryDashboard"
      />

      <AppCard>
        <PurchaseBasketFilter
          callback={onFilterCb}
          filterData={filterDataRef.current}
        />
        <PurchaseBasketAppliedFilters
          filterData={filterDataRef.current}
          callback={appliedFilterCallback}
        />
      </AppCard>
      <AppCard>
        <PaginationSummary
          loadingTotalRecords={recordsCount.loading}
          paginationConfig={paginationRef.current}
          className="mb-3"
        />

        <div
          className="tbl-scroll-container custom-scrollbar thin-scrollbar fixed-width-table"
          style={tableContainerStyle}
        >
          <table className="table table-sm table-striped" style={tableStyle}>
            <TableHeader
              data={headerData}
              noBg
              sortCb={onSortCb}
              sort={sortRef.current}
              isSticky={true}
            />
            <tbody className="fs-val-md">
              {loading ? (
                <TableSkeletonLoader cols={headerData.length} rows={5} />
              ) : null}

              {!loading && !baskets.length ? (
                <tr>
                  <td colSpan={headerData.length}>
                    <NoDataFound>No baskets found</NoDataFound>
                  </td>
                </tr>
              ) : null}

              {baskets.map((basket, index) => (
                <tr key={basket._id}>
                  <td>{paginationRef.current.startSlNo + index}</td>
                  <td>{basket?.dealInfo?.id}</td>
                  <td>
                    <div className="text-wrap">{basket?.dealInfo?.name}</div>
                  </td>
                  <td>{basket.suggestedBy?.name}</td>
                  <td>{basket.quantity} units</td>
                  <td>
                    <HighlightText
                      type={basket.status === "Open" ? "success" : "danger"}
                      template={2}
                    >
                      {basket.status}
                    </HighlightText>
                  </td>
                  <td>
                    <DateFormatter date={basket.createdAt} />
                  </td>
                  <td>
                    <button
                      className="btn btn-link text-dark text-start fs-val-md p-0"
                      onClick={() =>
                        updateFilter("menu", {
                          label: basket.dealInfo.menuName,
                          _id: basket.dealInfo.menuId,
                        })
                      }
                    >
                      {basket.dealInfo.menuName}
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-link text-dark text-start fs-val-md p-0"
                      onClick={() =>
                        updateFilter("category", {
                          label: basket.dealInfo.categoryName,
                          _id: basket.dealInfo.categoryId,
                        })
                      }
                    >
                      {basket.dealInfo.categoryName}
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-link text-dark text-start fs-val-md p-0"
                      onClick={() =>
                        updateFilter("brand", {
                          label: basket.dealInfo.brandName,
                          _id: basket.dealInfo.brandId,
                        })
                      }
                    >
                      {basket.dealInfo.brandName}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-end mt-3">
          <PaginationBlock
            loadingTotalRecords={recordsCount.loading}
            paginationCb={onPaginationCb}
            size="sm"
            paginationConfig={paginationRef.current}
          />
        </div>
      </AppCard>

      <AddToPurchaseBasketModal
        show={basketModal.show}
        dealId={basketModal.dealId}
        storeId={fid}
        callback={handleBasketModalCallback}
      />
    </>
  );
};

export default PurchaseBasket;
