import { SummaryCard, AppSwiper, AppSlide } from "@sk/uis";

const slideConfig = {
  slidesPerView: "auto",
  spaceBetween: 10,
  mousewheel: {
    forceToAxis: true,
  },
};

const ClubRequestsSummary = ({ summary }) => {
  return (
    <AppSwiper config={slideConfig}>
      {summary.map((card, index) => (
        <AppSlide key={index} autoWidth={true}>
          <SummaryCard
            title={card.title}
            value={card.value}
            isValueAmt={card.isValueAmt}
            template={3}
            loading={card.loading}
            valueColor={card.valueColor}
            icon={card.icon}
          />
        </AppSlide>
      ))}
    </AppSwiper>
  );
};

export default ClubRequestsSummary;
