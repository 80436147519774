import { PosService } from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  HighlightText,
  ImgPreviewModal,
  ImgRender,
  KeyVal,
  NoDataFound,
  Pagination,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
} from "@sk/uis";
import { endOfDay, startOfDay } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useForm } from "react-hook-form";

const canvasWidth = "50%";

const imgStyle = {
  width: "90px",
  height: "90px",
};

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const defaultFormData = {
  search: "",
};

const headers = [
  { label: "Sl No", width: "8%" },
  { label: "Order ID", width: "25%" },
  { label: "Log ID", width: "25%" },
  { label: "Amount", width: "20%" },
  { label: "Payment Mode", width: "30%" },
];

const getData = async (params) => {
  const r = await PosService.getPosSettlement(params);
  return { data: r.resp || [] };
};

const prepareFilterParams = (filter, pagination) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      "shipmentUserInfo.id": filter.userId,
      "senderInfo.id": filter.storeId,
      createdAt: {
        $gte: startOfDay(new Date(filter.dateRange[0])),
        $lte: endOfDay(new Date(filter.dateRange[1])),
      },
      status: filter.status,
    },
    groupbycond: "shipmentUserId",
  };

  if (filter.status === "Pending") {
    p.filter.status = "SettlementInitiated";
    delete p.filter.createdAt;
  }

  if (filter.status === "Collected") {
    p.filter.status = "Settled";
  }
  return p;
};

const PosCashCollectOrdersModal = ({
  userId,
  storeId,
  callback,
  show,
  dateRange,
  status,
}) => {
  const { register, reset } = useForm({
    defaultValues: defaultFormData,
  });

  const [data, setData] = useState([]);
  const [records, setRecords] = useState({ loading: true, value: 0 });
  const [loadingData, setLoadingData] = useState(true);
  const [shipmentUserDetails, setShipmentUserDetails] = useState({});
  const [collectedAmount, setCollectedAmount] = useState(0);
  const [previewImage, setPreviewImage] = useState(null);

  const filterDataRef = useRef({ ...defaultFormData });
  const paginationRef = useRef({ ...defaultPaginationConfig });
  const originalData = useRef([]);

  const applyFilter = useCallback(() => {
    paginationRef.current = { ...defaultPaginationConfig };
    loadData();
  }, [loadData]);

  const loadData = useCallback(async () => {
    const searchValue = filterDataRef.current.search?.toLowerCase();

    let filtered = originalData.current.slice();
    if (searchValue) {
      filtered = filtered.filter((item) => {
        const matchesSearch = item.orderId.toLowerCase().includes(searchValue);
        return matchesSearch;
      });
    }

    setData(
      filtered.slice(
        paginationRef.current.startSlNo - 1,
        paginationRef.current.endSlNo
      )
    );

    paginationRef.current.totalRecords = filtered.length;
    setRecords({ loading: false, value: filtered.length });
  }, []);

  const init = useCallback(async () => {
    const shipmentUserDetails = await PosService.getEmpList({
      page: 1,
      count: 1,
      filter: {
        _id: userId,
      },
    });
    if (shipmentUserDetails.resp && shipmentUserDetails.resp.length > 0) {
      const shipmentUser = shipmentUserDetails.resp[0];
      setShipmentUserDetails(shipmentUser);
    } else {
      setShipmentUserDetails({});
    }

    const r = await getData(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );
    originalData.current = r.data?.[0]?.data || [];
    setCollectedAmount(r.data?.[0]?.totalAmt || 0);
    setLoadingData(false);
    applyFilter();
  }, [applyFilter, userId]);

  useEffect(() => {
    if (show) {
      filterDataRef.current = { userId, storeId, dateRange, status };
      reset();
      init();
    }
  }, [userId, storeId, init, show, dateRange, status, reset]);

  const onClose = () => {
    callback({ action: "close" });
  };

  const handleOrderClick = (orderId) => {
    callback({ action: "viewOrder", orderId });
  };

  const handleViewProof = (item) => {
    if (item.paymentProofImage) {
      setPreviewImage([{ id: item.paymentProofImage }]);
    }
  };

  const paginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadData();
  };

  const onSearchChange = useCallback(
    debounce((e) => {
      filterDataRef.current = {
        ...filterDataRef.current,
        search: e.target.value,
      };
      applyFilter();
    }, 300), // Adjust the delay as needed
    []
  );

  return (
    <>
      <Offcanvas
        show={show}
        onHide={onClose}
        placement="end"
        style={{ width: canvasWidth }}
      >
        <Offcanvas.Header closeButton className="bg-white">
          <Offcanvas.Title>Cash Collect Orders</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modal-bg">
          <AppCard className="mb-3">
            <div className="row">
              <div className="col-auto">
                <div style={imgStyle}>
                  <ImgRender
                    assetId={shipmentUserDetails.profileImages?.[0]}
                    className="img-fluid h-100 rounded cover"
                  />
                </div>
              </div>
              <div className="col">
                <KeyVal
                  label="Delivery Person"
                  labelCol="col-md-3"
                  contentCol="col-md-9"
                >
                  : {shipmentUserDetails.name}
                </KeyVal>

                <KeyVal
                  label="Store Details"
                  labelCol="col-md-3"
                  contentCol="col-md-9"
                  fwSize="md"
                >
                  : {data?.[0]?.senderName}
                </KeyVal>

                <KeyVal
                  label="Total Amount"
                  labelCol="col-md-3"
                  contentCol="col-md-9"
                  fwSize="md"
                >
                  :{" "}
                  <span className="text-danger">
                    <Amount value={collectedAmount} />
                  </span>
                </KeyVal>

                <KeyVal
                  label="Status"
                  labelCol="col-md-3"
                  contentCol="col-md-9"
                  fwSize="md"
                >
                  :{" "}
                  <HighlightText
                    type={status === "Pending" ? "warning" : "success"}
                    template={2}
                  >
                    {status === "Pending" ? "Pending to Collect" : "Collected"}
                  </HighlightText>
                </KeyVal>
              </div>
            </div>
          </AppCard>
          <AppCard>
            <div className="mb-3">
              <TextInput
                name="search"
                placeholder="Search by Order ID"
                register={register}
                gap={0}
                callback={onSearchChange}
              />
            </div>

            <div className="row g-0">
              <div className="col-auto">
                <PaginationSummary
                  loadingTotalRecords={records.loading}
                  paginationConfig={paginationRef.current}
                  className="mb-1"
                />
              </div>
              {status !== "Pending" && (
                <div className="col-auto fs-val-md text-black-50 ms-1">
                  , from{" "}
                  <DateFormatter date={dateRange[0]} format="dd MMM yyyy" /> to{" "}
                  <DateFormatter date={dateRange[1]} format="dd MMM yyyy" />
                </div>
              )}
            </div>

            <div className="table-responsive">
              <table className="table bg-white table-striped table-sm">
                <TableHeader data={headers} noBg={true} />
                <tbody className="fs-val-md">
                  {loadingData ? (
                    <TableSkeletonLoader cols={headers.length} rows={10} />
                  ) : null}

                  {!loadingData && !data.length ? (
                    <tr>
                      <td colSpan={headers.length}>
                        <NoDataFound>No Data found</NoDataFound>
                      </td>
                    </tr>
                  ) : null}

                  {data.map((item, index) => (
                    <tr key={index}>
                      <td>{paginationRef.current.startSlNo + index}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-link fs-val-md text-start text-dark p-0"
                          onClick={() => handleOrderClick(item.orderId)}
                        >
                          {item.orderId}
                        </button>
                      </td>
                      <td>{item._id}</td>
                      <td>
                        <Amount value={item.totalAmount} />
                      </td>
                      <td>
                        {item.paymentMode}{" "}
                        {item.paymentProofImage ? (
                          <button
                            className="btn btn-sm btn-link fs-val-sm text-primary p-0 ms-2"
                            onClick={() => handleViewProof(item)}
                          >
                            View Proof
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <div className="d-flex justify-content-end mt-3">
              <Pagination
                callback={paginationCb}
                activePage={paginationRef.current.activePage}
                rowsPerPage={paginationRef.current.rowsPerPage}
                totalRecords={records.value}
                size="sm"
              />
            </div>
          </AppCard>
        </Offcanvas.Body>
      </Offcanvas>

      <ImgPreviewModal
        assets={previewImage}
        show={!!previewImage}
        handleClose={() => setPreviewImage(null)}
        isAsset={true}
      />
    </>
  );
};

export default PosCashCollectOrdersModal;
