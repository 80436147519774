import { CommonService } from "@sk/services";
import { AppliedFilterLabel } from "@sk/uis";

const filterLabels = {
  status: {
    label: "Status",
    canRemove: true,
    resetVal: "",
  },
  employee: {
    label: "Employee",
    canRemove: true,
    resetVal: null,
    valuePath: "[0].label",
  },
  dateRange: {
    label: "Date Range",
    canRemove: true,
    resetVal: [],
    type: "dateRange",
  },
  menu: {
    label: "Menu",
    canRemove: true,
    resetVal: [],
    valuePath: "[0].label",
  },
  brand: {
    label: "Brand",
    canRemove: true,
    resetVal: [],
    valuePath: "[0].label",
  },
  category: {
    label: "Category",
    canRemove: true,
    resetVal: [],
    valuePath: "[0].label",
  },
};

const PurchaseBasketAppliedFilters = ({ filterData, callback }) => {
  const { fid, ...filteredData } = filterData;

  const labels = CommonService.prepareAppliedFilterLabels(
    filterLabels,
    filteredData
  );

  return (
    <AppliedFilterLabel className="mt-3" labels={labels} callback={callback} />
  );
};

export default PurchaseBasketAppliedFilters;
