import { RspChangeLog, StockLedgerDetailModal } from "@sk/features";
import { useFetchUrlQueryString } from "@sk/hooks";
import { OmsService, PosService, ProductService } from "@sk/services";
import {
  InfoBlk,
  NoDataFound,
  PageInfo,
  PageLoader,
  Tabs,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PosOrders from "../components/pos-orders/PosOrders";
import StoreDealViewBasic from "./components/StoreDealViewBasic";
import StoreDealPurchaseSummary from "./components/purchase-summary/StoreDealPurchaseSummary";
import StoreDealPurchases from "./components/purchase/StoreDealPurchases";
import StoreDealSalesSummary from "./components/sales-summary/StoreDealSalesSummary";
import StockDetails from "./components/stock-details/StockDetails";

const breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Store Deal Details",
  },
];

const tabs = [
  { key: "sales", tabName: "Sales" },
  { key: "purchases", tabName: "Purchases" },
  { key: "stockLedger", tabName: "Stock Details" },
  { key: "priceChangeLog", tabName: "Price Change Log" },
];

const StoreDealView = () => {
  const [searchParams] = useSearchParams();
  const query = useFetchUrlQueryString(searchParams);
  const [display, setDisplay] = useState("loading");
  const [data, setData] = useState({});
  const [activeTab, setActiveTab] = useState({
    key: tabs[0].key,
  });
  const navigate = useNavigate();
  const [showLedgerDetailModal, setShowLedgerDetailModal] = useState(false);
  const [ledgerDetailData, setLedgerDetailData] = useState({});

  useEffect(() => {
    init();
  }, [init]);

  const init = useCallback(() => {
    if (query.id && query.storeId) {
      loadDealDetails(query.id);
    } else {
      setDisplay("noDataFound");
    }
  }, [loadDealDetails, query.id, query.storeId]);

  const loadDealDetails = useCallback(
    async (id) => {
      setDisplay("loading");
      try {
        const response = await PosService.getDealsStock({
          page: 1,
          count: 1,
          filter: { _id: query.storeId },
          dealFilter: { _id: id },
        });
        const dealData = response.resp?.[0] || {};
        if (dealData._id) {
          const linkedProductIds = dealData.productId || [];
          const linkedProducts = await getLinkedProducts(linkedProductIds);

          const openOrders = await OmsService.getOpenOrders(
            {
              filter: { "subOrders.id": id, "franchise.id": query.storeId },
            },
            true
          );
          setData({
            ...dealData,
            basic: getBasicDetails(dealData),
            sales: getSalesDetails(dealData),
            purchases: {
              ...getPurchaseDetails(dealData),
              openOrders: openOrders.resp,
            },
            linkedProducts,
          });
          setDisplay("details");
        } else {
          setDisplay("noDataFound");
        }
      } catch (error) {
        Toaster.error("Failed to load deal details");
        setDisplay("noDataFound");
      }
    },
    [query.storeId]
  );

  const tabCb = (data) => {
    setActiveTab({ ...data.value });
  };

  const handleLedgerCallback = (data) => {
    if (data.action === "viewLedgerDetail") {
      setLedgerDetailData(data);
      setShowLedgerDetailModal(true);
    }
  };

  const closeLedgerDetailModal = () => {
    setShowLedgerDetailModal(false);
  };

  return (
    <>
      <PageInfo
        title="Deal Details"
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />
      <InfoBlk variant="info">
        Viewing data related to store:{" "}
        <span className="fw-semibold">
          {query.storeName} - {query.storeId}
        </span>
      </InfoBlk>
      {display === "loading" && <PageLoader />}
      {display === "noDataFound" && <NoDataFound>No Data Found</NoDataFound>}
      {display === "details" && (
        <>
          <StoreDealViewBasic
            data={data.basic}
            linkedProducts={data.linkedProducts}
          />

          <div className="row">
            <div className="col-6">
              <StoreDealSalesSummary data={data.sales} />
            </div>
            <div className="col-6">
              <StoreDealPurchaseSummary data={data.purchases} />
            </div>
          </div>

          <div className="mb-3">
            <Tabs
              data={tabs}
              activeTab={activeTab}
              callback={tabCb}
              template={4}
              shadow={true}
            />
          </div>

          {activeTab.key === "sales" && (
            <PosOrders
              dealId={query.id}
              storeId={query.storeId}
              type="all"
              salesData={data.sales} // Pass sales data to PosOrders
            />
          )}
          {activeTab.key === "purchases" && (
            <StoreDealPurchases
              data={data.purchases}
              dealId={data.sellInLooseQty ? data.MasterDealId : query.id}
              storeId={query.storeId}
            />
          )}
          {activeTab.key === "stockLedger" && (
            <StockDetails
              dealId={query.id}
              storeId={query.storeId}
              isLooseDeal={data.sellInLooseQty}
            />
          )}
          {activeTab.key === "priceChangeLog" && (
            <RspChangeLog
              dealId={query.id}
              storeId={query.storeId}
              hideDealInfo={true}
            />
          )}
        </>
      )}
      <StockLedgerDetailModal
        show={showLedgerDetailModal}
        ledgerId={ledgerDetailData.ledgerId}
        callback={closeLedgerDetailModal}
        storeId={query.storeId}
        dealId={query.id}
      />
    </>
  );
};

const getBasicDetails = (data) => {
  return {
    name: data.name,
    category: {
      name: data.categoryName,
      id: data.categoryId,
    },
    brand: {
      name: data.brandName,
      id: data.brandId,
    },
    menu: {
      name: data.menuName,
      id: data.menuId,
    },
    _id: data._id,
    images: data.images,
    mrp: data.mrp,
    price: data._finalSellingPrice,
    stock: data._qty,
    sellInLooseQty: data.sellInLooseQty,
    lastSold: data.lastCustomerOrderDate,
    lastSoldQty: data.lastCustomerOrderQty,
    lastPurchased: data.lastOrderDate,
    lastPurchasedQty: data.lastOrderQty,
    lastIntake: data.lastIntakeDate,
    b2bprice: data.b2bprice,
    pnl: data._pnl,
  };
};

const getSalesDetails = (data) => {
  return {
    sellInLooseQty: data.sellInLooseQty,
    lastOrderDate: data.lastCustomerOrderDate,
    lastOrderQty: data.lastCustomerOrderQty,
    salesData: data.salesData,
  };
};

const getPurchaseDetails = (data) => {
  return {
    purchaseData: data.purchaseData,
    lastPurchasedDate: data.lastOrderDate,
    lastPurchasedUnits: data.lastOrderQty,
    lastInwardedDate: data.lastInwardedDate,
    lastIntakeUnits: data.purchaseData?.qty,
    openOrder: data.openOrder,
  };
};

const getLinkedProducts = async (productIds) => {
  if (productIds.length === 0) return [];

  const linkedProducts = await ProductService.getList({
    page: 1,
    count: productIds.length,
    filter: { _id: { $in: productIds } },
    select: "name",
  });
  return linkedProducts?.resp || [];
};
export default StoreDealView;
