import { NavService } from "@sk/services";
import {
  Amount,
  AppCard,
  BtnLink,
  HighlightText,
  ImgRender,
  KeyVal,
} from "@sk/uis";

const imgStyle = {
  width: "120px",
  height: "120px",
  objectFit: "contain",
};

const StoreDealViewBasic = ({ data, linkedProducts }) => {
  const viewProduct = (id) => {
    NavService.openInNewTab("/cms/product/view", {
      id,
    });
  };

  return (
    <AppCard>
      <div className="row align-items-center">
        <div className="col-auto align-self-center">
          <div style={imgStyle}>
            <ImgRender
              assetId={data.images[0]}
              width="120"
              height="120"
              className="object-contain"
            />
          </div>
        </div>
        <div className="col">
          <div className="d-flex gap-2 mb-3">
            <div className="fs-val-normal fw-bold">{data.name}</div>
          </div>

          <div className="d-flex gap-4 mb-2">
            <div>
              <span className="text-muted">ID: </span>
              <span>{data._id}</span>
            </div>

            <div>
              <span className="text-muted">MRP: </span>
              <span>
                <Amount value={data.mrp} decimalPlace={2} />
              </span>
            </div>

            <div>
              <span className="text-muted">B2B Price: </span>
              <Amount value={data.b2bprice} decimalPlace={2} />
            </div>

            <div>
              <span className="text-muted">RSP: </span>
              <span className="text-danger fw-bold">
                <Amount value={data.price} decimalPlace={2} />
              </span>
            </div>

            {/* Profit and Loss */}
            <div>
              <span className="text-muted">Profit and Loss: </span>
              <HighlightText
                type={
                  data.pnl.value > 0
                    ? "success"
                    : data.pnl.value < 0
                    ? "danger"
                    : "neutral"
                }
                template={2}
                isSoft={true}
              >
                <Amount value={data.pnl.value} decimalPlace={2} />
                <span>
                  {data.pnl.value > 0 ? (
                    <i className="bi bi-arrow-up fw-bold" title="Positive"></i>
                  ) : data.pnl.value < 0 ? (
                    <i
                      className="bi bi-arrow-down fw-bold"
                      title="Negative"
                    ></i>
                  ) : (
                    <i
                      className="bi bi-dash-circle fw-bold"
                      title="Neutral"
                    ></i>
                  )}
                </span>
              </HighlightText>
            </div>
          </div>

          <div className="mb-2">
            <span className="text-muted">Available Stock: </span>
            <HighlightText type={"success"} template={2}>
              <span className="fw-bold">
                {data.stock} {data.sellInLooseQty ? "kg" : "units"}
              </span>
            </HighlightText>
          </div>

          <div>
            <div className="fs-val-sm fw-semibold">Linked Products</div>
            <div className="d-flex gap-2">
              {linkedProducts.map((product) => (
                <div key={product._id}>
                  <BtnLink
                    className="fs-val-sm text-muted"
                    onClick={() => viewProduct(product._id)}
                  >
                    {product.name} - ({product._id})
                  </BtnLink>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="col-4">
          <KeyVal label="Menu">: {data.menu?.name || "N/A"}</KeyVal>

          <KeyVal label="Category">: {data.category?.name || "N/A"}</KeyVal>
          <KeyVal label="Brand">: {data.brand?.name || "N/A"}</KeyVal>
        </div>
      </div>
    </AppCard>
  );
};

export default StoreDealViewBasic;
