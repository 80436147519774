import { PosService } from "@sk/services";
import { DateInput, SelectInput, TextInput } from "@sk/uis";
import { startOfMonth } from "date-fns";
import { debounce } from "lodash";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const statusOptions = PosService.getPosOrderStatusGroups().map((e) => ({
  label: e.name,
  value: e.key,
}));
statusOptions.unshift({ label: "All", value: "" });

const dateConfig = {
  maxDate: new Date(),
};

const PosOrderFilter = ({ callback, showStatus = true }) => {
  const { register, getValues, control, setValue } = useForm({
    defaultValues: {
      search: "",
      date: [startOfMonth(new Date()), new Date()],
      status: "",
    },
  });

  const onInputChange = () => {
    callback({ formData: getValues() });
  };

  const onDateChange = (chngFn) => {
    return (value) => {
      chngFn(value);
      onInputChange();
    };
  };

  const onSearchChange = useCallback(debounce(onInputChange, 500), [
    onInputChange,
  ]);

  return (
    <div className="row">
      <div className="col-3">
        <TextInput
          label="Search"
          name="search"
          register={register}
          gap={0}
          callback={onSearchChange}
          placeholder="Search here..."
        />
      </div>
      <div className="col-3">
        <Controller
          control={control}
          name="date"
          render={({ field }) => (
            <DateInput
              label="Order Date"
              callback={onDateChange(field.onChange)}
              value={field.value}
              gap={0}
              template={2}
              hideClose={true}
              config={dateConfig}
            />
          )}
        />
      </div>
      {showStatus && (
        <div className="col-3">
          <SelectInput
            label="Order Status"
            options={statusOptions}
            register={register}
            name="status"
            callback={onInputChange}
            gap={0}
          />
        </div>
      )}
    </div>
  );
};

export default PosOrderFilter;
