import { PosService, UserService } from "@sk/services";
import {
  Amount,
  AppCard,
  HighlightText,
  ImgRender,
  KeyVal,
  NoDataFound,
  TableHeader,
} from "@sk/uis";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = {
  width: "40%",
};

const imgStyle = {
  width: "90px",
  height: "90px",
};

const headers = [
  { label: "Sl No", width: "8%" },
  { label: "Image", width: "20%" },
  { label: "Status", width: "15%" },
  { label: "Remarks", width: "37%" },
];

const getData = async (params) => {
  const r = await PosService.getPosDeposits(params);
  const data =
    Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0] : r.resp || {};
  if (data._id) {
    let userId = "";
    let userObj = {};

    if (data.status == "Approved") {
      const a = data.auditLog.findLast((x) => x.status === "Approved");
      userId = a?.loggedBy;
    }

    if (data.status == "ProofPending") {
      const a = data.auditLog.findLast((x) => x.status === "ProofPending");
      userId = a?.loggedBy;
    }

    if (userId) {
      const userResp = await UserService.getList({
        filter: { _id: userId },
      });
      userObj =
        Array.isArray(userResp.resp) && userResp.resp.length > 0
          ? userResp.resp[0]
          : {};
    }

    if (data.status == "Approved") {
      data.approvedBy = userObj;
    }

    if (data.status == "ProofPending") {
      data.rejectedBy = userObj;
    }
  }

  return {
    data,
  };
};

const PosCashDepositViewModal = ({ show, callback, refId }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(async () => {
    setLoading(true);
    const r = await getData({ filter: { _id: refId } });
    setData(r.data);
    setLoading(false);
  }, [refId]);

  useEffect(() => {
    if (show && refId) {
      loadData();
    }
  }, [show, refId, loadData]);

  const onClose = () => {
    callback({ action: "close" });
  };

  return (
    <Offcanvas show={show} onHide={onClose} style={canvasStyle} placement="end">
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>Cash Deposit</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        {!loading && data._id ? (
          <>
            <AppCard>
              <KeyVal label="Amount" labelCol="col-5" contentCol="col-7">
                :{" "}
                <span className="text-danger">
                  <Amount value={data.depositedAmount} decimalPlace={2} />
                </span>
              </KeyVal>
              <KeyVal label="Status" labelCol="col-5" contentCol="col-7">
                :{" "}
                <HighlightText type={data._statusBadge} template={2}>
                  {data._statusLbl}
                </HighlightText>
              </KeyVal>
              <KeyVal
                label="Bank Account Number"
                labelCol="col-5"
                contentCol="col-7"
              >
                : {data.bankAccountNo}
              </KeyVal>
              <KeyVal label="Bank IFSC" labelCol="col-5" contentCol="col-7">
                : {data.bankIfsc}
              </KeyVal>
              <KeyVal label="Bank Name" labelCol="col-5" contentCol="col-7">
                : {data.bankName}
              </KeyVal>
              <KeyVal
                label="Account Holder Name"
                labelCol="col-5"
                contentCol="col-7"
              >
                : {data.bankAccountHolderName}
              </KeyVal>

              {data.approvedBy?.name ? (
                <KeyVal label="Approved By" labelCol="col-5" contentCol="col-7">
                  : {data.approvedBy?.name || "-"}
                </KeyVal>
              ) : null}

              {data.rejectedBy?.name ? (
                <KeyVal label="Rejected By" labelCol="col-5" contentCol="col-7">
                  : {data.rejectedBy?.name || "-"}
                </KeyVal>
              ) : null}
            </AppCard>

            <AppCard title="Deposit Proof">
              <table className="table bg-white table-striped table-sm">
                <TableHeader data={headers} noBg={true} />
                <tbody className="fs-val-md">
                  {!data.receipts.length ? (
                    <tr>
                      <td colSpan={headers.length}>
                        <NoDataFound>No proof documents found</NoDataFound>
                      </td>
                    </tr>
                  ) : null}

                  {data.receipts.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        <div style={imgStyle}>
                          <ImgRender
                            assetId={item.id}
                            className="img-fluid h-100 rounded cover"
                            allowPreview={true}
                          />
                        </div>
                      </td>
                      <td>
                        <HighlightText
                          type={item.status ? "success" : "warning"}
                          template={2}
                        >
                          {item.status ? "Approved" : "Rejected"}
                        </HighlightText>
                      </td>
                      <td>{item.remarks || "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </AppCard>
          </>
        ) : null}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PosCashDepositViewModal;
