import { DateInput, SelectInput, TextInput } from "@sk/uis";
import { sub } from "date-fns";
import { debounce } from "lodash";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

const returnTypeOptions = [
  { label: "All", value: "" },
  { label: "Club Returns", value: "club" },
];

const statusOptions = [
  { label: "All", value: "" },
  { label: "Approval Pending", value: "Pending" },
  { label: "Returned", value: "Closed" },
];

const PosReturnFilter = ({ callback, type }) => {
  const { register, getValues, control } = useForm({
    defaultValues: {
      search: "",
      date: [sub(new Date(), { days: 60 }), new Date()],
      returnType: "",
      status: "",
    },
  });

  const onInputChange = () => {
    callback({ formData: getValues() });
  };

  const onDateChange = (chngFn) => {
    return (value) => {
      chngFn(value);
      onInputChange();
    };
  };

  const onSearchChange = useCallback(debounce(onInputChange, 500), [
    onInputChange,
  ]);

  return (
    <div className="row">
      <div className="col-3">
        <TextInput
          label="Search"
          name="search"
          register={register}
          gap={0}
          callback={onSearchChange}
          placeholder="Search here..."
        />
      </div>
      <div className="col-3">
        <Controller
          control={control}
          name="date"
          render={({ field }) => (
            <DateInput
              label="Return Date"
              callback={onDateChange(field.onChange)}
              value={field.value}
              gap={0}
              template={2}
              hideClose={true}
            />
          )}
        />
      </div>
      {type !== "quick" && (
        <div className="col-3">
          <SelectInput
            label="Return Type"
            options={returnTypeOptions}
            register={register}
            name="returnType"
            callback={onInputChange}
            gap={0}
          />
        </div>
      )}
      <div className="col-3">
        <SelectInput
          label="Return Status"
          options={statusOptions}
          register={register}
          name="status"
          callback={onInputChange}
          gap={0}
        />
      </div>
    </div>
  );
};

export default PosReturnFilter;
