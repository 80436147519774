import {
  AppliedFilterLabel,
  BooleanCheckboxInput,
  EntitySearchInput,
  SelectInput,
  TextInput,
} from "@sk/uis"; // Import Select for dropdown
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

const defaultFormData = {
  search: "",
  type: "purchasedFromSk", // Add type for dropdown
  menu: [],
  category: [],
  brand: [],
  showInStockOnly: true,
};

// Define options outside of the component
const typeOptions = [
  { value: "all", label: "All Inventory" },
  { value: "purchasedFromSk", label: "Purchased from SK" },
  { value: "notConfiguredRSP", label: "Not Configured RSP" },
  { value: "kingSlab", label: "King Slab" },
  { value: "looseDeals", label: "Loose Deals" },
  { value: "reserveEnabled", label: "Reserve Enabled" },
  { value: "outOfStock", label: "Only Out of Stock" },
];

function PosStoreFilter({ callback, filterData }) {
  const { control, register, getValues, setValue } = useForm({
    defaultValues: { ...defaultFormData },
  });

  const [appliedFilterLabels, setAppliedFilterLabels] = useState([]); // State for applied filter labels
  const [showMoreFilters, setShowMoreFilters] = useState(false); // State for toggling additional filters

  const [type] = useWatch({
    name: ["type"],
    control,
  });

  useEffect(() => {
    setValue("menu", filterData?.menu || []);
    setValue("category", filterData?.category || []);
    setValue("brand", filterData?.brand || []);
  }, [filterData, setValue]);

  const onSearch = useCallback(
    debounce(() => {
      triggerCb();
    }, 800),
    []
  );

  const triggerCb = (action = "apply") => {
    callback({
      action: action,
      formData: getValues(),
    });
    prepareFilterLabels(); // Update applied filter labels
  };

  // Prepare applied filters for display
  const prepareFilterLabels = () => {
    const filters = [];

    // Get the search value
    const searchValue = getValues("search");
    // Push the search filter only if there is a value
    if (searchValue) {
      filters.push({ key: "search", label: "Search", value: searchValue });
    }

    setAppliedFilterLabels(filters.filter(Boolean)); // Update state with non-null filters
  };

  const handleShowInStockOnly = (changeFn) => (value) => {
    changeFn(value);
    triggerCb();
  };

  // Separate function to handle onChange for EntitySearchInput
  const handleEntityChange = (name, onChange) => (value) => {
    if (name === "menu") {
      setValue("category", []);
      setValue("brand", []);
    }
    onChange(value); // Call the original onChange callback
    triggerCb(); // Trigger the callback
  };

  return (
    <>
      <AppliedFilterLabel
        labels={appliedFilterLabels}
        callback={triggerCb}
        className="mb-3"
      />
      <div className="row">
        <div className="col-4">
          <TextInput
            label="Search"
            placeholder="Search..."
            register={register}
            name="search"
            callback={onSearch}
            size="sm"
            gap={2}
          />
        </div>
        <div className="col-4">
          <SelectInput
            label="Types"
            register={register}
            name="type"
            options={typeOptions}
            defaultValue={getValues("type")}
            callback={(value) => {
              setValue("type", value);
              triggerCb();
            }}
            size="sm"
            gap={2}
          />
        </div>

        {type !== "outOfStock" && (
          <div className="col-auto align-self-end">
            <Controller
              name="showInStockOnly"
              control={control}
              render={({ field }) => (
                <BooleanCheckboxInput
                  label="Show In Stock Only"
                  value={field.value}
                  callback={handleShowInStockOnly(field.onChange)}
                  size="sm"
                  gap={2}
                />
              )}
            />
          </div>
        )}
        <div className="col text-end align-self-center">
          <button
            type="button"
            onClick={() => setShowMoreFilters((prev) => !prev)} // Toggle the visibility
            className="btn btn-link btn-sm mt-3"
          >
            <i
              className={`bi ${
                showMoreFilters ? "bi-chevron-up" : "bi-chevron-down"
              }`}
            ></i>
            {showMoreFilters ? " Less Filters" : " More Filters"}
          </button>
        </div>
      </div>

      {showMoreFilters && ( // Conditionally render additional filters
        <div className="row">
          <div className="col-4">
            <Controller
              name="menu"
              control={control}
              render={({ field }) => (
                <EntitySearchInput
                  type="menu"
                  label="Menu"
                  uid="menu-input"
                  value={field.value}
                  callback={handleEntityChange("menu", field.onChange)}
                  isMultiple={true}
                  size="sm"
                  gap={0}
                />
              )}
            />
          </div>
          <div className="col-4">
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <EntitySearchInput
                  type="category"
                  label="Category"
                  uid="category-input"
                  value={field.value}
                  callback={handleEntityChange("category", field.onChange)}
                  isMultiple={true}
                  size="sm"
                  gap={0}
                />
              )}
            />
          </div>
          <div className="col-4">
            <Controller
              name="brand"
              control={control}
              render={({ field }) => (
                <EntitySearchInput
                  type="brand"
                  label="Brand"
                  uid="brand-input"
                  value={field.value}
                  callback={handleEntityChange("brand", field.onChange)}
                  isMultiple={true}
                  size="sm"
                  gap={0}
                />
              )}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default PosStoreFilter;
