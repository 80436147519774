import { PosService } from "@sk/services";
import {
  Amount,
  AppCard,
  HighlightText,
  KeyVal,
  NoDataFound,
  Pagination,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { set } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useForm } from "react-hook-form";

const canvasStyle = {
  width: "50%",
};

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const defaultFilterData = {};

const defaultFormData = {
  search: "",
  dateRange: [],
  status: "",
};

const headers = [
  { label: "Sl No", width: "8%" },
  { label: "Order ID", width: "25%" },
  { label: "Log ID", width: "25%" },
  { label: "Amount", width: "20%" },
  { label: "Payment Mode", width: "30%" },
];

const getData = async (params) => {
  const r = await PosService.getPosSettlement(params);
  return { data: r.resp || [] };
};

const getCount = async (params) => {
  let p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;

  const r = await PosService.getPosSettlement(p);
  return { count: r.resp?.[0]?.total || 0 };
};

const prepareFilterParams = (filter, pagination) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {
      cashDepositId: filter.refId,
    },
  };

  const s = filter.search?.trim();

  if (s) {
    p.filter.$or = [{ orderId: s }, { logId: { $regex: s, $options: "gi" } }];
  }

  if (filter?.dateRange?.length > 0) {
    p.filter.createdAt = {
      $gte: set(new Date(filter.dateRange[0]), {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(new Date(filter.dateRange[1]), {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  return p;
};

const PosCashDepositOrdersModal = ({
  refId,
  callback,
  show,
  totalAmount,
  status,
  statusBadge,
}) => {
  const { getValues } = useForm({
    defaultValues: defaultFormData,
  });

  const [data, setData] = useState([]);
  const [records, setRecords] = useState({ loading: true, value: 0 });
  const [loadingData, setLoadingData] = useState(true);
  const [storeName, setStoreName] = useState("");

  const filterDataRef = useRef({ ...defaultFilterData });
  const paginationRef = useRef({ ...defaultPaginationConfig });

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    filterDataRef.current = {
      ...filterDataRef.current,
      ...getValues(),
    };

    loadList();

    setRecords({ loading: true, value: 0 });
    const r = await getCount(prepareFilterParams(filterDataRef.current));
    setRecords({ loading: false, value: r.count });
    paginationRef.current.totalRecords = r.count;
  }, [getValues, loadList]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    if (show) {
      filterDataRef.current = { refId };
      init();
    }
  }, [refId, init, show]);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    setData([]);

    const r = await getData(
      prepareFilterParams(filterDataRef.current, paginationRef.current)
    );

    setStoreName(r.data?.[0]?.senderName);

    setData(r.data || []);
    setLoadingData(false);
  }, []);

  const onSearch = useCallback(
    debounce(() => {
      applyFilter();
    }, 800),
    []
  );

  const paginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
  };

  const onClose = () => {
    callback({ action: "close" });
  };

  const handleOrderClick = (orderId) => {
    callback({ action: "viewOrder", orderId });
  };

  return (
    <Offcanvas show={show} onHide={onClose} placement="end" style={canvasStyle}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Deposit Orders - {refId}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        <AppCard className="mb-3">
          <div className="row">
            <div className="col-md-7">
              <KeyVal label="Store" template="col" className="mb-2">
                <div className="mb-1">{storeName}</div>
                <div className="text-muted fs-val-sm">
                  FID: {data?.[0]?.senderId}
                </div>
              </KeyVal>
            </div>
            <div className="col-md-5">
              <KeyVal
                label="Total Amount"
                labelCol="col-md-5"
                contentCol="col-md-7"
                noBottomSpace={true}
                className="mb-1"
                fwSize="md"
              >
                :{" "}
                <span className="text-danger">
                  <Amount value={totalAmount} />
                </span>
              </KeyVal>
              <KeyVal
                label="Orders"
                labelCol="col-md-5"
                contentCol="col-md-7"
                noBottomSpace={true}
                className="mb-1"
                fwSize="md"
              >
                : {records.value}
              </KeyVal>
              <KeyVal
                label={`Status`}
                labelCol="col-md-5"
                contentCol="col-md-7"
                noBottomSpace={true}
                fwSize="md"
              >
                :{" "}
                <HighlightText type={statusBadge} template={2}>
                  {status || "Not Deposited"}
                </HighlightText>
              </KeyVal>
            </div>
          </div>
        </AppCard>

        <AppCard>
          <PaginationSummary
            loadingTotalRecords={records.loading}
            paginationConfig={paginationRef.current}
            className="mb-3"
          />

          <div className="table-responsive">
            <table className="table bg-white table-striped table-sm">
              <TableHeader data={headers} noBg={true} />
              <tbody className="fs-val-md">
                {loadingData ? (
                  <TableSkeletonLoader cols={headers.length} rows={10} />
                ) : null}

                {!loadingData && !data.length ? (
                  <tr>
                    <td colSpan={headers.length}>
                      <NoDataFound>No Data found</NoDataFound>
                    </td>
                  </tr>
                ) : null}

                {data.map((item, index) => (
                  <tr key={item.orderId}>
                    <td>{paginationRef.current.startSlNo + index}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-link fs-val-md text-start text-dark p-0"
                        onClick={() => handleOrderClick(item.orderId)}
                      >
                        {item.orderId}
                      </button>
                    </td>
                    <td>{item._id}</td>
                    <td>
                      <Amount value={item.totalAmount} />
                    </td>
                    <td>{item.paymentMode}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <Pagination
              callback={paginationCb}
              activePage={paginationRef.current.activePage}
              rowsPerPage={paginationRef.current.rowsPerPage}
              totalRecords={paginationRef.current.totalRecords}
            />
          </div>
        </AppCard>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PosCashDepositOrdersModal;
