import {
  CommonService,
  DealService,
  FranchiseService,
  PosService,
} from "@sk/services";
import {
  AppCard,
  BusyLoader,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import PosStoreNewlyLaunchedFilter from "./components/PosStoreNewlyLaunchedFilter";
import { AddToPurchaseBasketModal } from "@sk/features";

// Define header data for the table
const headerData = [
  { key: "_slNo", label: "#", width: "3%" },
  { key: "name", label: "Deal Name", width: "20%" },
  { key: "id", label: "Deal ID", width: "10%" },
  { key: "mrp", label: "MRP", width: "10%" },
  { key: "ptr", label: "PTR", width: "10%" },
  { key: "discount", label: "Discount", width: "10%" },
  { key: "menu", label: "Menu", width: "10%" },
  { key: "brand", label: "Brand", width: "10%" },
  { key: "category", label: "Category", width: "10%" },
  { key: "action", label: "Action", width: "10%" },
];

// Pagination configuration
const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

// Fetch deals data
const getData = async (params) => {
  const response = await PosService.getNewlyLaunchedProducts(params);
  return { data: Array.isArray(response.resp) ? response.resp : [] };
};

// Fetch count of deals
const getCount = async (params) => {
  let p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;

  const response = await PosService.getNewlyLaunchedProducts(p);
  return { count: response.resp?.[0]?.total };
};

// Add the prepareFilterParams function
const prepareFilterParams = (filter, pagination) => {
  let params = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      whId: { $in: filter.whIds },
      sellerId: filter.fid,
    },
    projectFilter: {},
  };

  const searchTerm = filter.searchTerm?.trim();
  if (searchTerm) {
    params.projectFilter.$or = [
      { name: { $regex: searchTerm, $options: "i" } },
    ];
  }

  if (filter.menu) {
    params.projectFilter.menuId = filter.menu;
  }

  if (filter.brand?.length) {
    const brandIds = filter.brand.map((item) => item.value._id);
    params.projectFilter.brandId = {
      $in: brandIds,
    };
  }

  if (filter.category?.length) {
    const categoryIds = filter.category.map((item) => item.value._id);
    params.projectFilter.categoryId = {
      $in: categoryIds,
    };
  }

  // delete projectFilter if empty
  if (Object.keys(params.projectFilter).length === 0) {
    delete params.projectFilter;
  }

  return params;
};

const tableContainerStyle = {
  height: "calc(100vh - 300px)",
  overflowY: "auto",
};

const PosStoreNewlyLaunched = ({ storeId }) => {
  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [busyLoading, setBusyLoading] = useState(false); // Separate loading state for BusyLoader
  const [recordsCount, setRecordsCount] = useState({
    loading: false,
    count: 0,
  });

  const filterRef = useRef({
    search: "",
    menu: null,
    brand: null,
    category: null,
    sellerId: "",
    fid: "",
    whIds: [],
  });

  const paginationRef = useRef({ ...defaultPaginationConfig });

  const [basketModal, setBasketModal] = useState({
    show: false,
    dealId: null,
    useDealInfo: null,
  });

  const handleAddToBasket = async (deal) => {
    setBusyLoading(true); // Show Busyloader
    try {
      const response = await DealService.getDetailsById(deal._id);
      const dealDetails = response.resp;

      const dealInfo = {
        _id: dealDetails._id,
        name: dealDetails.name,
        menuId: dealDetails.menuId,
        menuName: dealDetails.menuName,
        categoryId: dealDetails.categoryId,
        categoryName: dealDetails.categoryName,
        brandId: dealDetails.brandId,
        brandName: dealDetails.brandName,
        mrp: dealDetails.mrp,
        b2bprice: dealDetails.b2bPrice,
        images: dealDetails.images, // Update images from API response
        lastOrderDate: dealDetails.lastOrderDate,
        lastOrderQty: dealDetails.lastOrderQty,
        _qty: dealDetails._qty,
        sellInLooseQty: dealDetails.sellInLooseQty,
        caseQty: dealDetails.caseQty,
        innerPackQty: dealDetails.innerPackQty,
        salesData: dealDetails.salesData,
      };

      setBasketModal({
        show: true,
        dealId: dealDetails._id,
        useDealInfo: dealInfo,
      });
    } catch (error) {
      console.error("Error fetching deal details:", error);
    } finally {
      setBusyLoading(false); // Hide Busyloader
    }
  };

  const handleBasketModalCallback = (data) => {
    if (data.action === "addToBasket") {
      Toaster.success(
        `Added ${data.purchaseDetails.quantity} ${data.purchaseDetails.dealInfo.name} to basket`
      );
      setBasketModal({ show: false, dealId: null });
    } else if (data.action === "close") {
      setBasketModal({ show: false, dealId: null });
    }
  };

  // Fetch franchise data when the component mounts or storeId changes
  useEffect(() => {
    const fetchFranchiseData = async () => {
      try {
        const response = await FranchiseService.getFcList(storeId);
        const fcList = response.resp?.fcList;
        const fcIds = fcList?.map((fc) => fc.whId);
        const franchise = await FranchiseService.getFranchise(storeId, {
          select: "name,sk_franchise_details",
        });
        filterRef.current.fid = franchise.resp?._id;
        filterRef.current.whIds = fcIds;

        applyFilter();
      } catch (error) {
        console.error("Error fetching franchise data:", error);
      }
    };

    if (storeId) {
      fetchFranchiseData(); // Call the function to fetch data
    }
  }, [storeId, applyFilter]); // Dependency array includes storeId

  const applyFilter = useCallback(async () => {
    paginationRef.current = {
      ...defaultPaginationConfig,
    };

    const params = prepareFilterParams(
      filterRef.current,
      paginationRef.current
    );

    // Fetch total count of deals
    setRecordsCount({ loading: true, count: 0 });
    const { count } = await getCount(params);
    setRecordsCount({ loading: false, count });
    console.log("Total Records Count:", count);
    paginationRef.current.totalRecords = count;

    const { data } = await getData(params);
    setDeals(data);
    setLoading(false);
  }, []);

  const onPaginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    applyFilter();
    CommonService.scrollToView();
  };

  const onFilterCb = (data) => {
    if (data.action === "apply") {
      filterRef.current = {
        ...filterRef.current,
        ...data.formData,
      };
      console.log("Filter Data:", filterRef.current);
      applyFilter();
    }
  };

  const handleFilterClick = (type, value) => {
    let params = {};

    if (type == "menu") {
      params.menu = value.menuId;
    } else {
      params[type] = [
        { label: value[type + "Name"], value: { _id: value[type + "Id"] } },
      ];
    }

    filterRef.current = {
      ...filterRef.current,
      ...params,
    };

    console.log("Filter Data:", filterRef.current);

    applyFilter();
  };

  return (
    <>
      <BusyLoader show={busyLoading} />
      <AppCard>
        <PosStoreNewlyLaunchedFilter
          storeId={storeId}
          callback={onFilterCb}
          filterData={filterRef.current} // Pass filterData as a prop
        />
      </AppCard>
      <AppCard>
        <PaginationSummary
          loadingTotalRecords={recordsCount.loading}
          paginationConfig={paginationRef.current}
          className="mb-3"
        />
        <div
          className="tbl-scroll-container custom-scrollbar thin-scrollbar fixed-width-table"
          style={tableContainerStyle}
        >
          <table className="table table-sm mb-0 bg-white table-hover">
            <TableHeader data={headerData} noBg isSticky={true} />
            <tbody className="fs-val-md">
              {loading ? (
                <TableSkeletonLoader cols={headerData.length} />
              ) : null}

              {!loading && deals.length === 0 ? (
                <tr>
                  <td colSpan={headerData.length} className="text-center">
                    <NoDataFound>No Data Found</NoDataFound>
                  </td>
                </tr>
              ) : null}

              {deals.map((deal, index) => (
                <tr key={index}>
                  <td className="text-center">
                    {paginationRef.current.startSlNo + index}
                  </td>
                  <td>
                    <span className="btn btn-link text-dark text-start fs-val-md p-0 text-wrap">
                      {deal.name}
                    </span>
                  </td>
                  <td>{deal._id}</td>
                  <td>{deal.mrp}</td>
                  <td>{deal.ptr}</td>
                  <td>{deal.discount}</td>
                  <td>
                    <span
                      className="btn btn-link text-dark text-start fs-val-md p-0 text-wrap"
                      onClick={() => handleFilterClick("menu", deal)}
                    >
                      {deal.menuName}
                    </span>
                  </td>
                  <td>
                    <span
                      className="btn btn-link text-dark text-start fs-val-md p-0 text-wrap"
                      onClick={() => handleFilterClick("brand", deal)}
                    >
                      {deal.brandName}
                    </span>
                  </td>
                  <td>
                    <span
                      className="btn btn-link text-dark text-start fs-val-md p-0 text-wrap"
                      onClick={() => handleFilterClick("category", deal)}
                    >
                      {deal.categoryName}
                    </span>
                  </td>
                  <td>
                    <button
                      className="btn btn-outline-primary btn-sm rounded-pill px-3 fs-val-sm"
                      onClick={() => handleAddToBasket(deal)}
                    >
                      <i className="bi bi-cart-plus me-1"></i>
                      Add
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <PaginationBlock
          loadingTotalRecords={recordsCount.loading}
          paginationCb={onPaginationCb}
          size="sm"
          paginationConfig={paginationRef.current}
        />
      </AppCard>
      <AddToPurchaseBasketModal
        show={basketModal.show}
        dealId={basketModal.dealId}
        storeId={storeId}
        callback={handleBasketModalCallback}
        useDealInfo={basketModal.useDealInfo} // Pass useDealInfo prop
      />
    </>
  );
};

export default PosStoreNewlyLaunched;
