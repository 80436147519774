// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fq4bHH1X_BtTEmhTaG2q{position:fixed;height:100%;width:100%;left:60px;width:calc(100% - 60px)}`, "",{"version":3,"sources":["webpack://./src/pages/main/PosMain.module.scss"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,WAAA,CACA,UAAA,CACA,SAAA,CACA,uBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pos-erp-frame": `fq4bHH1X_BtTEmhTaG2q`
};
export default ___CSS_LOADER_EXPORT___;
