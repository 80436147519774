import React from "react";
import { Amount } from "@sk/uis";
import styles from "./OrderMonitorItem.module.scss";
import classNames from "classnames"; // Import classNames
import { NavService } from "@sk/services";

const OrderMonitorItem = ({ order, columnKey, viewOrderDetail }) => {
  const handleClick = () => {
    NavService.openInNewTab("/pos/request/view", {
      id: order.requestId,
    });
  };

  const renderCustomerInfo = () => (
    <div className={styles["order-customer-info"]}>
      <div>
        <span className="text-secondary">Customer: </span>
        <span>{order.customerName}</span>
      </div>
      <div className="color-black-m mobile">
        <i className="bi bi-phone me-1"></i> {order.customerPhone}
      </div>
    </div>
  );

  const renderDeliveryInfo = () => (
    <div className={styles["order-delivery-info"]}>
      <div className={styles["delivery-person-section"]}>
        {/* Section for delivery person */}
        <div className={styles["delivery-person-details"]}>
          {/* Details container */}
          <span className={styles["info-label"]}>Delivery:</span>
          <span className={styles["delivery-person-name"]}>
            {order.deliveryPersonInfo?.name || "--"}
          </span>
        </div>
        {order.deliveryPersonInfo?.mobile ? (
          <div className="color-black-m mobile">
            <i className="bi bi-phone me-1"></i>{" "}
            {order.deliveryPersonInfo?.mobile}
          </div>
        ) : null}
      </div>
      <div className={styles["otp-section"]}>
        {/* Section for OTP */}
        <span className={styles["info-label"]}>OTP:</span>
        <span className={styles["otp-value"]}>
          {order.deliveryPersonInfo?.deliveryOtp || "--"}
        </span>
      </div>
    </div>
  );

  return (
    <div
      className={classNames(
        styles["order-item"],
        "cursor-pointer",
        { [styles["delayed-order"]]: order.isDelayed } // Conditional class
      )}
      onClick={handleClick}
    >
      <div className={styles["order-item-header"]}>
        <div>#{order.id}</div>
      </div>

      <div>
        {columnKey !== "newOrders" && order.timeDisplay && (
          <div className={classNames('styles["time-stamp"]', "fs-val-sm")}>
            <span
              className={classNames({
                [styles["overdue-time"]]: order.isDelayed,
              })}
            >
              {order.isDelayed ? "Delayed in " : "Time Left"}{" "}
              {order.timeDisplay}
            </span>
          </div>
        )}
      </div>

      {columnKey == "newOrders" || columnKey == "unassignedOrders" // updated key
        ? renderCustomerInfo()
        : null}

      {columnKey == "readyToShip" || columnKey == "inTransit"
        ? renderDeliveryInfo()
        : null}

      <div className={styles["order-product-summary"]}>
        <div>
          <span className="text-secondary">SKUs: </span>
          {order.skuCount}
        </div>
        <div>
          <span className="text-secondary">Units: </span>
          {order.totalUnits}
        </div>
        <div className="text-end fw-semibold">
          <Amount value={order.totalAmount} />
        </div>
      </div>

      {(columnKey == "picking" ||
        columnKey == "yetToStartPacking" ||
        columnKey == "packing") && (
        <div className={styles["order-picker"]}>
          <div>
            <span className="text-secondary">Picker: </span>
            <span>{order.picker || "N/A"}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderMonitorItem;
