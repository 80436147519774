import { RspChangeLog } from "@sk/features";
import { Offcanvas } from "react-bootstrap";

const offcanvasStyle = { width: "60%" };

const PosRspLogModal = ({ dealId, storeId, show, callback }) => {
  return (
    <Offcanvas
      show={show}
      onHide={() => callback({ action: "close" })}
      placement="end"
      style={offcanvasStyle}
    >
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>RSP Changelog - #{dealId}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        <RspChangeLog dealId={dealId} storeId={storeId} hideDealInfo={false} />
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PosRspLogModal;
