import { AppCard, DateInput, SelectInput, TextInput } from "@sk/uis";
import { format, startOfMonth, subMonths } from "date-fns";
import { debounce } from "lodash";
import { useCallback } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

const endDate = new Date();
const startDate = startOfMonth(endDate);

const typeOptions = [
  { label: "Pending to Collect", value: "Pending" },
  { label: "Collected", value: "Collected" },
];

const defaultFormData = {
  search: "",
  dateRange: [format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd")],
  type: "Pending",
};

const dtConfig = {
  mode: "range",
  maxDate: new Date(),
};

const PosCashCollectFilter = ({ callback }) => {
  const { control, register, getValues, setValue } = useForm({
    defaultValues: {
      ...defaultFormData,
    },
  });

  const [type] = useWatch({
    control,
    name: ["type"],
  });

  const onInpChange = (chngFn) => (val) => {
    chngFn(val);
    triggerCb();
  };

  const onTypeChange = () => {
    triggerCb();
  };

  const triggerCb = () => {
    let f = getValues();
    callback({ formData: { ...f } });
  };

  const onSearchChange = useCallback(
    debounce(() => {
      triggerCb();
    }, 500),
    [triggerCb]
  );

  return (
    <>
      <AppCard>
        <div className="row">
          <div className="col-md-3">
            <SelectInput
              name="type"
              options={typeOptions}
              callback={onTypeChange}
              gap={0}
              register={register}
            />
          </div>
          <div className="col-md-3">
            <TextInput
              name="search"
              placeholder="Search by Delivery Person"
              register={register}
              gap={0}
              callback={onSearchChange}
            />
          </div>
          {type === "Pending" ? null : (
            <div className="col-md-3">
              <Controller
                name="dateRange"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <DateInput
                    placeholder="Select start date"
                    value={value}
                    callback={onInpChange(onChange)}
                    config={dtConfig}
                    hideClose={true}
                    gap={0}
                    template={2}
                    disabled={type === "Pending"}
                  />
                )}
              />
            </div>
          )}
        </div>
      </AppCard>
    </>
  );
};

export default PosCashCollectFilter;
