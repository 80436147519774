import { FranchiseService } from "@sk/services";
import { DateFormatter } from "@sk/uis";
import { useEffect, useState } from "react";

const ClubReqTimeline = ({ timelineData }) => {
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    const ids = timelineData.map((x) => x.doneBy).filter(Boolean);
    if (ids.length > 0) {
      // fetch user details
      fetchUserDetails(ids);
    }
  }, [timelineData]);

  const fetchUserDetails = async (ids) => {
    try {
      const response = await FranchiseService.getFranchiseManpower({
        page: 1,
        count: ids.length,
        filter: {
          _id: { $in: ids },
        },
      });
      const data = Array.isArray(response.resp) ? response.resp : [];
      const userMap = data.reduce((acc, user) => {
        acc[user._id] = user.name;
        return acc;
      }, {});

      userMap["System"] = "System";

      setUserDetails(userMap);
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  return (
    <>
      <div className="activity">
        {timelineData.map((x, i) => (
          <div
            className="activity-info"
            key={i}
            style={{ opacity: x.time ? 1 : 0.5 }}
          >
            <div className="icon-info-activity">
              <i
                className={`bi ${x.icon} ${
                  x.time ? "bg-success text-white" : ""
                }`}
              />
            </div>
            <div className="activity-info-text">
              <div className="fs-val-md">{x.label}</div>
              <div className="text-muted d-block fs-val-sm">
                {x.time ? (
                  <>
                    <DateFormatter date={x.time} />
                    {x.doneBy ? (
                      <p className="text-muted mt-3 fs-val-sm">
                        {x.remarks.replace(
                          "[pickerName]",
                          userDetails[x.doneBy] || "Unknown"
                        )}
                      </p>
                    ) : null}
                  </>
                ) : (
                  <span className="text-muted">Not done yet</span>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ClubReqTimeline;
