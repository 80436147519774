import { PosService } from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { endOfDay, startOfDay, startOfMonth } from "date-fns";
import produce from "immer";
import { useCallback, useEffect, useRef, useState } from "react";
import PosCashDepositsSummary from "./PosCashDeposistsSummary";
import PosCashDepositsFilter from "./PosCashDepositsFilter";
import PosCashDepositOrdersModal from "./modals/PosCashDepositOrders";
import PosCashDepositViewModal from "./modals/PosCashDepositViewModal";
import { PosOrderDetailModal } from "@sk/features";

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "Reference ID", width: "15%" },
  { label: "Amount", width: "15%" },
  { label: "Payment Mode", width: "15%" },
  { label: "Status", width: "15%" },
  { label: "Logged At", width: "15%" },
  { label: "Action", width: "20%" },
];

const defaultSummaryCards = [
  {
    title: "Deposit Pending",
    value: 0,
    isValueAmt: true,
    valueColor: "warning",
    icon: "bi bi-hourglass-split",
    loading: true,
    valueKey: "Created",
  },
  {
    title: "Approval Pending",
    value: 0,
    isValueAmt: true,
    valueColor: "info",
    icon: "bi bi-clock",
    loading: true,
    valueKey: "PendingApproval",
  },
  {
    title: "Approved",
    value: 0,
    isValueAmt: true,
    valueColor: "success",
    icon: "bi bi-check-circle",
    loading: true,
    valueKey: "Approved",
  },
  {
    title: "Rejected",
    value: 0,
    isValueAmt: true,
    valueColor: "danger",
    icon: "bi bi-x-circle",
    loading: true,
    valueKey: "ProofPending",
  },
];

const getData = async (params) => {
  const r = await PosService.getPosDeposits(params);
  const d = Array.isArray(r.resp) ? r.resp : [];

  const empIds = d.map((x) => x._id);

  if (empIds.length) {
    const empList = await PosService.getEmpList({
      page: 1,
      count: empIds.length,
      filter: {
        _id: { $in: empIds },
      },
    });
    d.forEach((x) => {
      const emp = empList.resp.find((e) => e._id === x._id);
      x.deliveryPerson = emp;
    });
  }

  return { data: d };
};

const getCount = async (params) => {
  const p = { ...params };
  p.outputType = "count";

  delete p.count;
  delete p.page;
  delete p.sort;

  const r = await PosService.getPosDeposits(p);
  return { count: r.statusCode == 200 && r.resp[0] ? r.resp[0].total : 0 };
};

const prepareParams = (filter, pagination) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      "franchiseInfo.id": filter.storeId,
      createdAt: {
        $gte: startOfDay(new Date(filter.dateRange[0])),
        $lte: endOfDay(new Date(filter.dateRange[1])),
      },
      paymentMode: {
        $in: ["CASH", "UPI"],
      },
    },
  };

  const search = filter.search?.trim();

  if (search) {
    p.filter._id = { $regex: search, $options: "i" };
  }

  if (filter.paymentMode) {
    p.filter.paymentMode = filter.paymentMode;
  }

  if (filter.status) {
    p.filter.status = filter.status;
  }

  return p;
};

const PosCashDeposits = ({ storeId }) => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [records, setRecords] = useState({ loading: true, value: 0 });
  const [type, setType] = useState("Pending");
  const [summaryCards, setSummaryCards] = useState([...defaultSummaryCards]);

  const [ordersModal, setOrdersModal] = useState({ show: false, id: "" });
  const [viewModal, setViewModal] = useState({
    show: false,
    id: "",
    status: "",
    statusBadge: "",
    totalAmount: 0,
  });

  const [orderDetailModal, setOrderDetailModal] = useState({
    show: false,
    orderId: null,
  });

  const filterDataRef = useRef({
    type: "Pending",
    dateRange: [startOfMonth(new Date()), new Date()],
  });
  const paginationRef = useRef({ ...defaultPaginationConfig });

  const loadSummaryCards = useCallback(async () => {
    setSummaryCards(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );

    const p = prepareParams(filterDataRef.current, paginationRef.current);
    p.groupbycond = "status";

    delete p.count;
    delete p.page;
    delete p.sort;

    const r = await getData(p);
    setSummaryCards(
      produce((draft) => {
        draft.forEach((x) => {
          x.value = r.data.find((y) => y._id === x.valueKey)?.totalAmt || 0;
          x.loading = false;
        });
      })
    );
    // setSummaryCards(defaultSummaryCards);
  }, []);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    loadList();

    loadSummaryCards();

    setRecords({ loading: true, value: 0 });
    const r = await getCount(
      prepareParams(filterDataRef.current, paginationRef.current)
    );
    setRecords({ loading: false, value: r.count });
    paginationRef.current.totalRecords = r.count;
  }, [loadList, loadSummaryCards]);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    setData([]);

    const r = await getData(
      prepareParams(filterDataRef.current, paginationRef.current)
    );
    const d = r.data || [];
    setData(d);
    setLoadingData(false);
  }, []);

  useEffect(() => {
    filterDataRef.current = {
      ...filterDataRef.current,
      storeId,
    };
    applyFilter();
  }, [applyFilter, storeId]);

  const paginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
  };

  const onFilterCb = (data) => {
    filterDataRef.current = {
      ...filterDataRef.current,
      ...data.formData,
    };

    setType(data.formData.type);

    applyFilter();
  };

  const handleOrderDetailClose = (data) => {
    if (data.action === "close") {
      setOrderDetailModal({
        show: false,
        orderId: null,
      });
    }
  };

  const onOrdersModalClose = (data) => {
    setOrdersModal({ show: false, id: "" });

    if (data.action === "viewOrder") {
      setOrderDetailModal({
        show: true,
        orderId: data.orderId,
      });
    }
  };

  const onViewOrders = (data) => {
    setOrdersModal({
      show: true,
      id: data._id,
      status: data._statusLbl,
      statusBadge: data._statusBadge,
      totalAmount: data.depositedAmount,
    });
  };

  const onView = (id) => {
    setViewModal({ show: true, id });
  };

  const viewModalCb = () => {
    setViewModal({ show: false, id: "" });
  };

  return (
    <>
      <PosCashDepositsFilter callback={onFilterCb} />
      <PosCashDepositsSummary summaryCards={summaryCards} type={type} />
      <AppCard>
        <div className="d-flex align-items-center mb-1">
          <PaginationSummary
            loadingTotalRecords={records.loading}
            paginationConfig={paginationRef.current}
          />
        </div>

        <table className="table bg-white table-striped table-sm table-hover">
          <TableHeader data={headers} noBg={true} />
          <tbody className="fs-val-md">
            {!loadingData && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Data found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {loadingData ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={10}
                height={40}
              />
            ) : null}

            {data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                <td>{paginationRef.current.startSlNo + index}</td>
                <td>{x._id}</td>
                <td>
                  <Amount value={x.depositedAmount || 0} decimal={2} />
                </td>
                <td>{x.paymentMode}</td>
                <td>
                  <HighlightText type={x._statusBadge} template={2}>
                    {x._statusLbl}
                  </HighlightText>
                </td>
                <td>
                  <DateFormatter date={x.createdAt} />
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-link p-0 text-dark fs-val-md"
                    onClick={() => onViewOrders(x)}
                  >
                    View Orders
                  </button>
                  {x.status != "Created" ? (
                    <>
                      <span className="mx-2"> | </span>
                      <button
                        className="btn btn-sm btn-link p-0 text-dark fs-val-md"
                        onClick={() => onView(x._id)}
                      >
                        View Deposit Proof
                      </button>
                    </>
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="text-end">
          <PaginationBlock
            loadingTotalRecords={records.loading}
            paginationCb={paginationCb}
            paginationConfig={paginationRef.current}
          />
        </div>
      </AppCard>

      <PosCashDepositOrdersModal
        show={ordersModal.show}
        callback={onOrdersModalClose}
        refId={ordersModal.id}
        totalAmount={ordersModal.totalAmount}
        status={ordersModal.status}
        statusBadge={ordersModal.statusBadge}
      />

      <PosCashDepositViewModal
        show={viewModal.show}
        callback={viewModalCb}
        refId={viewModal.id}
      />

      <PosOrderDetailModal
        show={orderDetailModal.show}
        callback={handleOrderDetailClose}
        orderId={orderDetailModal.orderId}
      />
    </>
  );
};

export default PosCashDeposits;
