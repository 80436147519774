import { StockLedgerModal, StoreCard } from "@sk/features";
import { CommonService, FranchiseService, PosService } from "@sk/services";
import {
  Amount,
  AppCard,
  BusyLoader,
  HighlightText,
  PageInfo,
  PageLoader,
  PaginationBlock,
  SelectInput,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
  Toaster,
} from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate } from "react-router-dom";
import "./PosRackBin.scss";
import PosRackBinSearchInp from "./PosRackBinSearchInp";
import PosBinItemsModal from "./modals/bin-items/PosBinItemsModal";

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const breadcrumbs = [{ name: "Home", link: "/" }, { name: "Rack Bin" }];

const tableHeader = [
  { label: "#", key: "empty", width: "3%" },
  { label: "Deal", key: "deal", width: "20%" },
  { label: "Deal ID", key: "dealId", width: "10%" },
  { label: "Menu", key: "menu", width: "15%" },
  { label: "Category", key: "category", width: "12%" },
  { label: "Brand", key: "brand", width: "10%" },
  { label: "MRP", key: "mrp", width: "5%" },
  { label: "Location", key: "location", width: "8%" },
  { label: "Rack", key: "rack", width: "5%" },
  { label: "Bin", key: "bin", width: "5%" },
  { label: "Stock", key: "stock", width: "10%" },
];

const prepareParams = (filter, pagination, isDownload = false) => {
  const params = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      "location.id": filter.locationId,
    },
    menuBrandCatFilter: {
      $and: [],
    },
  };

  const { menuSearch, categorySearch, brandSearch } = filter;

  if (menuSearch.length > 0) {
    params.menuBrandCatFilter.$and.push({
      [isDownload ? "MenuId" : "MenuID"]: {
        $in: menuSearch.map((x) => x.value),
      },
    });
  }

  if (categorySearch.length > 0) {
    params.menuBrandCatFilter.$and.push({
      [isDownload ? "CategoryId" : "DealCategoryId"]: {
        $in: categorySearch.map((x) => x.value),
      },
    });
  }

  if (brandSearch.length > 0) {
    params.menuBrandCatFilter.$and.push({
      [isDownload ? "BrandId" : "DealBrandId"]: {
        $in: brandSearch.map((x) => x.value),
      },
    });
  }

  if (filter.search?.length > 0) {
    params.filter.$or = [
      {
        productName: {
          $regex: filter.search,
          $options: "gi",
        },
      },
      {
        dealId: filter.search,
      },
    ];
  }

  if (!params.menuBrandCatFilter.$and.length) {
    delete params.menuBrandCatFilter;
  }

  return params;
};

// Move getCount function outside the component
const getCount = async (params, sellerId) => {
  const r = await PosService.searchDealInRackBin(sellerId, {
    ...params,
    outputType: "count",
  });
  return r?.resp?.[0]?.total || 0;
};

const PosRackBin = () => {
  const [searchParams] = useSearchParams();
  const storeId = searchParams.get("fid");
  const navigate = useNavigate();

  const { register, getValues } = useForm();
  const [data, setData] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("L1");

  const [display, setDisplay] = useState("loading");

  const [menuSearch, setMenuSearch] = useState([]);
  const [categorySearch, setCategorySearch] = useState([]);
  const [brandSearch, setBrandSearch] = useState([]);

  const [busyLoader, setBusyLoader] = useState({ show: false });

  const [searchData, setSearchData] = useState({
    loading: false,
    data: [],
  });

  const origDataRef = useRef([]);

  const filterDataRef = useRef({
    storeId: "",
    locationId: "L1",
    menuSearch: [],
    categorySearch: [],
    brandSearch: [],
  });

  const paginationRef = useRef({ ...defaultPagination });

  // New state for total records
  const [totalRecords, setTotalRecords] = useState({
    loading: false,
    value: 0,
  });

  // State for StockLedgerModal
  const [stockLedgerModal, setStockLedgerModal] = useState({
    show: false,
    pid: "",
  });

  // State for PosBinItemsModal
  const [binItemsModal, setBinItemsModal] = useState({
    show: false,
    binInfo: null,
  });

  const init = useCallback(async () => {
    setDisplay("loading");
    await loadLocations();
    loadData(selectedLocation);
  }, [loadData, loadLocations, selectedLocation]);

  const loadData = useCallback(async (locationId) => {
    setDisplay("loading");

    const r = await PosService.getRackBin({
      filter: {
        franchiseId: filterDataRef.current.storeId,
        code: locationId,
      },
    });

    origDataRef.current = r.resp?.[0]?.racks || [];

    const f = await FranchiseService.getFranchise(
      filterDataRef.current.storeId,
      { select: "sk_franchise_details" }
    );
    const sellerId = f?.resp?.sellerId || "";

    filterDataRef.current = {
      ...filterDataRef.current,
      sellerId,
    };

    const r2 = await PosService.getRackBinAllotmentPercent({
      queryType: "fetchDataByBin",
      filter: {
        "sellerInfo._id": sellerId,
        isUsable: locationId == "L1",
      },
    });

    origDataRef.current.forEach((x) => {
      x.bins.forEach((e) => {
        const t = (r2?.resp || []).find((i) => i.Bin == e.code);

        e._percentage = t?.Percentage || 0;
        e._cssClass = "";
        if (e._percentage > 0 && e._percentage <= 40) {
          e._cssClass = "stock-fill-1";
        }
        if (e._percentage > 40 && e._percentage <= 80) {
          e._cssClass = "stock-fill-2";
        }
        if (e._percentage > 80) {
          e._cssClass = "stock-fill-3";
        }
        e._stock = t?.TotalQuantity || 0;
      });
    });

    setData(origDataRef.current);

    setDisplay("data");
  }, []);

  const loadLocations = useCallback(async () => {
    const locs = await PosService.getRackBin({
      groupbycond: "location",
      filter: {
        franchiseId: filterDataRef.current.storeId,
      },
    });
    setLocations(
      Array.isArray(locs.resp)
        ? locs.resp
            .map((location) => ({
              value: location._id,
              label: location.name,
            }))
            .reverse()
        : []
    );
  }, []);

  useEffect(() => {
    filterDataRef.current = {
      ...filterDataRef.current,
      storeId,
    };
    init();
  }, [init, storeId]);

  const decideDisplay = useCallback(() => {
    const hasSearchValues =
      filterDataRef.current.menuSearch.length > 0 ||
      filterDataRef.current.categorySearch.length > 0 ||
      filterDataRef.current.brandSearch.length > 0 ||
      getValues("search").trim()?.length > 0;

    return hasSearchValues ? "search" : "data";
  }, [getValues]);

  const onLocationChange = (value) => {
    filterDataRef.current = {
      ...filterDataRef.current,
      locationId: value,
    };

    setMenuSearch([]);
    setCategorySearch([]);
    setBrandSearch([]);

    const d = decideDisplay();
    setDisplay(d);

    setSelectedLocation(value);
    if (d === "search") {
      applySearch();
    } else {
      loadData(value);
    }
  };

  const handleDownload = async () => {
    const params = prepareParams(
      filterDataRef.current,
      paginationRef.current,
      true
    );

    const isUsable = selectedLocation === "L1";
    params.filter.isUsable = isUsable;

    delete params.page;
    delete params.count;

    setBusyLoader({ show: true });

    const r = await PosService.downloadRackBin(
      filterDataRef.current.sellerId,
      params
    );

    setBusyLoader({ show: false });
    if (r.resp.downloadLink) {
      CommonService.downloadAsset(r.resp.downloadLink);
      Toaster.success("Downloaded successfully");
    } else {
      Toaster.error("Failed to download data");
    }
  };

  const onSearch = useCallback(
    debounce((e) => {
      const value = e.target.value;
      filterDataRef.current = {
        ...filterDataRef.current,
        search: value,
      };
      const d = decideDisplay();
      setDisplay(d);
      if (d === "search") {
        applySearch();
      }
    }, 300), // Adjust the debounce delay as needed
    [applySearch]
  );

  const handleFilter = useCallback(
    (key, value) => {
      if (key === "menuSearch") {
        setMenuSearch(value);
      }
      if (key === "categorySearch") {
        setCategorySearch(value);
      }
      if (key === "brandSearch") {
        setBrandSearch(value);
      }
      filterDataRef.current = {
        ...filterDataRef.current,
        [key]: value,
      };

      const d = decideDisplay();

      setDisplay(d);

      if (d === "search") {
        applySearch();
      }
    },
    [applySearch, decideDisplay]
  );

  const applySearch = useCallback(async () => {
    paginationRef.current = {
      ...defaultPagination,
    };

    loadSearchData();

    const params = prepareParams(filterDataRef.current, paginationRef.current);

    // Get count for pagination
    setTotalRecords({ loading: true, value: 0 });
    const count = await getCount(params, filterDataRef.current.sellerId);
    paginationRef.current.totalRecords = count;
    // Update totalRecords state
    setTotalRecords({ loading: false, value: count });
    paginationRef.current.totalRecords = count;
  }, [loadSearchData]);

  const loadSearchData = useCallback(async () => {
    setDisplay("search");

    const params = prepareParams(filterDataRef.current, paginationRef.current);

    setSearchData({ loading: true, data: [] });

    const r = await PosService.searchDealInRackBin(
      filterDataRef.current.sellerId,
      params
    );

    setSearchData({ loading: false, data: r.resp || [] });
  }, []);

  const paginationCb = (data) => {
    paginationRef.current = {
      ...defaultPagination,
      totalRecords: totalRecords.value,
      ...data,
    };
    loadSearchData();
  };

  const onMenuSearch = useCallback(
    async (value) => {
      setMenuSearch(value);
      handleFilter("menuSearch", value);
    },
    [handleFilter]
  );

  const onCategorySearch = useCallback(
    async (value) => {
      setCategorySearch(value);
      handleFilter("categorySearch", value);
    },
    [handleFilter]
  );

  const onBrandSearch = useCallback(
    async (value) => {
      setBrandSearch(value);
      handleFilter("brandSearch", value);
    },
    [handleFilter]
  );

  const onMenuClick = (menuId, menuName) => {
    const m = [{ value: menuId, label: menuName }];
    setMenuSearch(m);
    handleFilter("menuSearch", m);
  };

  const onCategoryClick = (categoryId, categoryName) => {
    const c = [{ value: categoryId, label: categoryName }];
    setCategorySearch(c);
    handleFilter("categorySearch", c);
  };

  const onBrandClick = (brandId, brandName) => {
    const b = [{ value: brandId, label: brandName }];
    setBrandSearch(b);
    handleFilter("brandSearch", b);
  };

  const onProductClick = (product) => {
    console.log(product);

    setBinItemsModal({ show: true, binInfo: product });
  };

  const handleStockLedgerModalCallback = (data) => {
    if (data.action === "close") {
      setStockLedgerModal({ show: false, pid: "" });
    }
  };

  const handleBinItemsModalCallback = (data) => {
    if (data.action === "viewLedger") {
      setStockLedgerModal({ show: true, pid: data.id });
      return;
    }

    if (data.action === "close") {
      setBinItemsModal({ show: false, binInfo: null });
    }
  };

  return (
    <>
      <PageInfo title="Rack Bin" breadcrumbs={breadcrumbs} />
      <StoreCard
        fid={storeId}
        navigate={navigate}
        rbacKey="DarkStoreInventoryDashboard"
      />
      <AppCard>
        <div className="row">
          <div className="col">
            <SelectInput
              label="Select Location"
              name="location"
              options={locations}
              callback={onLocationChange}
              register={register}
              gap={0}
            />
          </div>
          <div className="col">
            <TextInput
              label="Search"
              placeholder="Search..."
              callback={onSearch}
              name="search"
              register={register}
              gap={0}
            />
          </div>
          <div className="col">
            <PosRackBinSearchInp
              storeId={storeId}
              type="menu"
              callback={onMenuSearch}
              value={menuSearch}
            />
          </div>
          <div className="col">
            <PosRackBinSearchInp
              storeId={storeId}
              type="category"
              callback={onCategorySearch}
              value={categorySearch}
            />
          </div>
          <div className="col">
            <PosRackBinSearchInp
              storeId={storeId}
              type="brand"
              callback={onBrandSearch}
              value={brandSearch}
            />
          </div>
          <div className="col-auto align-self-end">
            <button
              onClick={handleDownload}
              className="btn btn-outline-primary"
            >
              <i className="bi bi-download"></i>
            </button>
          </div>
        </div>
      </AppCard>
      <AppCard>
        {display === "loading" && <PageLoader />}

        {display === "data" && (
          <div className="rack-bin-table">
            <div className="color-legend mt-3">
              <div className="d-flex">
                <div className="legend-item">
                  <div className="color-box stock-fill-1"></div>
                  <span>0% - 40%: Low Stock</span>
                </div>
                <div className="legend-item">
                  <div className="color-box stock-fill-2"></div>
                  <span>41% - 80%: Medium Stock</span>
                </div>
                <div className="legend-item">
                  <div className="color-box stock-fill-3"></div>
                  <span>81% - 100%: High Stock</span>
                </div>
              </div>
            </div>

            <div className="rack-container-scroll">
              <div className="rack-container">
                {data.map((rack, index) => (
                  <div key={index} className="rack-column">
                    <div className="w-100 mb-3 fs-val-md">
                      <div className="cell rack-lbl text-center">
                        {rack.aliasName || rack.name}
                      </div>
                      {rack.bins.map((bin, binIndex) => (
                        <div
                          key={binIndex}
                          className={`cell ${bin._cssClass}`}
                          title={`Rack - ${
                            rack.aliasName || rack.name
                          } | Bin - ${bin.name}`}
                        >
                          <div className="mb-2 mt-2">
                            <span className="text-muted">Bin:</span> {bin.name}
                          </div>
                          <div className="mb-2 mt-2">
                            <span className="text-muted">Stock:</span>{" "}
                            {bin._stock || 0}
                            <span className="unit-lbl"> units</span>
                          </div>
                          <div className="mb-2">
                            <button
                              className="btn btn-link p-0 fs-val-sm text-muted"
                              onClick={() =>
                                onProductClick({
                                  location: selectedLocation,
                                  locationName: locations.find(
                                    (x) => x.value === selectedLocation
                                  )?.label,
                                  rack: rack.aliasName || rack.name,
                                  bin: bin.name,
                                  code: bin.code,
                                })
                              }
                            >
                              View Products
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        {display === "search" && (
          <>
            <table className="table table-sm table-hover">
              <TableHeader data={tableHeader} noBg={true} />
              <tbody className="fs-val-md">
                {searchData.loading ? (
                  <TableSkeletonLoader cols={tableHeader.length} rows={10} />
                ) : null}

                {searchData.data.map((x, index) => (
                  <tr key={index}>
                    <td>
                      {index +
                        1 +
                        (paginationRef.current.activePage - 1) *
                          paginationRef.current.rowsPerPage}
                    </td>
                    <td>
                      <button
                        className="p-0 btn btn-link text-dark text-start fs-val-md"
                        onClick={() => onProductClick(x)}
                      >
                        {x.DealName}
                      </button>
                    </td>
                    <td>{x.DealId}</td>
                    <td>
                      <button
                        className="p-0 btn btn-link text-dark text-start fs-val-md"
                        onClick={() => onMenuClick(x.MenuID, x.MenuName)}
                      >
                        {x.MenuName}
                      </button>
                    </td>
                    <td>
                      <button
                        className="p-0 btn btn-link text-dark text-start fs-val-md"
                        onClick={() =>
                          onCategoryClick(x.DealCategoryId, x.DealCategory)
                        }
                      >
                        {x.DealCategory}
                      </button>
                    </td>
                    <td>
                      <button
                        className="p-0 btn btn-link text-dark text-start fs-val-md"
                        onClick={() => onBrandClick(x.DealBrandId, x.DealBrand)}
                      >
                        {x.DealBrand}
                      </button>
                    </td>
                    <td>
                      <Amount value={x.MRP} />
                    </td>
                    <td>
                      <HighlightText
                        type={x.Location == "Sellable" ? "success" : "danger"}
                        template={2}
                      >
                        {x.Location}
                      </HighlightText>
                    </td>
                    <td>
                      <HighlightText type="secondary" template={2}>
                        {x.aliasName || x.RackName}
                      </HighlightText>
                    </td>
                    <td>
                      <HighlightText type="warning" template={2}>
                        {x.BinName}
                      </HighlightText>
                    </td>
                    <td>{x.TotalQuantity}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            <PaginationBlock
              callback={paginationCb}
              loadingTotalRecords={totalRecords.loading}
              paginationCb={paginationCb}
              paginationConfig={paginationRef.current}
            />
          </>
        )}
      </AppCard>

      {/* Stock Ledger Modal */}
      <StockLedgerModal
        show={stockLedgerModal.show}
        callback={handleStockLedgerModalCallback}
        storeId={storeId}
        dealId={stockLedgerModal.pid}
      />

      {/* PosBinItemsModal */}
      <PosBinItemsModal
        show={binItemsModal.show}
        callback={handleBinItemsModalCallback}
        binInfo={binItemsModal.binInfo}
        sellerId={filterDataRef.current.sellerId}
        storeId={storeId}
      />

      <BusyLoader />
    </>
  );
};

export default PosRackBin;
