import { CommonService, NavService, PosService } from "@sk/services";
import {
  Amount,
  AppCard,
  BtnLink,
  BusyLoader,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
} from "@sk/uis";
import { endOfDay, startOfDay, startOfMonth } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import PosOrderFilter from "./PosOrderFilter";

const tableContainerStyle = {
  maxHeight: "calc(100vh - 300px)",
  overflowY: "auto",
};

const tableStyle = {
  minWidth: "2000px",
};

const PosOrders = ({ storeId, type, callback, dealId }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recordsCount, setRecordsCount] = useState({
    loading: false,
    count: 0,
  });
  const [busyLoader, setBusyLoader] = useState({ show: false });

  const tableRef = useRef(null);

  const filterDataRef = useRef({
    date: [startOfMonth(new Date()), new Date()],
  });
  const paginationRef = useRef({
    ...defaultPaginationConfig,
  });
  const sortRef = useRef({
    key: "createdAt",
    value: "desc",
  });

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    loadList();

    setRecordsCount({ loading: true, count: 0 });
    const r = await getCount(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      )
    );
    paginationRef.current.totalRecords = r.count;
    setRecordsCount({ loading: false, count: r.count });
  }, [loadList]);

  const loadList = useCallback(async () => {
    setLoading(true);
    setOrders([]);
    const r = await getData(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      )
    );
    setOrders(r.data);
    setLoading(false);
  }, []);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    filterDataRef.current.storeId = storeId;
    filterDataRef.current.type = type;
    filterDataRef.current.dealId = dealId;
    init();
  }, [init, storeId, type, dealId]);

  const onPaginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
    CommonService.scrollToView(tableRef.current, 0);
  };

  const onFilterCb = (data) => {
    filterDataRef.current = {
      ...filterDataRef.current,
      ...data.formData,
    };
    applyFilter();
  };

  const onDownload = async () => {
    const params = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current
    );
    params.reportType = "SFPOSOrderSummary";
    params.franchiseId = storeId;
    delete params.page;
    delete params.count;
    delete params.sort;
    delete params.filter["franchiseInfo.id"];

    setBusyLoader({ show: true });
    PosService.downloadOrdersReportData(params, (data) => {
      setBusyLoader({ show: false });
      if (data.status == "error") {
        Toaster.error(data.err);
      } else {
        Toaster.success("Report downloaded successfully");
      }
    });
  };

  const onSortCb = (sort) => {
    sortRef.current = sort;
    applyFilter();
  };

  return (
    <>
      <AppCard>
        <div className="row mb-3">
          <div className="col">
            <PosOrderFilter
              callback={onFilterCb}
              showStatus={type === "club"}
            />
          </div>
          {/* <div className="col-auto align-self-end">
            <button
              className="btn btn-sm btn-outline-secondary"
              onClick={onDownload}
            >
              <i className="bi bi-file-earmark-spreadsheet me-1" />
              Download
            </button>
          </div> */}
        </div>

        <PaginationSummary
          loadingTotalRecords={recordsCount.loading}
          paginationConfig={paginationRef.current}
          className="mb-3"
        />

        <div ref={tableRef}></div>

        <div
          className="tbl-scroll-container custom-scrollbar thin-scrollbar fixed-width-table"
          style={tableContainerStyle}
        >
          <table className="table table-sm table-striped" style={tableStyle}>
            <TableHeader
              data={headerData}
              noBg
              sortCb={onSortCb}
              sort={sortRef.current}
              isSticky={true}
            />

            <tbody className="fs-val-md">
              {loading ? (
                <TableSkeletonLoader cols={headerData.length} rows={5} />
              ) : null}

              {!loading && !orders.length ? (
                <tr>
                  <td colSpan={headerData.length}>
                    <NoDataFound>No orders found</NoDataFound>
                  </td>
                </tr>
              ) : null}

              {orders.map((order, index) => (
                <tr
                  key={order._id}
                  className="cursor-pointer"
                  onClick={() =>
                    // callback({ action: "viewOrder", id: order._id })
                    NavService.openInNewTab("/pos/order/view", {
                      id: order._id,
                      storeId: storeId,
                    })
                  }
                >
                  <td>{paginationRef.current.startSlNo + index}</td>
                  <td>
                    <BtnLink className="fs-val-md">{order._id}</BtnLink>
                  </td>
                  <td>
                    {order.customerInfo?.isGuestCustomer ? (
                      <div className="text-danger fs-val-sm">
                        WITHOUT CUSTOMER INFO
                      </div>
                    ) : (
                      <div>
                        <BtnLink
                          className="fs-val-md"
                          onClick={(e) => {
                            e.stopPropagation();
                            NavService.openInNewTab("/users/customer/view", {
                              id: order.customerInfo.id,
                            });
                          }}
                        >
                          {order.customerInfo?.name}
                        </BtnLink>
                      </div>
                    )}
                  </td>
                  <td>{order.customerInfo?.district}</td>
                  <td>{order.customerInfo?.state}</td>
                  <td>{order.customerInfo?.pincode}</td>
                  <td>
                    <DateFormatter date={order.createdAt} />
                  </td>
                  <td>
                    <Amount value={order._payableAmt} />
                  </td>
                  <td>{order.orderType}</td>
                  <td>{order.deals.length}</td>
                  <td>{order.amountReceivedVia}</td>
                  <td>{order.couponCode || "--"}</td>
                  <td className="text-success">
                    +{order.loyaltyPoints?.customerPoints || 0}
                  </td>
                  <td className="text-danger">
                    <Amount
                      value={order.loyaltyPoints?.customerRedeemedValues || 0}
                    />
                  </td>

                  <td>
                    <HighlightText type={order.statusType} template={2}>
                      {order.displayStatus}
                    </HighlightText>
                  </td>
                  <td>
                    <button className="btn btn-sm btn-outline-secondary fs-val-sm">
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="d-flex justify-content-end mt-3">
          <PaginationBlock
            loadingTotalRecords={recordsCount.loading}
            paginationCb={onPaginationCb}
            size="sm"
            paginationConfig={paginationRef.current}
          />
        </div>
      </AppCard>

      <BusyLoader show={busyLoader.show} />
    </>
  );
};

const headerData = [
  { key: "_slNo", label: "#", width: "30px" },
  {
    key: "_id",
    label: "Order ID",
    width: "100px",
    enableSort: true,
  },
  {
    key: "customerInfo.name",
    label: "Customer",
    width: "180px",
    enableSort: true,
  },
  { key: "customerInfo.district", label: "District", width: "100px" },
  { key: "customerInfo.state", label: "State", width: "100px" },
  { key: "customerInfo.pincode", label: "Pincode", width: "60px" },
  { key: "createdAt", label: "Ordered on", width: "140px", enableSort: true },
  {
    key: "orderSummary.amount",
    label: "Amount",
    width: "80px",
    enableSort: true,
  },
  {
    key: "orderType",
    label: "Order Type",
    width: "100px",
  },
  { key: "totalDeals", label: "Items", width: "50px" },
  {
    key: "amountReceivedVia",
    label: "Payment Mode",
    width: "100px",
    enableSort: true,
  },
  { key: "coupon", label: "Coupon", width: "100px" },
  {
    key: "loyaltyPoints.customerPoints",
    label: "Coins Rewarded",
    width: "100px",
    enableSort: true,
  },
  {
    key: "loyaltyPoints.customerRedeemedValues",
    label: "Coins Redeemed",
    width: "100px",
    enableSort: true,
  },
  { key: "status", label: "Status", width: "80px" },
  { key: "action", label: "Action", width: "100px" },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const getData = async (params) => {
  const r = await PosService.getOrders(params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const getCount = async (params) => {
  let p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;

  const r = await PosService.getOrders(p);
  return { count: r.resp };
};

const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {
      "franchiseInfo.id": filter.storeId,
    },
    // sort: { [sort?.key]: sort?.value == "asc" ? 1 : -1 },
    sort: sort.value == "asc" ? sort.key : "-" + sort.key,
  };

  if (filter.type != "all") {
    if (filter.type === "club") {
      p.filter.requestedId = { $exists: true };
    } else {
      p.filter.requestedId = { $exists: false };
    }
  }

  if (filter.dealId) {
    p.filter["deals.id"] = filter.dealId;
  }

  if (filter.search) {
    const search = filter.search.trim();
    const searchRegex = {
      $regex: search,
      $options: "gi",
    };

    if (search) {
      p.filter.$or = [
        {
          _id: search,
        },
        {
          "customerInfo.name": searchRegex,
        },
        {
          "customerInfo.mobile": searchRegex,
        },
        {
          referenceId: searchRegex,
        },
        {
          "manpowerFranchiseInfo.name": searchRegex,
        },
        {
          couponCode: searchRegex,
        },
        {
          requestedId: search,
        },
        {
          "deals.id": search,
        },
      ];
    }
  }

  if (filter.date?.length) {
    p.filter.createdAt = {
      $gte: startOfDay(filter.date[0]),
      $lte: endOfDay(filter.date[1]),
    };
  }

  if (filter.status) {
    const statusGroup = PosService.getPosOrderStatusGroups().find(
      (e) => e.key === filter.status
    )?.statusGroup;

    if (statusGroup) {
      p.filter.status = {
        $in: statusGroup,
      };
    }
  }

  return p;
};

export default PosOrders;
