import { Amount, DisplayUnit, ImgRender, HighlightText } from "@sk/uis";

const ClubReqItem = ({ item }) => {
  return (
    <div className="row" key={item._id}>
      <div className="col-auto">
        <div className="border rounded p-1" style={{ width: "80px" }}>
          <ImgRender assetId={item.images?.[0]} />
        </div>
      </div>
      <div className="col">
        <div className="fs-val-md mb-2 fw-semibold">
          {item.name} -{" "}
          <span className="text-black-50 fs-val-sm">({item.dealId})</span>
        </div>
        <div className="row fs-val-sm mb-2">
          <div className="col-auto">
            <div className="d-flex align-items-center">
              <span className="me-1 text-black-50">Selling Price:</span>
              {item.isFree ? (
                <span className="text-danger fw-bold">FREE</span>
              ) : (
                <span className="text-primary fw-semibold">
                  <Amount value={item.price} decimalPlace={2} />
                </span>
              )}
            </div>
          </div>
          <div className="col-auto">
            <div className="d-flex align-items-center">
              <span className="me-1 text-black-50">MRP:</span>
              <span className="fw-semibold">
                <Amount value={item.mrp} decimalPlace={2} />
              </span>
            </div>
          </div>
          <div className="col-auto">
            <HighlightText
              template={2}
              type={item._statusType}
              className="ms-2"
              size="xs"
            >
              {item._displayStatus}
            </HighlightText>
          </div>
        </div>
        <div className="row fs-val-sm mb-2">
          <div className="col-auto">
            <div className="d-flex align-items-center">
              <span className="me-1 text-black-50">Quantity:</span>
              <span className="fw-semibold">
                {item.quantity} {item.unitType}
              </span>
            </div>
          </div>
          {item.status === "Pending" && (
            <>
              <div className="col-auto">
                <div className="d-flex align-items-center">
                  <span className="me-1 text-black-50">Available Stock:</span>
                  <span className="text-secondary fw-semibold">
                    <DisplayUnit
                      isLoose={item.sellInLooseQty}
                      value={item.stockQty}
                    />
                  </span>
                </div>
              </div>
              <div className="col-auto">
                <div className="d-flex align-items-center">
                  <span className="me-1 text-black-50">Blocked Qty:</span>
                  <span className="text-danger fw-semibold">
                    <DisplayUnit
                      isLoose={item.sellInLooseQty}
                      value={item.blockedQty}
                    />
                  </span>
                </div>
              </div>
            </>
          )}

          {item.showProcessedQty && (
            <div className="col-auto">
              <div className="d-flex align-items-center">
                <span className="me-1 text-black-50">Processed Qty:</span>
                <span className="fw-semibold">
                  <DisplayUnit
                    isLoose={item.sellInLooseQty}
                    value={item.processedQty}
                  />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ClubReqItem;
