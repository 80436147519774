import { Tabs } from "@sk/uis";
import { useEffect } from "react";

const tabData = [
  {
    tabName: "All",
    key: "all",
    description:
      "List of all inventory items purchased from Storeking and other Vendors",
  },
  {
    tabName: "Top Moving",
    key: "topMoving",
    description:
      "List of products that are sold most frequently in the last 90 days",
  },
  {
    tabName: "Slow Moving",
    key: "slowMoving",
    description: "List of products that have not been sold in 15 days",
  },
  {
    tabName: "Non Moving",
    key: "nonMoving",
    description: "List of products that have not been sold in 30 days",
  },
  // { tabName: "Top Purchases", key: "topPurchases" },
];

const PosStoreTabs = ({ activeTab, setActiveTab }) => {
  const tabCb = (e) => {
    setActiveTab(e.value);
  };

  useEffect(() => {
    setActiveTab(tabData[0]);
  }, [setActiveTab]);

  return (
    <>
      <Tabs
        data={tabData}
        activeTab={activeTab}
        template={4}
        callback={tabCb}
      />
    </>
  );
};

export default PosStoreTabs;
