import { AutoCompleteInput } from "@sk/uis";
import React from "react";
import { PosService } from "@sk/services";

const PosRackBinSearchInp = ({ type, storeId, callback, value }) => {
  const handleSearch = async (value, callback) => {
    let data = [];
    try {
      if (type === "menu") {
        data = await fetchMenuData(value);
      } else if (type === "category") {
        data = await fetchCategoryData(value);
      } else if (type === "brand") {
        data = await fetchBrandData(value);
      }
      callback(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchMenuData = async (value) => {
    const response = await PosService.getMenu({
      menuFilter: { menuName: { $regex: value, $options: "i" } },
      filter: { _id: storeId },
    });
    return response.resp.map((menu) => ({
      label: menu.name,
      value: menu._id,
    }));
  };

  const fetchCategoryData = async (value) => {
    const response = await PosService.getCategory({
      categoryFilter: { categoryName: { $regex: value, $options: "i" } },
      filter: { _id: storeId },
    });
    return response.resp.map((category) => ({
      label: category.name,
      value: category._id,
    }));
  };

  const fetchBrandData = async (value) => {
    const response = await PosService.getBrand({
      brandFilter: { brandName: { $regex: value, $options: "i" } },
      filter: { _id: storeId },
    });
    return response.resp.map((brand) => ({
      label: brand.name,
      value: brand._id,
    }));
  };

  return (
    <div>
      <AutoCompleteInput
        label={`Search ${type.charAt(0).toUpperCase() + type.slice(1)}`}
        onSearch={handleSearch}
        placeholder={`Search for ${type}`}
        gap={0}
        uid={`pos-rack-bin-${type}-search-input`}
        callback={callback}
        value={value}
      />
    </div>
  );
};

export default PosRackBinSearchInp;
