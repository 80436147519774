import { CustomerService, PosService } from "@sk/services";
import { AppCard, PageLoader, Tabs } from "@sk/uis";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import ClubReqBasic from "./components/ClubReqBasic";
import ClubReqDeliveryDetail from "./components/ClubReqDeliveryDetail";
import ClubReqItem from "./components/ClubReqItem";
import ClubReqStore from "./components/ClubReqStore";
import ClubReqTimeline from "./components/ClubReqTimeline";

const canvasStyle = { width: "70%" }; // Set the width as in ClubViewModal

const tabs = [
  {
    tabName: "Items",
    key: "items",
  },
  {
    tabName: "Timeline",
    key: "timeline",
  },
];

const ClubRequestDetailModal = ({ show, callback, requestId }) => {
  const [data, setData] = useState({
    order: null,
    invoice: null,
    customerRequest: null,
  });
  const [loading, setLoading] = useState(false);
  const [tabData, setTabData] = useState([...tabs]);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    if (requestId && show) {
      loadDetail();
      setActiveTab(tabs[0]);
    }
  }, [loadDetail, requestId, show]);

  const loadDetail = useCallback(async () => {
    let customerRequest = {};
    let order = {};
    let invoice = {};

    setLoading(true);
    const response = await CustomerService.getClubRequests({
      filter: {
        _id: requestId,
      },
    });

    customerRequest =
      Array.isArray(response.resp) && response.resp.length > 0
        ? CustomerService.formatCustomerServiceRequests(response.resp)[0]
        : {};

    if (
      customerRequest.posOrderIdList &&
      customerRequest.posOrderIdList.length > 0
    ) {
      const orderResponse = await PosService.getOrders({
        filter: {
          _id: { $in: customerRequest.posOrderIdList },
        },
      });

      order = orderResponse.resp[0] || {};

      // Check if the order has an invoiceId
      if (order.invoiceId) {
        const invoiceResponse = await PosService.getInvoices({
          filter: {
            _id: order.invoiceId,
          },
        });

        invoice = invoiceResponse.resp[0] || {};
      }
    }

    // Check if the request status is "Created" before fetching deal IDs and stock details
    if (
      customerRequest.status === "Created" ||
      customerRequest.status === "Approved"
    ) {
      // Collect all deal IDs from customerRequest
      const dealIds = customerRequest.listingPayload.map((item) => item.dealId);

      // Fetch stock details for the collected deal IDs
      const stockResponse = await PosService.getDealsStock({
        page: 1,
        count: dealIds.length,
        filter: {
          _id: customerRequest.franchiseInfo?.id,
        },
        dealFilter: {
          _id: { $in: dealIds },
        },
      });

      const stockData = stockResponse.resp || [];

      // Attach quantity to the relevant deal in listingPayload
      const updatedListingPayload = customerRequest.listingPayload.map(
        (item) => {
          const stockItem = stockData.find(
            (stock) => stock._id === item.dealId
          );

          // Initialize processedQty
          let processedQty = 0;

          // If order data is present, find the matching deal and get the qty
          if (order.deals) {
            const matchingDeal = order.deals.find(
              (deal) => deal._id === item.dealId
            );
            processedQty = matchingDeal ? matchingDeal.qty : 0;
          }

          return {
            ...item,
            stockQty: stockItem ? stockItem.qty : 0,
            blockedQty: stockItem ? stockItem.blockedQty : 0,
            processedQty,
            showProcessedQty: order?._id ? true : false,
          };
        }
      );

      // Update customerRequest with the modified listingPayload
      customerRequest.listingPayload = updatedListingPayload;
    }

    setData({
      customerRequest,
      order,
      invoice,
      basic: {
        pickerInfo: customerRequest.pickerInfo,
        customerInfo: {
          id: customerRequest.customerInfo?.id,
          name: customerRequest.customerInfo?.name,
          isGuestCustomer: customerRequest.customerInfo?.isGuestCustomer,
        },
        statusType: customerRequest._bgCss,
        displayStatus: customerRequest._statusLbl,
        _id: customerRequest._id,
        shippingCharges: customerRequest.shippingCharges,
        _payableAmt: customerRequest._displayFinalAmt,
        createdAt: customerRequest.createdAt,
        amountReceivedVia: customerRequest.amountReceivedVia,
        orderId: order._id,
        rejectedReason: customerRequest.rejectedReason,
      },
      deliveryDetail: {
        deliveryDistance: customerRequest.deliveryDistance || "N/A",
        franchiseInfo: customerRequest.franchiseInfo,
        deliveryTimeSlot: customerRequest.deliveryTimeSlot,
        shippingAddress: customerRequest.shippingAddress,
        lat: customerRequest.shippingAddress?.geolocation?.coordinates[0],
        lng: customerRequest.shippingAddress?.geolocation?.coordinates[1],
      },
    });

    setTabData(
      produce((draft) => {
        draft[0].tabName = `${customerRequest.listingPayload.length} Item${
          customerRequest.listingPayload.length > 1 ? "s" : ""
        }`;
      })
    );

    setLoading(false);
  }, [requestId]);

  const onHide = () => {
    callback({ action: "close" });
  };

  const handleCb = (data) => {
    callback(data);
  };

  const onTabChange = ({ value }) => {
    setActiveTab(value);
  };

  return (
    <Offcanvas show={show} onHide={onHide} placement="end" style={canvasStyle}>
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>Request Details - #{requestId}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        {loading ? <PageLoader /> : null}

        {!loading && data.customerRequest?._id ? (
          <div className="row">
            <div className="col-6">
              <div className="position-sticky top-0">
                <AppCard title="Basic Details">
                  <ClubReqBasic callback={handleCb} order={data.basic} />
                </AppCard>
                <AppCard title="Store Information">
                  <ClubReqStore
                    storeInfo={data.customerRequest.franchiseInfo}
                  />
                </AppCard>
                <AppCard title="Delivery Details">
                  <ClubReqDeliveryDetail order={data.deliveryDetail} />
                </AppCard>
              </div>
            </div>
            <div className="col-6">
              {data.customerRequest.isPartnerOrder ? (
                <div className="mb-2">
                  <Tabs
                    data={tabData}
                    activeTab={activeTab}
                    callback={onTabChange}
                    template={3}
                  />
                </div>
              ) : null}

              {activeTab.key === "items" ? (
                <>
                  <div className="fs-val-lg">
                    Items ({data.customerRequest.listingPayload.length})
                  </div>
                  {data.customerRequest.listingPayload.map((item) => (
                    <div
                      key={item._id}
                      className="mb-2 bg-white border rounded p-2"
                    >
                      <ClubReqItem item={item} />

                      {item.offerDetails?.deals?.length > 0 && (
                        <div>
                          <div className="mt-2 fs-val-sm">Offers:</div>
                          {item.offerDetails.deals.map((offer) => (
                            <ClubReqItem
                              item={offer}
                              key={`${item._id}-${offer.id}`}
                            />
                          ))}
                        </div>
                      )}

                      {item.comboOfferDetails?.deals?.length > 0 && (
                        <div>
                          <div className="mt-2 fs-val-sm">Combo Offers:</div>
                          {item.comboOfferDetails.deals.map((comboOffer) => (
                            <ClubReqItem
                              item={comboOffer}
                              key={`${item._id}-${comboOffer.id}`}
                            />
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </>
              ) : null}

              {activeTab.key === "timeline" ? (
                <AppCard>
                  <ClubReqTimeline
                    timelineData={data.customerRequest._timeline}
                  />
                </AppCard>
              ) : null}
            </div>
          </div>
        ) : null}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default ClubRequestDetailModal;
