import { appConfigs, CommonService, FranchiseService } from "@sk/services";
import { AppCard, BusyLoader, DateInput, Toaster } from "@sk/uis";
import { endOfDay, set, startOfDay, sub } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";

const reportTypes = [
  {
    title: "Purchase Reports",
    children: [
      {
        title: "GST Filing - Purchase",
        description: "Download your GST Filing for the purchase",
        dataKey: "SFGSTPURCHASEFILINGREPORT",
      },
      {
        title: "GST Filing - Purchase Returns",
        description: "Download your Purchase Returns Report",
        dataKey: "PURCHASERETURNSREPORT",
      },
      {
        title: "Store Intake",
        description: "Download the Store Intake report",
        dataKey: "STOREINTAKE",
      },
    ],
  },
  {
    title: "Sales Reports",
    children: [
      {
        title: "GST Filing - POS Sales",
        description: "Download your GST filing for POS Sales",
        dataKey: "SFGSTPOSSALESFILINGREPORT",
      },
      {
        title: "GST Filing - Retailer Sales (B2B)",
        description: "Download your Retailer Sales GST Filing",
        dataKey: "SFTORFGSTFILINGREPORT",
        isGstRequired: true,
      },
    ],
  },
  {
    title: "Returns Reports",
    children: [
      {
        title: "GST Filing - POS Returns",
        description: "Download POS Returns report",
        dataKey: "POSRETURNSREPORT",
      },
    ],
  },
];

const PosReport = ({ storeId }) => {
  const { control, getValues } = useForm({
    defaultValues: {
      date: [
        startOfDay(
          sub(new Date(), {
            days: 30,
          })
        ),
        new Date(),
      ],
    },
  });

  const [busyloader, setBusyloader] = useState({ show: false });

  const filterDataRef = useRef({});

  useEffect(() => {
    filterDataRef.current = {
      storeId,
    };
  }, [storeId]);

  const handleDownload = async (dataKey, isGstRequired = false) => {
    const formData = getValues();

    const params = {
      franchiseId: storeId,
      filter: {
        createdAt: {
          $gte: startOfDay(
            set(formData.date[0], {
              hours: 0,
              minutes: 0,
              seconds: 0,
            })
          ),
          $lte: endOfDay(
            set(formData.date[1], {
              hours: 23,
              minutes: 59,
              seconds: 59,
            })
          ),
        },
      },
      reportType: dataKey,
    };

    setBusyloader({ show: true });

    let gstNo = "";
    let sellerId = "";

    const franchiseResp = await FranchiseService.getFranchise(storeId, {
      select: "finance_details,sk_franchise_details",
    });

    if (franchiseResp.resp?.finance_details?.gstNo) {
      gstNo = franchiseResp.resp.finance_details.gstNo;
    }

    if (franchiseResp.resp?.sellerId) {
      sellerId = franchiseResp.resp.sellerId;
    }

    params.sellerId = sellerId;

    if (isGstRequired) {
      if (!gstNo) {
        Toaster.error("GST No is not found");
        setBusyloader({ show: false });
        return;
      }
      params.filter.gstNo = gstNo;
    }

    const res = await CommonService.storeReportDownload(params);
    setBusyloader({ show: false });

    if (res.resp?.filePath) {
      window.open(appConfigs.ASSET + "/" + res.resp.filePath, "_blank");
      Toaster.success("Report Downloaded Successfully");
    } else {
      Toaster.error(res.resp.message || "Something went wrong");
    }
  };

  const handleStoreIntakeDownload = async () => {
    const formData = getValues();

    const params = {
      franchiseId: storeId,
      filter: {
        createdAt: {
          $gte: startOfDay(
            set(formData.date[0], {
              hours: 0,
              minutes: 0,
              seconds: 0,
            })
          ),
          $lte: endOfDay(
            set(formData.date[1], {
              hours: 23,
              minutes: 59,
              seconds: 59,
            })
          ),
        },
      },
      reportType: "STOREINTAKE",
    };

    setBusyloader({ show: true });

    let sellerId = "";

    const franchiseResp = await FranchiseService.getFranchise(storeId, {
      select: "finance_details,sk_franchise_details",
    });

    if (franchiseResp.resp?.sellerId) {
      sellerId = franchiseResp.resp.sellerId;
    }

    params.filter["sellerInfo._id"] = sellerId;

    const res = await FranchiseService.getStockIntake(params);

    setBusyloader({ show: false });

    if (res.resp?.downloadLink) {
      window.open(appConfigs.ASSET + "/" + res.resp.downloadLink, "_blank");
      Toaster.success("Store Intake Report Downloaded Successfully");
    } else {
      Toaster.error(res.resp.message || "Something went wrong");
    }
  };

  const handleButtonClick = (dataKey, isGstRequired) => {
    if (dataKey === "STOREINTAKE") {
      handleStoreIntakeDownload();
    } else {
      handleDownload(dataKey, isGstRequired);
    }
  };

  return (
    <>
      <AppCard>
        <div className="row">
          <div className="col-3">
            <Controller
              control={control}
              name="date"
              render={({ field }) => (
                <DateInput
                  label="Date Range"
                  callback={field.onChange}
                  value={field.value}
                  gap={0}
                  template={2}
                  hideClose={true}
                />
              )}
            />
          </div>
        </div>
      </AppCard>

      {reportTypes.map((reportType, index) => (
        <div key={index} className="mb-3">
          <div className="fs-val-lg fw-semibold mb-1">{reportType.title}</div>
          <div className="row">
            {reportType.children.map((child, index) => (
              <div className="col-md-4" key={index}>
                <AppCard>
                  <div className="row align-items-center">
                    <div className="col-auto">
                      <i className="bi bi-file-earmark-text"></i>
                    </div>
                    <div className="col">
                      <div className="fs-val-md fw-semibold">{child.title}</div>
                      <div className="fs-val-sm text-muted">
                        {child.description}
                      </div>
                    </div>
                    <div className="col-auto">
                      <button
                        className="btn btn-link p-0 text-dark"
                        onClick={() =>
                          handleButtonClick(child.dataKey, child.isGstRequired)
                        }
                      >
                        <i className="bi bi-download"></i>
                      </button>
                    </div>
                  </div>
                </AppCard>
              </div>
            ))}
          </div>
        </div>
      ))}

      <BusyLoader show={busyloader.show} />
    </>
  );
};

export default PosReport;
