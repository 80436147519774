import {
  Amount,
  DateFormatter,
  KeyVal,
  HighlightText,
  InfoPopover,
} from "@sk/uis";

const ClubReqBasic = ({ order, callback }) => {
  return (
    <>
      {order ? (
        <>
          {!order.customerInfo.isGuestCustomer ? (
            <>
              <KeyVal label="Customer Name" labelCol="col-5" contentCol="col-7">
                :{" "}
                <button
                  className="btn btn-link p-0 fs-val-md text-dark"
                  onClick={() =>
                    callback({
                      action: "viewCustomer",
                      id: order.customerInfo?.id,
                    })
                  }
                >
                  {order.customerInfo?.name}
                </button>
              </KeyVal>
              <KeyVal label="Customer ID" labelCol="col-5" contentCol="col-7">
                : {order.customerInfo?.id}
              </KeyVal>
            </>
          ) : null}

          <KeyVal label="Status" labelCol="col-5" contentCol="col-7">
            :{" "}
            <HighlightText type={order.statusType} template={2}>
              {order.displayStatus}
            </HighlightText>
            {order.displayStatus === "Rejected" && order.rejectedReason && (
              <InfoPopover
                header="Rejected Remarks"
                content={order.rejectedReason}
                placement="top"
                size="sm"
              />
            )}
          </KeyVal>

          <KeyVal label="Request ID" labelCol="col-5" contentCol="col-7">
            : {order._id}
          </KeyVal>

          <KeyVal label="Requested On" labelCol="col-5" contentCol="col-7">
            : <DateFormatter date={order.createdAt} />
          </KeyVal>

          <KeyVal label="Picker Name" labelCol="col-5" contentCol="col-7">
            : {order.pickerInfo?.name || "N/A"} - {order.pickerInfo?.id || ""}
          </KeyVal>

          {order.orderId && (
            <KeyVal label="Order ID" labelCol="col-5" contentCol="col-7">
              :{" "}
              <button
                className="btn btn-link p-0 fs-val-md text-dark text-start"
                onClick={() =>
                  callback({ action: "viewOrder", id: order.orderId })
                }
              >
                {order.orderId}
              </button>
            </KeyVal>
          )}

          {order.shippingCharges > 0 && (
            <KeyVal label="Shipping Charge" labelCol="col-5" contentCol="col-7">
              : <Amount value={order.shippingCharges} decimalPlace={2} />
            </KeyVal>
          )}

          <KeyVal label="Total Amount" labelCol="col-5" contentCol="col-7">
            :{" "}
            <span className="text-primary fw-semibold">
              <Amount value={order._payableAmt} />
            </span>
          </KeyVal>
        </>
      ) : (
        <div>No order details available</div>
      )}
    </>
  );
};

export default ClubReqBasic;
