import { Tabs } from "@sk/uis";
import { useState } from "react";
import PosCashCollect from "./components/cash-collect/PosCashCollect";
import PosCashDeposits from "./components/cash-deposits/PosCashDeposits";
import PosEmployeeSettlement from "./components/employee-settlement/PosEmployeeSettlement";

const tabs = [
  {
    key: "cashCollected",
    tabName: "Cash/UPI Collect",
    description:
      "Amount collected or remaining to be collected from delivery persons",
  },
  {
    key: "cashDeposited",
    tabName: "Sales Amount Bank Deposit",
    description:
      "Track and manage sales amounts deposited to the bank or awaiting deposit by the store owner.",
  },
  // {
  //   key: "earningSettlement",
  //   tabName: "Earning Settlement",
  //   description: "Earning settlement from delivery persons",
  // },
];

const PosCashManagement = ({ storeId }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const onTabChange = ({ value }) => {
    setActiveTab(value);
  };

  return (
    <>
      <div className="mb-2">
        <Tabs
          data={tabs}
          activeTab={activeTab}
          callback={onTabChange}
          template={3}
        />
      </div>

      <div className="my-3 fs-val-md text-muted">{activeTab.description}</div>

      {activeTab.key === "cashCollected" && (
        <PosCashCollect storeId={storeId} />
      )}
      {activeTab.key === "cashDeposited" && (
        <PosCashDeposits storeId={storeId} />
      )}
      {activeTab.key === "earningSettlement" && (
        <PosEmployeeSettlement storeId={storeId} />
      )}
    </>
  );
};

export default PosCashManagement;
