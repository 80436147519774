import { PosService } from "@sk/services";
import { AutoCompleteInput } from "@sk/uis"; // Updated import path
import { useCallback } from "react";

const PosBrandInput = ({ storeId, callback, value }) => {
  // Added value prop
  const fetchBrandData = useCallback(
    async (search) => {
      try {
        const response = await PosService.getBrand({
          filter: { _id: storeId },
          brandFilter: { name: { $regex: search, $options: "i" } },
        });
        const options = response.resp.map((brand) => ({
          label: brand.name,
          value: brand._id,
        }));
        return options; // Return options for immediate use
      } catch (error) {
        console.error("Error fetching brand data:", error);
        return []; // Return empty array in case of error
      }
    },
    [storeId]
  );

  const onSearch = useCallback(
    async (val, callback) => {
      const options = await fetchBrandData(val);
      callback(options);
    },
    [fetchBrandData]
  );

  const handleBrandSelect = (selected) => {
    callback({ selected });
  };

  return (
    <>
      <AutoCompleteInput
        label="Select Brand"
        onSearch={onSearch}
        callback={handleBrandSelect}
        name="brand"
        size="sm"
        emptyLabel="No brands found"
        value={value} // Updated to use value prop
        uid="brand-input"
        gap={0}
      />
    </>
  );
};

export default PosBrandInput;
