import { Offcanvas } from "react-bootstrap";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  NoDataFound,
  Pagination,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
  AppCard,
  Amount,
  DisplayUnit,
  DateFormatter,
  SummaryCard,
} from "@sk/uis";
import { debounce } from "lodash";
import { PosService } from "@sk/services";

const canvasStyle = {
  width: "70%",
};

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const defaultFormData = {
  search: "",
};

const headers = [
  { label: "Sl No", width: "5%", key: null },
  { label: "ID", width: "10%", key: "_id" },
  { label: "Name", width: "45%", key: "productName", enableSort: false },
  { label: "MRP", width: "8%", key: "mrp", enableSort: true },
  { label: "Stock", width: "10%", key: "stock", enableSort: true },
  { label: "Last Intake", width: "15%", key: "lastIntake" },
  { label: "Ledger", width: "10%", key: null },
];

const getData = async (binId, params, storeId) => {
  const binItemsResponse = await PosService.getBinItems(binId, params);
  const dealIds = binItemsResponse.resp.map((item) => item.dealId);

  const dealStockResponse = await PosService.getDealsStock({
    page: 1,
    count: dealIds.length,
    dealFilter: {
      _id: { $in: dealIds },
    },
    filter: {
      _id: storeId,
    },
  });

  const enrichedData = binItemsResponse.resp.map((item) => {
    const stockInfo =
      (dealStockResponse.resp || []).find(
        (stock) => stock._id === item.dealId
      ) || {};

    return {
      ...item,
      name: stockInfo.name || item.name,
      sellInLooseQty: stockInfo.sellInLooseQty || 0,
    };
  });

  return { data: enrichedData };
};

const getCount = async (binId, params) => {
  let p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;

  const r = await PosService.getBinItems(binId, p);
  return { count: r.resp?.[0]?.total || 0 };
};

const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {
      "sellerInfo._id": filter.sellerId,
      quantity: { $gt: 0 },
    },
    sort: {
      [sort.key]: sort.value === "asc" ? 1 : -1,
    },
  };

  const s = filter.search?.trim();
  if (s) {
    p.filter.$or = [
      { dealId: s },
      { productName: { $regex: s, $options: "gi" } },
    ];
  }

  return p;
};

const PosBinItemsModal = ({ show, callback, binInfo, sellerId, storeId }) => {
  const { register, getValues } = useForm({
    defaultValues: defaultFormData,
  });

  const [data, setData] = useState([]);
  const [records, setRecords] = useState({ loading: true, value: 0 });
  const [loadingData, setLoadingData] = useState(true);

  const filterDataRef = useRef({});
  const paginationRef = useRef({ ...defaultPaginationConfig });
  const sortDataRef = useRef({ key: "createdAt", value: "desc" });

  const handleClose = () => {
    callback({ action: "close" });
  };

  const loadList = useCallback(async () => {
    setLoadingData(true);
    setData([]);

    const enrichedData = await getData(
      filterDataRef.current.binId,
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortDataRef.current
      ),
      filterDataRef.current.storeId
    );

    setData(enrichedData.data || []);
    setLoadingData(false);
  }, []);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };
    filterDataRef.current = {
      ...filterDataRef.current,
      ...getValues(),
    };

    loadList();

    setRecords({ loading: true, value: 0 });
    const r = await getCount(
      filterDataRef.current.binId,
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortDataRef.current
      )
    );
    setRecords({ loading: false, value: r.count });
    paginationRef.current.totalRecords = r.count;
  }, [getValues, loadList]);

  useEffect(() => {
    if (show) {
      filterDataRef.current = {
        binId: binInfo?.code,
        sellerId,
        storeId,
      };
      applyFilter();
    }
  }, [show, applyFilter, binInfo?.code, sellerId, storeId]);

  const onSearch = useCallback(
    debounce(() => {
      applyFilter();
    }, 800),
    [applyFilter]
  );

  const paginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
  };

  const sortCb = (data) => {
    sortDataRef.current = data;
    applyFilter();
  };

  const viewLedger = (id) => {
    callback({ action: "viewLedger", id });
  };

  return (
    <Offcanvas
      show={show}
      onHide={handleClose}
      placement="end"
      style={canvasStyle}
    >
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>Bin Items</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        <div className="mb-1">
          <div className="row g-2 fw-bold">
            <div className="col">
              <AppCard>
                <div className="d-flex align-items-center justify-content-between fs-val-md">
                  <i
                    className="bi bi-geo-alt me-2 flex-shrink-0 text-primary fs-val-lg rounded-circle bg-primary bg-opacity-10 d-flex align-items-center justify-content-center"
                    style={{ width: "30px", height: "30px" }}
                  ></i>
                  <div className="flex-grow-1">Location</div>
                  <div className="text-primary">
                    {binInfo?.locationName || "-"}
                  </div>
                </div>
              </AppCard>
            </div>
            <div className="col">
              <AppCard>
                <div className="d-flex align-items-center justify-content-between fs-val-md">
                  <i
                    className="bi bi-grid me-2 flex-shrink-0 text-primary fs-val-lg rounded-circle bg-primary bg-opacity-10 d-flex align-items-center justify-content-center"
                    style={{ width: "30px", height: "30px" }}
                  ></i>
                  <div className="flex-grow-1">Rack</div>
                  <div className="text-primary">{binInfo?.rack || "-"}</div>
                </div>
              </AppCard>
            </div>
            <div className="col">
              <AppCard>
                <div className="d-flex align-items-center justify-content-between fs-val-md">
                  <i
                    className="bi bi-box me-2 flex-shrink-0 text-primary fs-val-lg rounded-circle bg-primary bg-opacity-10 d-flex align-items-center justify-content-center"
                    style={{ width: "30px", height: "30px" }}
                  ></i>
                  <div className="flex-grow-1">Bin</div>
                  <div className="text-primary">{binInfo?.bin || "-"}</div>
                </div>
              </AppCard>
            </div>
          </div>
        </div>
        <AppCard>
          <div className="row mb-3">
            <div className="col-6">
              <TextInput
                register={register}
                callback={onSearch}
                gap={0}
                label="Search Deal ID/Name"
                name="search"
              />
            </div>
          </div>

          <PaginationSummary
            loadingTotalRecords={records.loading}
            paginationConfig={paginationRef.current}
            className="mb-1"
          />

          <table className="table bg-white table-striped table-sm">
            <TableHeader
              data={headers}
              noBg={true}
              sortCb={sortCb}
              sort={sortDataRef.current}
            />
            <tbody className="fs-val-md">
              {!loadingData && !data.length ? (
                <tr>
                  <td colSpan={headers.length}>
                    <NoDataFound>No Data found</NoDataFound>
                  </td>
                </tr>
              ) : null}

              {loadingData ? (
                <TableSkeletonLoader
                  cols={headers.length}
                  rows={10}
                  height={40}
                />
              ) : null}

              {data.map((item, index) => (
                <tr key={item.dealId} className="fs-val-md">
                  <td>{paginationRef.current.startSlNo + index}</td>
                  <td>{item.dealId}</td>
                  <td>{item.name}</td>
                  <td>
                    <Amount value={item.mrp} />
                  </td>
                  <td>
                    <DisplayUnit
                      isLoose={item.sellInLooseQty}
                      value={item.stock}
                    />
                  </td>
                  <td>
                    <DateFormatter
                      date={item.lastIntakeOn}
                      format="dd-MMM-yyyy"
                    />
                  </td>
                  <td>
                    <button
                      className="btn btn-link p-0 fs-val-md text-dark"
                      onClick={() => viewLedger(item.dealId)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="text-end">
            <Pagination
              callback={paginationCb}
              activePage={paginationRef.current.activePage}
              rowsPerPage={paginationRef.current.rowsPerPage}
              totalRecords={paginationRef.current.totalRecords}
            />
          </div>
        </AppCard>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PosBinItemsModal;
