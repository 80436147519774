import { TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { useForm, Controller } from "react-hook-form"; // Add Controller import
import { useEffect } from "react";
import PosBrandInput from "../../components/brand-input/PosBrandInput";
import PosCategoryInput from "../../components/category-input/PosCategoryInput";
import PosMenuInput from "../../components/menu-input/PosMenuInput";

const defaultFormData = {
  searchTerm: "",
  menu: "",
  category: [],
  brand: [],
};

const PosStoreNewlyLaunchedFilter = ({ storeId, callback, filterData }) => {
  const { handleSubmit, register, setValue, getValues, reset, control } =
    useForm({
      defaultValues: { ...defaultFormData },
    });

  useEffect(() => {
    Object.keys(filterData).forEach((key) => {
      setValue(key, filterData[key]);
    });
  }, [filterData, setValue]);

  const onSubmit = () => {
    triggerCallback();
  };

  const debouncedSubmit = debounce(onSubmit, 800);

  const handleSearchChange = () => {
    debouncedSubmit();
  };

  const onMenuCb = (selected) => {
    setValue("menu", selected);
    triggerCallback();
  };

  const onCategoryCb = (chngFn) => (val) => {
    chngFn(val);
    triggerCallback();
  };

  const onBrandCb = (chngFn) => (value) => {
    chngFn(value);
    triggerCallback();
  };

  const triggerCallback = () => {
    callback({ action: "apply", formData: getValues() });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="row">
        <div className="col-3">
          <TextInput
            label="Search"
            placeholder="Enter search term"
            type="text"
            size="sm"
            gap={0}
            register={register}
            name="searchTerm"
            callback={handleSearchChange}
          />
        </div>
        <div className="col-3">
          <PosMenuInput
            storeId={storeId}
            callback={onMenuCb}
            register={register}
          />
        </div>
        <div className="col-3">
          <Controller
            name="category"
            control={control}
            render={({ field }) => (
              <PosCategoryInput
                storeId={storeId}
                callback={onCategoryCb(field.onChange)}
                value={field.value}
              />
            )}
          />
        </div>
        <div className="col-3">
          <Controller
            name="brand"
            control={control}
            render={({ field }) => (
              <PosBrandInput
                storeId={storeId}
                callback={onBrandCb(field.onChange)}
                value={field.value}
              />
            )}
          />
        </div>
      </div>
    </form>
  );
};

export default PosStoreNewlyLaunchedFilter;
