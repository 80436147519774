import { CommonService, NavService, PosService } from "@sk/services";
import {
  AppCard,
  BtnLink,
  BusyLoader,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { endOfDay, startOfDay, sub } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import PosReturnFilter from "./PosReturnFilter";
import PosReturnDetailModal from "./modals/return-detail/PosReturnDetailModal";

const headerData = [
  { key: "_slNo", label: "#" },
  { key: "returnId", label: "Return ID", enableSort: true },
  { key: "orderId", label: "Order ID", enableSort: true },
  { key: "returnedDeals", label: "Returned Deals" },
  { key: "customerInfo.name", label: "Customer", enableSort: true },
  { key: "createdAt", label: "Returned on", enableSort: true },
  { key: "type", label: "Type" },
  { key: "status", label: "Status" },
  { key: "action", label: "Action" },
];

const getData = async (params) => {
  const r = await PosService.getReturns(params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const getCount = async (params) => {
  let p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;

  const r = await PosService.getReturns(p);
  return { count: r.resp };
};

const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {
      "franchiseInfo.id": filter.storeId,
    },
    sort: sort.value === "asc" ? sort.key : "-" + sort.key,
  };

  if (filter.search) {
    const search = filter.search.trim();
    const searchRegex = { $regex: search, $options: "gi" };

    if (search) {
      p.filter.$or = [
        { _id: search },
        { orderId: search },
        { "customerInfo.name": searchRegex },
      ];
    }
  }

  if (filter.date?.length) {
    p.filter.createdAt = {
      $gte: startOfDay(filter.date[0]),
      $lte: endOfDay(filter.date[1]),
    };
  }

  if (filter.returnType == "club") {
    p.filter.shipmentUserId = { $exists: true };
  }

  if (filter.status) {
    p.filter.status = filter.status;
  }

  return p;
};

const PosReturns = ({ storeId, type, callback }) => {
  const [returns, setReturns] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recordsCount, setRecordsCount] = useState({
    loading: false,
    count: 0,
  });
  const [busyLoader, setBusyLoader] = useState({ show: false });
  const [viewReturnModal, setViewReturnModal] = useState({
    show: false,
    returnId: null,
  });

  const tableRef = useRef(null);
  const filterDataRef = useRef({
    date: [sub(new Date(), { days: 60 }), new Date()],
  });
  const paginationRef = useRef({
    totalRecords: 0,
    rowsPerPage: 50,
    activePage: 1,
    startSlNo: 1,
    endSlNo: 50,
  });
  const sortRef = useRef({ key: "createdAt", value: "desc" });

  const applyFilter = useCallback(async () => {
    paginationRef.current = {
      totalRecords: 0,
      rowsPerPage: 50,
      activePage: 1,
      startSlNo: 1,
      endSlNo: 50,
    };

    loadList();

    setRecordsCount({ loading: true, count: 0 });
    const r = await getCount(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      )
    );
    paginationRef.current.totalRecords = r.count;
    setRecordsCount({ loading: false, count: r.count });
  }, [loadList]);

  const loadList = useCallback(async () => {
    setLoading(true);
    setReturns([]);
    const r = await getData(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      )
    );
    setReturns(r.data);
    setLoading(false);
  }, []);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    filterDataRef.current.storeId = storeId;
    filterDataRef.current.type = type;
    init();
  }, [init, storeId, type]);

  const onPaginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
    CommonService.scrollToView(tableRef.current, 0);
  };

  const onSortCb = (sort) => {
    sortRef.current = sort;
    applyFilter();
  };

  const onFilterCb = (data) => {
    filterDataRef.current = {
      ...filterDataRef.current,
      ...data.formData,
    };
    applyFilter();
  };

  const viewReturn = (returnId) => {
    setViewReturnModal({ show: true, returnId });
  };

  const returnModalCb = (data) => {
    setViewReturnModal({ show: false, returnId: null });
    if (data.action == "viewOrder") {
      callback({ action: "viewOrder", id: data.id });
    }
    if (data.action == "viewCustomer") {
      callback({ action: "viewCustomer", id: data.id });
    }
  };

  return (
    <>
      <AppCard>
        <PosReturnFilter callback={onFilterCb} type={type} />
      </AppCard>

      <AppCard>
        <PaginationSummary
          loadingTotalRecords={recordsCount.loading}
          paginationConfig={paginationRef.current}
          className="mb-3"
        />

        <div ref={tableRef}></div>

        <table className="table table-sm table-striped">
          <TableHeader
            data={headerData}
            noBg
            sortCb={onSortCb}
            sort={sortRef.current}
          />
          <tbody className="fs-val-md">
            {loading ? (
              <TableSkeletonLoader cols={headerData.length} rows={5} />
            ) : null}

            {!loading && !returns.length ? (
              <tr>
                <td colSpan={headerData.length}>
                  <NoDataFound>No returns found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {returns.map((returnItem, index) => (
              <tr key={returnItem.returnId}>
                <td>{paginationRef.current.startSlNo + index}</td>
                <td>
                  <BtnLink
                    className="fs-val-md"
                    onClick={() => viewReturn(returnItem._id)}
                  >
                    {returnItem._id}
                  </BtnLink>
                </td>
                <td>
                  <BtnLink
                    className="fs-val-md"
                    onClick={() =>
                      NavService.openInNewTab("/pos/order/view", {
                        id: returnItem.orderId,
                      })
                    }
                  >
                    {returnItem.orderId}
                  </BtnLink>
                </td>
                <td>{returnItem.deals.length}</td>
                <td>
                  <BtnLink
                    className="fs-val-md"
                    onClick={() =>
                      NavService.openInNewTab("/users/customer/view", {
                        id: returnItem.customerInfo.id,
                      })
                    }
                  >
                    {returnItem.customerInfo?.name}
                  </BtnLink>
                </td>
                <td>
                  <DateFormatter date={returnItem.createdAt} />
                </td>
                <td>
                  <HighlightText type={returnItem.returnTypeBadge} template={2}>
                    {returnItem.type}
                  </HighlightText>
                </td>
                <td>
                  <HighlightText type={returnItem.statusType} template={2}>
                    {returnItem.displayStatus}
                  </HighlightText>
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-outline-secondary fs-val-sm"
                    onClick={() => viewReturn(returnItem._id)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="d-flex justify-content-end mt-3">
          <PaginationBlock
            loadingTotalRecords={recordsCount.loading}
            paginationCb={onPaginationCb}
            size="sm"
            paginationConfig={paginationRef.current}
          />
        </div>
      </AppCard>

      <BusyLoader show={busyLoader.show} />

      <PosReturnDetailModal
        show={viewReturnModal.show}
        returnId={viewReturnModal.returnId}
        callback={returnModalCb}
      />
    </>
  );
};

export default PosReturns;
