import { CustomerService, PosService } from "@sk/services";
import { AppCard, PageLoader, Tabs, PageInfo } from "@sk/uis";
import produce from "immer";
import { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useFetchUrlQueryString } from "@sk/hooks";
import ClubReqBasic from "./components/ClubReqBasic";
import ClubReqDeliveryDetail from "./components/ClubReqDeliveryDetail";
import ClubReqItem from "./components/ClubReqItem";
import ClubReqStore from "./components/ClubReqStore";
import ClubReqTimeline from "./components/ClubReqTimeline";
import ClubReqInvoice from "./components/ClubReqInvoice";

const breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Request Details",
  },
];

const tabs = [
  {
    tabName: "Timeline",
    key: "timeline",
  },
  {
    tabName: "Items",
    key: "items",
  },
];

const getData = async (requestId) => {
  const response = await CustomerService.getClubRequests({
    filter: {
      _id: requestId,
    },
  });

  let customerRequest =
    Array.isArray(response.resp) && response.resp.length > 0
      ? CustomerService.formatCustomerServiceRequests(response.resp)[0]
      : {};

  let order = {};
  let invoice = {};

  if (
    customerRequest.posOrderIdList &&
    customerRequest.posOrderIdList.length > 0
  ) {
    const orderResponse = await PosService.getOrders({
      filter: {
        _id: { $in: customerRequest.posOrderIdList },
      },
    });

    order = orderResponse.resp[0] || {};

    // Check if the order has an invoiceId
    if (order.invoiceId) {
      const invoiceResponse = await PosService.getInvoices({
        filter: {
          _id: order.invoiceId,
        },
      });

      invoice = invoiceResponse.resp[0] || {};
    }
  }

  // Check if the request status is "Created" before fetching deal IDs and stock details
  if (
    customerRequest.status === "Created" ||
    customerRequest.status === "Approved"
  ) {
    // Collect all deal IDs from customerRequest
    const dealIds = customerRequest.listingPayload.map((item) => item.dealId);

    // Fetch stock details for the collected deal IDs
    const stockResponse = await PosService.getDealsStock({
      page: 1,
      count: dealIds.length,
      filter: {
        _id: customerRequest.franchiseInfo?.id,
      },
      dealFilter: {
        _id: { $in: dealIds },
      },
    });

    const stockData = stockResponse.resp || [];

    // Attach quantity to the relevant deal in listingPayload
    const updatedListingPayload = customerRequest.listingPayload.map((item) => {
      const stockItem = stockData.find((stock) => stock._id === item.dealId);

      // Initialize processedQty
      let processedQty = 0;

      // If order data is present, find the matching deal and get the qty
      if (order.deals) {
        const matchingDeal = order.deals.find(
          (deal) => deal._id === item.dealId
        );
        processedQty = matchingDeal ? matchingDeal.qty : 0;
      }

      return {
        ...item,
        stockQty: stockItem ? stockItem.qty : 0,
        blockedQty: stockItem ? stockItem.blockedQty : 0,
        processedQty,
        showProcessedQty: order?._id ? true : false,
      };
    });

    // Update customerRequest with the modified listingPayload
    customerRequest.listingPayload = updatedListingPayload;
  }

  return {
    customerRequest,
    order,
    invoice,
    basic: {
      pickerInfo: customerRequest.pickerInfo,
      customerInfo: {
        id: customerRequest.customerInfo?.id,
        name: customerRequest.customerInfo?.name,
        isGuestCustomer: customerRequest.customerInfo?.isGuestCustomer,
      },
      statusType: customerRequest._bgCss,
      displayStatus: customerRequest._statusLbl,
      _id: customerRequest._id,
      shippingCharges: customerRequest.shippingCharges,
      _payableAmt: customerRequest._displayFinalAmt,
      createdAt: customerRequest.createdAt,
      amountReceivedVia: customerRequest.amountReceivedVia,
      orderId: order._id,
      rejectedReason: customerRequest.rejectedReason,
      thirdPartyPartnerOrderId:
        customerRequest.thirdPartyPartnerOrderId || null,
    },
    deliveryDetail: {
      deliveryDistance: customerRequest.deliveryDistance || "N/A",
      franchiseInfo: customerRequest.franchiseInfo,
      deliveryTimeSlot: customerRequest.deliveryTimeSlot,
      shippingAddress: customerRequest.shippingAddress,
      lat: customerRequest.shippingAddress?.geolocation?.coordinates[0],
      lng: customerRequest.shippingAddress?.geolocation?.coordinates[1],
      storeLat: customerRequest?.franchiseInfo?.geolocation?.coordinates[0],
      storeLng: customerRequest?.franchiseInfo?.geolocation?.coordinates[1],
    },
  };
};

const ClubRequestDetail = () => {
  const [searchParams] = useSearchParams();
  const query = useFetchUrlQueryString(searchParams);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tabData, setTabData] = useState([...tabs]);
  const [activeTab, setActiveTab] = useState(tabs[0]);
  const navigate = useNavigate();

  useEffect(() => {
    if (query.id) {
      const fetchData = async () => {
        setLoading(true);
        const fetchedData = await getData(query.id);

        setData({
          customerRequest: fetchedData.customerRequest,
          order: fetchedData.order,
          invoice: fetchedData.invoice,
          basic: fetchedData.basic,
          deliveryDetail: fetchedData.deliveryDetail,
        });

        setTabData(
          produce((draft) => {
            const index = draft.findIndex((tab) => tab.key === "items");
            draft[index].tabName = `${
              fetchedData.customerRequest.listingPayload.length
            } Item${
              fetchedData.customerRequest.listingPayload.length > 1 ? "s" : ""
            }`;

            // Check if invoice tab should be added
            // const invoiceTabExists = draft.some(
            //               (tab) => tab.key === "invoiced"
            //             );
            //             if (fetchedData.customerRequest._showInvoice && !invoiceTabExists) {
            //               draft.push({
            //                 tabName: "Invoice",
            //                 key: "invoiced",
            //               });
            //             }
          })
        );

        setLoading(false);
      };

      setActiveTab(tabs[0]);
      fetchData();
    }
  }, [query.id]);

  const onTabChange = ({ value }) => {
    setActiveTab(value);
  };

  const handleCb = (actionData) => {
    switch (actionData.action) {
      case "viewCustomer":
        // Navigate to customer details
        break;
      case "viewOrder":
        // Navigate to order details
        break;
      default:
        break;
    }
  };

  return (
    <>
      <PageInfo
        title={`Request Details #${query.id}`}
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />

      {loading ? <PageLoader /> : null}

      {!loading && data?.customerRequest?._id ? (
        <div className="row">
          <div className="col-6">
            <div className="position-sticky top-0">
              <AppCard title="Basic Details">
                <ClubReqBasic callback={handleCb} order={data.basic} />
              </AppCard>
              <AppCard title="Store Information">
                <ClubReqStore storeInfo={data.customerRequest.franchiseInfo} />
              </AppCard>
              <AppCard title="Delivery Details">
                <ClubReqDeliveryDetail order={data.deliveryDetail} />
              </AppCard>
            </div>
          </div>
          <div className="col-6">
            <div className="mb-2">
              <Tabs
                data={tabData}
                activeTab={activeTab}
                callback={onTabChange}
                template={3}
              />
            </div>

            {activeTab.key === "items" && (
              <>
                <div className="fs-val-lg">
                  Items ({data.customerRequest.listingPayload.length})
                </div>
                {data.customerRequest.listingPayload.map((item) => (
                  <div
                    key={item._id}
                    className="mb-2 bg-white border rounded p-2"
                  >
                    <ClubReqItem item={item} />

                    {item.offerDetails?.deals?.length > 0 && (
                      <div>
                        <div className="mt-2 fs-val-sm">Offers:</div>
                        {item.offerDetails.deals.map((offer) => (
                          <ClubReqItem
                            item={offer}
                            key={`${item._id}-${offer.id}`}
                          />
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}

            {activeTab.key === "invoiced" &&
            data.customerRequest._showInvoice ? (
              <ClubReqInvoice orderId={data.basic.orderId} />
            ) : null}

            {activeTab.key !== "items" && activeTab.key !== "invoiced" && (
              <AppCard>
                <ClubReqTimeline
                  timelineData={data.customerRequest._timeline}
                  sellerId={data.customerRequest?.franchiseInfo?.sellerId}
                  partnerOrderId={data.customerRequest.thirdPartyPartnerOrderId}
                  fid={data.customerRequest?.franchiseInfo?.id}
                />
              </AppCard>
            )}
          </div>
        </div>
      ) : (
        <div className="text-center mt-5">No request details found</div>
      )}
    </>
  );
};

export default ClubRequestDetail;
