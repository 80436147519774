import { PosService } from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  HighlightText,
  KeyVal,
  PageLoader,
  TableHeader,
  BtnLink,
} from "@sk/uis";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { NavService } from "@sk/services"; // Import NavService

const canvasStyle = {
  width: "70%",
};

const headers = [
  {
    label: "#",
    key: "slno",
    width: "3%",
  },
  {
    label: "Name",
    key: "name",
    width: "30%",
  },
  {
    label: "Returned Qty",
    key: "returnedQty",
    isCentered: true,
  },
  {
    label: "Reason",
    key: "reason",
  },
  {
    label: "Remarks",
    key: "remarks",
  },
  {
    label: "Attachments",
    key: "attachments",
  },
];

const PosReturnDetailModal = ({ returnId, callback, show }) => {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (show) {
      const fetchDetails = async () => {
        setLoading(true);
        const res = await PosService.getReturns({
          filter: {
            _id: returnId,
          },
        });
        let d =
          Array.isArray(res.resp) && res.resp.length > 0 ? res.resp[0] : {};

        if (d._id) {
          d.deals = d.deals.sort((a, b) => a.name.localeCompare(b.name));
        }

        setDetails(d);
        setLoading(false);
      };
      fetchDetails();
    }
  }, [returnId, show]);

  const onClose = () => {
    callback({ action: "close" });
  };

  return (
    <Offcanvas show={show} onHide={onClose} placement="end" style={canvasStyle}>
      <Offcanvas.Header closeButton className="bg-white">
        <Offcanvas.Title>Return Details - #{details._id}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="modal-bg">
        {loading ? <PageLoader /> : null}

        {!loading && details._id ? (
          <>
            <AppCard
              title={
                <div className="d-flex gap-2 justify-content-between align-items-center">
                  <span className="card-title">Basic Details</span>

                  <HighlightText type={details.returnTypeBadge} template={2}>
                    {details.type}
                  </HighlightText>
                </div>
              }
            >
              <div className="row">
                <div className="col-3">
                  <KeyVal label="Return ID" template="col">
                    {details._id}
                  </KeyVal>
                </div>
                <div className="col-3">
                  <KeyVal label="Return Date" template="col">
                    {<DateFormatter date={details.createdAt} />}
                  </KeyVal>
                </div>
                <div className="col-3">
                  <KeyVal label="Order ID" template="col" noBottomSpace={true}>
                    <BtnLink
                      className="fs-val-md"
                      onClick={() =>
                        NavService.openInNewTab("/pos/order/view", {
                          id: details.orderId,
                        })
                      }
                    >
                      {details.orderId || "--"}
                    </BtnLink>
                  </KeyVal>
                </div>
                <div className="col-3">
                  <KeyVal label="Status" template="col">
                    <HighlightText type={details.statusType} template={2}>
                      {details.displayStatus || "--"}
                    </HighlightText>
                  </KeyVal>
                </div>
              </div>
            </AppCard>
            <div className="d-flex gap-2 mb-3">
              <div className="flex-fill">
                <AppCard title="Store Details" cardClassName="h-100">
                  <KeyVal label="Store Name" template="col">
                    <BtnLink
                      className="fs-val-md"
                      onClick={() =>
                        callback({
                          action: "viewStore",
                          id: details.franchiseInfo?.id,
                        })
                      }
                    >
                      {details.franchiseInfo?.name || "--"} -{" "}
                      {details.franchiseInfo?.id || "--"}
                    </BtnLink>
                  </KeyVal>
                  <KeyVal
                    label="Store Location"
                    template="col"
                    noBottomSpace={true}
                  >
                    <div className="text-truncate">
                      {details.franchiseInfo?.town || "--"},{" "}
                      {details.franchiseInfo?.district || "--"},{" "}
                      {details.franchiseInfo?.state} -{" "}
                      {details.franchiseInfo?.pincode}
                    </div>
                  </KeyVal>
                </AppCard>
              </div>
              <div className="flex-fill">
                <AppCard title="Customer Details" cardClassName="h-100">
                  <KeyVal label="Customer Name" template="col">
                    <BtnLink
                      className="fs-val-md text-start p-0  fw-medium text-dark"
                      onClick={() =>
                        NavService.openInNewTab("/users/customer/view", {
                          id: details.customerInfo?.id,
                        })
                      }
                    >
                      {details.customerInfo?.name || "--"}
                    </BtnLink>
                  </KeyVal>
                  <KeyVal
                    label="Customer ID"
                    template="col"
                    noBottomSpace={true}
                  >
                    {details.customerInfo?.id || "--"}
                  </KeyVal>
                </AppCard>
              </div>
            </div>

            <AppCard title={`Returned Deals   (${details.deals.length})`}>
              <table className="table table-sm">
                <TableHeader data={headers} noBg={true} />
                <tbody className="fs-val-md">
                  {details.deals.map((deal, index) => (
                    <tr key={deal._id}>
                      <td>{index + 1}</td>
                      <td>
                        <div className="mb-1">{deal.name}</div>
                        <div className="d-flex gap-2 fs-val-sm">
                          <span className="text-muted">
                            MRP: <Amount value={deal.mrp} />
                          </span>
                          <span className="text-muted">
                            Price:{" "}
                            <Amount value={deal.b2bPrice} decimalPlaces={2} />
                          </span>
                        </div>
                      </td>
                      <td className="text-center">{deal.quantity}</td>
                      <td>{deal.reasonType}</td>
                      <td>{deal.remarks}</td>
                      <td>
                        {deal.images.length > 0 ? (
                          <BtnLink
                            className="text-dark fs-val-md text-start p-0"
                            onClick={() =>
                              window.open(deal.images[0], "_blank")
                            }
                          >
                            View Image
                          </BtnLink>
                        ) : (
                          "--"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </AppCard>
          </>
        ) : null}
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default PosReturnDetailModal;
