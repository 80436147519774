import { FranchiseService } from "@sk/services";
import { NoDataFound, PageLoader, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useForm } from "react-hook-form";

const canvasStyle = { width: "30%" };

const getData = async (params) => {
  const r = await FranchiseService.getList(params);
  const d = Array.isArray(r.resp) ? r.resp : [];
  return { data: d };
};

const prepareParams = (filter = {}, pagination = {}) => {
  const s = (filter.search || "").trim();

  let params = {
    page: pagination.page,
    count: pagination.count,
    filter: {
      groupType: "COCO",
    },
  };

  if (s) {
    params.filter.$or = [
      {
        _id: s,
      },
      {
        name: {
          $regex: s,
          $options: "gi",
        },
      },
    ];
  }

  return params;
};

function PosFranModal({ show = false, callback }) {
  const { register, getValues } = useForm({ defaultValues: { search: "" } });

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);

  const filterDataRef = useRef({ search: "" });

  const paginationRef = useRef({ page: 1, count: 25 });

  const init = useCallback(async () => {
    applyFilter();
  }, []);

  const applyFilter = async () => {
    paginationRef.current.page = 1;
    setLoading(true);
    const r = await getData(
      prepareParams(filterDataRef.current, paginationRef.current)
    );
    setData(r.data);
    setLoading(false);
  };

  useEffect(() => {
    if (show) {
      init();
    }
  }, [init, show]);

  const closeModal = () => {
    callback({ action: "close" });
  };

  const onSearch = useCallback(
    debounce(() => {
      filterDataRef.current.search = getValues("search");
      applyFilter();
    }, 800),
    []
  );

  const onFranClick = (d) => {
    callback({
      action: "selected",
      data: d,
    });
  };

  return (
    <Offcanvas
      show={show}
      onHide={closeModal}
      placement="end"
      style={canvasStyle}
    >
      <Offcanvas.Header closeButton className="bg-light">
        {/* Modal Title */}
        <Offcanvas.Title>
          <div className="fs-val-lg"> Choose Store </div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-3  border-top">
        <div>
          <TextInput
            register={register}
            name="search"
            placeholder="Search by Store Name/ID"
            callback={onSearch}
          />
        </div>

        {loading ? <PageLoader /> : null}

        {!loading && !data.length ? (
          <NoDataFound>No Stores found</NoDataFound>
        ) : null}

        {data.map((x) => (
          <button
            className="btn w-100 text-start d-block border rounded mb-3"
            key={x._id}
            onClick={() => onFranClick(x)}
          >
            <div className="d-flex">
              <div className="flex-fill">
                <div className="fw-semibold fs-val-md mb-1">
                  {x.name} - {x._id}
                </div>
                <div className="fs-val-sm text-muted">
                  {x.town}, {x.district} - {x.pincode}
                </div>
              </div>
              <div>
                <span className="bi bi-chevron-right"></span>
              </div>
            </div>
          </button>
        ))}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default PosFranModal;
