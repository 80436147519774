import { PosService } from "@sk/services";
import { SelectInput } from "@sk/uis";
import { useEffect, useState } from "react";

const PosMenuInput = ({ storeId, callback, register }) => {
  const [menuOptions, setMenuOptions] = useState([
    {
      label: "All Menu",
      value: "",
    },
  ]);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await PosService.getMenu({
          filter: { _id: storeId },
        });
        const options = response.resp.map((menu) => ({
          label: menu.name,
          value: menu._id,
        }));
        options.unshift({ label: "All Menu", value: "" });
        setMenuOptions(options);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchMenuData();
  }, [storeId]);

  return (
    <>
      <SelectInput
        label="Select Menu"
        options={menuOptions}
        callback={callback}
        register={register}
        name="menu"
        size="sm"
        gap={0}
      />
    </>
  );
};

export default PosMenuInput;
