import { PosStockLedger, PosStockLedgerMaster } from "@sk/features";
import { AppCard, Tabs } from "@sk/uis";
import { useState } from "react";

const tabs = [
  { key: "stockLedger", tabName: "Stock Ledger" },
  { key: "stockMaster", tabName: "Stock Master" },
];

const StockDetails = ({ dealId, storeId, isLooseDeal }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const tabCb = (tab) => {
    setActiveTab({ ...tab.value });
  };

  return (
    <>
      <div className="mb-3">
        <Tabs data={tabs} activeTab={activeTab} callback={tabCb} template={3} />
      </div>

      {activeTab.key === "stockLedger" && (
        <AppCard>
          <PosStockLedger
            dealId={dealId}
            storeId={storeId}
            isLooseDeal={isLooseDeal}
          />
        </AppCard>
      )}

      {activeTab.key === "stockMaster" && (
        <AppCard>
          <PosStockLedgerMaster
            dealId={dealId}
            storeId={storeId}
            isLooseDeal={isLooseDeal}
          />
        </AppCard>
      )}
    </>
  );
};

export default StockDetails;
