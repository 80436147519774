import { FranchiseService, PosService } from "@sk/services";
import { DateFormatter } from "@sk/uis";
import { format } from "date-fns";
import produce from "immer";
import { useCallback, useEffect, useState } from "react";

const ClubReqTimeline = ({ timelineData, sellerId, partnerOrderId, fid }) => {
  const [userDetails, setUserDetails] = useState({});
  const [timelineDataState, setTimelineData] = useState(timelineData);

  useEffect(() => {
    init();
  }, [init]);

  const fetchUserDetails = async (ids) => {
    const response = await FranchiseService.getFranchiseManpower({
      page: 1,
      count: ids.length,
      filter: {
        _id: { $in: ids },
      },
    });
    const data = Array.isArray(response.resp) ? response.resp : [];
    const userMap = data.reduce((acc, user) => {
      acc[user._id] = user.name;
      return acc;
    }, {});

    userMap["System"] = "System";

    setUserDetails(userMap);
  };

  const fetchOrderLog = useCallback(async () => {
    const franchiseResponse = await FranchiseService.getFranchise(fid, {
      select: "posFlow",
    });
    const franchiseData = franchiseResponse.resp || {};
    const pickersEnabled = franchiseData.posFlow?.pickersEnabled || false;

    if (partnerOrderId) {
      const response = await PosService.getPartnerOrderLog({
        filter: {
          sellerId,
          partnerOrderId,
        },
      });
      const orderLogData = response.resp || [];

      setTimelineData(
        produce((draft) => {
          // Create a new array to hold the filtered logs
          const filteredLogs = [];

          for (let i = 0; i < draft.length; i++) {
            const log = draft[i];
            // Check if the log should be included based on showPickerEnabled
            if (log.showPickerEnabled && !pickersEnabled) {
              continue; // Skip this log
            }

            // If the log is to be included, process its partnerRemarks
            if (log.partnerRemarks) {
              const l = orderLogData.find(
                (e) => e.status === log.partnerStatus
              );

              if (l) {
                log.partnerRemarks = log.partnerRemarks.replace(
                  "[time]",
                  format(new Date(l.createdAt), "dd MMM yyyy hh:mm a")
                );
                if (
                  log.key === "created" &&
                  !log.partnerRemarks.includes(partnerOrderId)
                ) {
                  log.partnerRemarks += ` (Partner Order ID: ${partnerOrderId})`;
                }
              } else {
                log.partnerRemarks = "No updates from partner";
              }
            }

            // Add the log to the filteredLogs array
            filteredLogs.push(log);
          }

          // Replace the draft with the filtered logs
          draft.splice(0, draft.length, ...filteredLogs);
        })
      );
    }
  }, [sellerId, partnerOrderId, fid]);

  const init = useCallback(async () => {
    const ids = timelineData.map((x) => x.doneBy).filter(Boolean);
    if (ids.length > 0) {
      fetchUserDetails(ids);
    }

    fetchOrderLog();
  }, [timelineData, fetchOrderLog]);

  return (
    <>
      <div className="activity">
        {timelineDataState.map((x, i) => (
          <div
            className="activity-info"
            key={i}
            style={{ opacity: x.time ? 1 : 0.5 }}
          >
            <div className="icon-info-activity">
              <i
                className={`bi ${x.icon} ${
                  x.time ? "bg-success text-white" : ""
                }`}
              />
            </div>
            <div className="activity-info-text">
              <div className="fs-val-md">{x.label}</div>
              <div className="text-muted d-block fs-val-sm">
                {x.time ? (
                  <>
                    <DateFormatter date={x.time} />
                    {x.doneBy ? (
                      <p className="text-muted mt-2 fs-val-sm">
                        {x.remarks.replace(
                          "[pickerName]",
                          userDetails[x.doneBy] || "Unknown"
                        )}
                      </p>
                    ) : null}
                  </>
                ) : (
                  <div className="text-muted">Not done yet</div>
                )}

                {x.partnerRemarks && (
                  <div className="border border-secondary rounded p-2 mt-2 mb-3 d-inline-block">
                    <div className="text-muted small">
                      <span className="fw-bold">Partner Remarks:</span> &quot;
                      {x.partnerRemarks}&quot;
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default ClubReqTimeline;
