import { AppSlide, AppSwiper, SummaryCard } from "@sk/uis";

const swiperConfig = {
  slidesPerView: "auto",
  spaceBetween: 10,
};

const PosCustomerAnalyticsSummary = ({ summaryData = [] }) => {
  return (
    <AppSwiper config={swiperConfig}>
      {summaryData.map((item, index) => (
        <AppSlide key={index} autoWidth>
          <div>
            <SummaryCard
              template={3}
              title={item.title}
              value={item.value}
              loading={item.loading}
              valueColor={item.valueColor}
              isValueAmt={item.isValueAmt}
            />
          </div>
        </AppSlide>
      ))}
    </AppSwiper>
  );
};

export default PosCustomerAnalyticsSummary;
