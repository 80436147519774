export const statusOptions = [
  {
    value: "",
    label: "All",
    color: "dark",
    summaryStatusKey: "",
    filterStatus: [],
  },
  {
    value: "YET_TO_ASSIGN_PICKER",
    label: "Yet to Assign Picker",
    color: "warning",
    summaryStatusKey: "Yet to Assign Picker",
    filterStatus: ["Created", "Approved"],
  },
  {
    value: "PICKER_ASSIGNED",
    label: "Picker Assigned",
    color: "info",
    summaryStatusKey: "Picker Assigned",
    filterStatus: ["Assigned"],
  },
  {
    value: "PENDING",
    label: "Approval Pending",
    color: "warning",
    summaryStatusKey: "Created",
    filterStatus: ["Created"],
  },
  {
    value: "YET_TO_PROCESS",
    label: "Yet to Process",
    color: "warning",
    summaryStatusKey: "Approved",
    filterStatus: ["Approved", "Partially Processing"],
  },
  {
    value: "YET_TO_SHIP",
    label: "Ready to Ship",
    color: "info",
    summaryStatusKey: "Invoiced",
    filterStatus: ["Invoiced"],
  },
  {
    value: "SHIPPED",
    label: "Shipped",
    color: "success",
    summaryStatusKey: "Shipped",
    filterStatus: ["Shipped"],
  },
  {
    value: "DELIVERED",
    label: "Delivered",
    color: "primary",
    summaryStatusKey: "Delivered",
    filterStatus: ["Delivered", "Partially Delivered"],
  },
  {
    value: "REJECTED",
    label: "Rejected",
    color: "danger",
    summaryStatusKey: "Rejected",
    filterStatus: ["Rejected"],
  },
  {
    value: "EXPIRED",
    label: "Expired",
    color: "danger",
    summaryStatusKey: "Expired",
    filterStatus: ["Expired"],
  },
  {
    value: "RETURNED",
    label: "Returned",
    color: "secondary",
    summaryStatusKey: "Returned",
    filterStatus: ["Returned"],
  },
];
