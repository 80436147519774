import { CommonService, PosService } from "@sk/services";
import {
  Amount,
  AppCard,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
  BtnLink,
} from "@sk/uis";
import { endOfDay, startOfDay } from "date-fns";
import produce from "immer";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { NavService } from "@sk/services";

const getData = async (type, params) => {
  const response = await PosService.getPosTopProducts(params);
  if (params.download) {
    return response.resp;
  }

  if (type === "product") {
    const dealIds = response.resp.map((item) => item.dealId);
    if (dealIds.length > 0) {
      const stockResponse = await PosService.getDealsStock({
        page: 1,
        count: dealIds.length,
        filter: {
          _id: params.filter.$or[0].franchise,
        },
        dealFilter: { _id: { $in: dealIds } },
      });

      const stockData = stockResponse.resp.reduce((acc, item) => {
        acc[item._id] = {
          currentStock: item._qty,
          sellInLooseQty: item.sellInLooseQty || 0,
        };
        return acc;
      }, {});

      response.resp = response.resp.map((item) => ({
        ...item,
        currentStock: stockData[item.dealId]?.currentStock || 0,
        sellInLooseQty: stockData[item.dealId]?.sellInLooseQty || 0,
      }));
    }
  }

  return Array.isArray(response.resp) ? response.resp : [];
};

const prepareFilterParams = (type, filter, pagination, sort, isDownload) => {
  const params = {
    filter: {
      $or: [
        { franchise: filter.storeId },
        { "franchiseInfo.id": filter.storeId },
      ],
      createdAt: {
        $gte: startOfDay(new Date(filter.fromDate)), // Start of day
        $lte: endOfDay(new Date(filter.toDate)), // End of day
      },
    },
    sort: {},
    page: pagination.activePage,
    count: pagination.rowsPerPage,
  };

  const search = filter.search?.trim();

  if (type === "product") {
    params.groupbycond = "product";
    params.groupbycondName = "productName";
  } else if (type === "category") {
    params.groupbycond = "category";
    params.groupbycondName = "categoryName";
  } else if (type === "brand") {
    params.groupbycond = "brand";
    params.groupbycondName = "brandName";
  } else if (type === "menu") {
    params.groupbycond = "menu";
    params.groupbycondName = "menuName";
  }

  if (search) {
    if (type == "product") {
      params.categoryFilter = {
        $or: [
          {
            productName: { $regex: search, $options: "i" },
          },
          {
            productId: search,
          },
          {
            dealId: search,
          },
        ],
      };
    }

    if (type == "menu") {
      params.categoryFilter = {
        $or: [
          {
            menuName: { $regex: search, $options: "i" },
          },
          {
            menuId: search,
          },
        ],
      };
    }

    if (type == "category") {
      params.categoryFilter = {
        $or: [
          {
            categoryName: { $regex: search, $options: "i" },
          },
          {
            categoryId: search,
          },
        ],
      };
    }

    if (type == "brand") {
      params.categoryFilter = {
        $or: [
          {
            brandName: { $regex: search, $options: "i" },
          },
          {
            brandId: search,
          },
        ],
      };
    }
  }

  // if (search) {
  //   params.filter["menu.name"] = { $regex: search, $options: "i" };
  // }

  // Add sorting if specified
  if (sort.key) {
    params.sort[sort.key] = sort.value === "asc" ? 1 : -1;
  }

  if (isDownload) {
    params.download = true;
  }

  return params;
};

const PosAnalyticsFeature = ({
  fromDate,
  toDate,
  storeId,
  type,
  storeName,
}) => {
  const { register } = useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const paginationRef = useRef({
    activePage: 1,
    rowsPerPage: 30,
  });
  const filterDataRef = useRef();
  const sortRef = useRef({ key: "value", value: "desc" });

  const headers = useMemo(() => {
    if (type === "product") {
      return [
        { label: "#", key: "sno", enableSort: false, width: "5%" },
        { label: "Name", key: "name", enableSort: true, width: "30%" },
        { label: "ID", key: "dealId", enableSort: false, width: "10%" },
        { label: "Sold Qty", key: "qty", enableSort: true, width: "10%" },
        { label: "Value", key: "value", enableSort: true, width: "10%" },
        {
          label: "Category",
          key: "categoryName",
          enableSort: true,
          width: "20%",
        },
        { label: "Brand", key: "brandName", enableSort: true, width: "15%" },
        {
          label: "Current Stock",
          key: "currentStock",
          width: "25%",
        },
      ];
    }

    if (type === "category" || type === "brand" || type === "menu") {
      return [
        { label: "#", key: "sno", enableSort: false, width: "5%" },
        { label: "Name", key: "name", enableSort: true, width: "30%" },
        { label: "Sold Qty", key: "qty", enableSort: true, width: "15%" },
        { label: "Value", key: "value", enableSort: true, width: "15%" },
      ];
    }

    return [];
  }, [type]);

  const downloadData = async () => {
    try {
      const params = prepareFilterParams(
        filterDataRef.current.type,
        filterDataRef.current,
        paginationRef.current,
        sortRef.current,
        true
      );

      delete params.page;
      delete params.count;

      const fetchedData = await getData(filterDataRef.current.type, params);
      if (fetchedData?.downloadLink) {
        CommonService.downloadAsset(fetchedData.downloadLink);
      }
    } catch (error) {
      console.error("Error downloading data:", error);
    }
  };

  const getTitleNode = (type) => {
    const titleMap = {
      product: "Product Analytics",
      category: "Category Analytics",
      brand: "Brand Analytics",
      menu: "Menu Analytics",
    };

    const title = titleMap[type] || "Analytics Overview";

    return (
      <div className="row align-items-center">
        <div className="col">
          <h5 className="mb-0 card-title">{title}</h5>
        </div>
        <div className="col-auto">
          <button
            className="btn btn-link fs-val-sm p-0 m-0"
            onClick={downloadData}
          >
            Download
          </button>
        </div>
      </div>
    );
  };

  const titleNode = getTitleNode(type);

  // Define styles based on type
  const containerStyle = {
    height:
      type === "menu" || type === "brand" || type === "category"
        ? "350px"
        : "auto",
    maxHeight: type === "product" ? "800px" : "none",
    overflowY: "auto", // Allow scrolling if content exceeds height
  };

  const applyFilter = async () => {
    paginationRef.current.activePage = 1;
    setLoading(true);
    setData([]);
    try {
      const params = prepareFilterParams(
        filterDataRef.current.type,
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      );
      const fetchedData = await getData(filterDataRef.current.type, params);
      setData(fetchedData);
      setHasMoreData(fetchedData.length >= paginationRef.current.rowsPerPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    filterDataRef.current = { fromDate, toDate, storeId, type };
    applyFilter();
  }, [fromDate, toDate, storeId, type]);

  const loadMore = async () => {
    setLoadingMore(true);
    try {
      paginationRef.current.activePage += 1;
      const params = prepareFilterParams(
        filterDataRef.current.type,
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      );
      const fetchedData = await getData(filterDataRef.current.type, params);
      setData(
        produce((draft) => {
          draft.push(...fetchedData);
        })
      );
      setHasMoreData(fetchedData.length >= paginationRef.current.rowsPerPage);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingMore(false);
    }
  };

  const sortCb = (sort) => {
    sortRef.current = sort;
    applyFilter();
  };

  const onSearchChange = useCallback(
    debounce((e) => {
      filterDataRef.current.search = e.target.value;
      applyFilter();
    }, 500),
    []
  );

  return (
    <>
      <AppCard title={titleNode}>
        <div className="row">
          <div className={type === "product" ? "col-4" : "col-12"}>
            <TextInput
              register={register}
              name="search"
              placeholder={`Search ${type}`}
              callback={onSearchChange}
            />
          </div>
        </div>
        <div style={containerStyle} className="custom-scrollbar">
          <table className="table table-striped table-sm">
            <TableHeader
              data={headers}
              noBg
              sortCb={sortCb}
              sort={sortRef.current}
              isSticky={true}
            />
            <tbody className="fs-val-md">
              {!loading && data.length === 0 && (
                <tr>
                  <td colSpan={headers.length}>
                    <NoDataFound>
                      No data available for the selected filters.
                    </NoDataFound>
                  </td>
                </tr>
              )}
              {loading && (
                <TableSkeletonLoader rows={10} cols={headers.length} />
              )}
              {data.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    {type === "product" ? (
                      <BtnLink
                        className="text-dark"
                        onClick={() =>
                          NavService.openInNewTab("/pos/store/deal/view", {
                            id: item.dealId,
                            storeId,
                            storeName,
                          })
                        }
                      >
                        {item.name}
                      </BtnLink>
                    ) : (
                      <span className="text-dark">{item.name}</span>
                    )}
                  </td>
                  {type == "product" && <td>{item.dealId}</td>}
                  <td>{item.qty}</td>
                  <td>
                    <Amount value={item.value} />
                  </td>
                  {type === "product" && (
                    <>
                      <td>{item.categoryName}</td>
                      <td>{item.brandName}</td>
                      <td>
                        {item.currentStock}{" "}
                        {item.sellInLooseQty ? "kg" : "units"}
                      </td>
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {hasMoreData && (
            <div className="text-center mt-3">
              <button
                onClick={loadMore}
                disabled={loadingMore}
                className="btn btn-sm fs-val-sm bg-light"
              >
                {loadingMore ? "Loading..." : "Load More"}
              </button>
            </div>
          )}
        </div>
      </AppCard>
    </>
  );
};

export default PosAnalyticsFeature;
