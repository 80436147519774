import { AddToPurchaseBasketModal, StoreCard } from "@sk/features";
import { useFetchUrlQueryString } from "@sk/hooks";
import {
  CommonService,
  DealService,
  FranchiseService,
  PosService,
} from "@sk/services";
import {
  Amount,
  AppCard,
  BusyLoader,
  DateFormatter,
  InfoPopover,
  NoDataFound,
  PageInfo,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
} from "@sk/uis";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PosStoreNewlyLaunchedFilter from "./components/PosStoreNewlyLaunchedFilter";
// Define header data for the table
const headerData = [
  { key: "_slNo", label: "#", width: "3%", enableSort: false },
  { key: "name", label: "Deal Name", width: "30%", enableSort: true },
  { key: "id", label: "Deal ID", width: "10%", enableSort: true },
  { key: "mrp", label: "MRP (₹)", width: "10%", enableSort: true },
  { key: "ptr", label: "PTR (₹)", width: "10%", enableSort: true },
  { key: "discount", label: "Discount", width: "10%", enableSort: true },
  { key: "menu", label: "Menu", width: "15%", enableSort: true },
  { key: "brand", label: "Brand", width: "15%", enableSort: true },
  { key: "category", label: "Category", width: "20%", enableSort: true },
  {
    key: "action",
    label: "Action",
    width: "10%",
    isSticky: true,
    stickyPosition: "right",
  },
];

// Pagination configuration
const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

// Fetch deals data
const getData = async (params) => {
  const response = await PosService.getNewlyLaunchedProducts(params);
  return { data: Array.isArray(response.resp) ? response.resp : [] };
};

// Fetch count of deals
const getCount = async (params) => {
  let p = { ...params, outputType: "count" };
  delete p.page;
  delete p.count;

  const response = await PosService.getNewlyLaunchedProducts(p);
  return { count: response.resp?.[0]?.total };
};

// Add the prepareFilterParams function
const prepareFilterParams = (filter, pagination) => {
  let params = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      whId: { $in: filter.whIds },
      sellerId: filter.fid,
    },
    projectFilter: {},
  };

  const searchTerm = filter.searchTerm?.trim();
  if (searchTerm) {
    params.projectFilter.$or = [
      { name: { $regex: searchTerm, $options: "i" } },
    ];
  }

  if (filter.menu) {
    params.projectFilter.menuId = filter.menu;
  }

  if (filter.brand?.length) {
    const brandIds = filter.brand.map((item) => item.value._id);
    params.projectFilter.brandId = {
      $in: brandIds,
    };
  }

  if (filter.category?.length) {
    const categoryIds = filter.category.map((item) => item.value._id);
    params.projectFilter.categoryId = {
      $in: categoryIds,
    };
  }

  // delete projectFilter if empty
  if (Object.keys(params.projectFilter).length === 0) {
    delete params.projectFilter;
  }

  return params;
};

const containerStyle = {
  height: "calc(100vh - 300px)",
  overflowX: "auto",
};

const tableStyle = {
  width: "1600px",
};

const breadcrumbs = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Newly Launched",
  },
];

const tdStyles = {
  stickyRight: {
    position: "sticky",
    right: 0,
    backgroundColor: "#fff",
    zIndex: 98,
  },
};

// Add this function to fetch previous suggestions
const fetchPreviousSuggestions = async (dealIds, fid) => {
  const previousSuggestResponse = await PosService.getPurchaseBaskets({
    page: 1,
    count: dealIds.length,
    filter: {
      "dealInfo.id": { $in: dealIds },
      franchiseId: fid,
      status: "Open",
    },
  });

  return previousSuggestResponse.resp || [];
};

const NewlyLaunched = () => {
  const navigate = useNavigate();

  const [deals, setDeals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [busyLoading, setBusyLoading] = useState(false);
  const [recordsCount, setRecordsCount] = useState({
    loading: false,
    count: 0,
  });

  const [searchParams] = useSearchParams();
  const queryParams = useFetchUrlQueryString(searchParams);
  const storeId = queryParams.fid;

  const filterRef = useRef({
    search: "",
    menu: null,
    brand: null,
    category: null,
    sellerId: "",
    fid: "",
    whIds: [],
  });

  const paginationRef = useRef({ ...defaultPaginationConfig });

  const [basketModal, setBasketModal] = useState({
    show: false,
    dealId: null,
    useDealInfo: null,
  });

  const handleAddToBasket = async (deal) => {
    setBusyLoading(true);
    try {
      const response = await DealService.getDetailsById(deal._id);
      const dealDetails = response.resp;

      const dealInfo = {
        _id: dealDetails._id,
        name: dealDetails.name,
        menuId: dealDetails.menuId,
        menuName: dealDetails.menuName,
        categoryId: dealDetails.categoryId,
        categoryName: dealDetails.categoryName,
        brandId: dealDetails.brandId,
        brandName: dealDetails.brandName,
        mrp: deal.mrp || dealDetails.mrp,
        b2bprice: deal.price || dealDetails.b2bPrice,
        images: deal.images || dealDetails.images,
        lastOrderDate: deal.lastOrderDate || dealDetails.lastOrderDate,
        lastOrderQty: deal.lastOrderQty || dealDetails.lastOrderQty,
        _qty: deal.qty || dealDetails._qty,
        sellInLooseQty: dealDetails.sellInLooseQty,
        caseQty: dealDetails.caseQty,
        innerPackQty: dealDetails.innerPackQty,
        salesData: dealDetails.salesData,
      };

      setBasketModal({
        show: true,
        dealId: dealDetails._id,
        useDealInfo: dealInfo,
      });
    } catch (error) {
      console.error("Error fetching deal details:", error);
    } finally {
      setBusyLoading(false);
    }
  };

  const handleBasketModalCallback = (data) => {
    if (data.action === "addToBasket") {
      Toaster.success(
        `Added ${data.purchaseDetails.quantity} ${data.purchaseDetails.dealInfo.name} to basket`
      );
      setBasketModal({ show: false, dealId: null });
    } else if (data.action === "close") {
      setBasketModal({ show: false, dealId: null });
    }
  };

  useEffect(() => {
    const fetchFranchiseData = async () => {
      try {
        const response = await FranchiseService.getFcList(storeId);
        const fcList = response.resp?.fcList;
        const fcIds = fcList?.map((fc) => fc.whId);
        const franchise = await FranchiseService.getFranchise(storeId, {
          select: "name,sk_franchise_details",
        });
        filterRef.current = {
          ...filterRef.current,
          fid: franchise.resp?._id,
          whIds: fcIds,
        };

        applyFilter();
      } catch (error) {
        console.error("Error fetching franchise data:", error);
      }
    };

    if (storeId) {
      fetchFranchiseData();
    }
  }, [applyFilter, storeId]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = {
      ...defaultPaginationConfig,
    };

    const params = prepareFilterParams(
      filterRef.current,
      paginationRef.current
    );

    setRecordsCount({ loading: true, count: 0 });
    const { count } = await getCount(params);
    setRecordsCount({ loading: false, count });
    paginationRef.current.totalRecords = count;

    const { data } = await getData(params);
    const dealIds = data.map((deal) => deal._id); // Collect deal IDs

    // Fetch previous suggestions
    const previousSuggestions = await fetchPreviousSuggestions(
      dealIds,
      filterRef.current.fid
    );

    // Attach previous suggestions to each deal
    const updatedDeals = data.map((item) => {
      const suggestItem = previousSuggestions.find(
        (suggest) => suggest.dealInfo.id === item._id
      );
      return {
        ...item,
        previousSuggest: suggestItem ? suggestItem : null, // Attach previousSuggest if exists
      };
    });

    setDeals(updatedDeals);
    setLoading(false);
  }, []);

  const onPaginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    applyFilter();
    CommonService.scrollToView();
  };

  const onFilterCb = (data) => {
    console.log("Filter Data:", data);
    if (data.action === "apply") {
      filterRef.current = {
        ...filterRef.current,
        ...data.formData,
      };
      applyFilter();
    }
  };

  const handleFilterClick = (type, value) => {
    let params = {};

    if (type == "menu") {
      params.menu = value.menuId;
    } else {
      params[type] = [
        { label: value[type + "Name"], value: { _id: value[type + "Id"] } },
      ];
    }

    filterRef.current = {
      ...filterRef.current,
      ...params,
    };

    applyFilter();
  };

  return (
    <>
      <PageInfo
        title="Newly Launched Products"
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />

      <div className="text-muted fs-val-sm mb-3">
        <i className="bi bi-info-circle me-1"></i>
        This list shows all the deals that have been newly launched in StoreKing
        but have not been purchased by the store.
      </div>

      <StoreCard
        fid={storeId}
        navigate={navigate}
        rbacKey="InventoryAgeingStoreDashboard"
      />
      <AppCard>
        <PosStoreNewlyLaunchedFilter
          storeId={storeId}
          callback={onFilterCb}
          filterData={filterRef.current}
        />
      </AppCard>
      <AppCard>
        <PaginationSummary
          loadingTotalRecords={recordsCount.loading}
          paginationConfig={paginationRef.current}
          className="mb-3"
        />
        <div
          className="tbl-scroll-container custom-scrollbar thin-scrollbar fixed-width-table"
          style={containerStyle}
        >
          <table
            className="table table-sm mb-0 bg-white table-hover"
            style={tableStyle}
          >
            <TableHeader data={headerData} noBg isSticky={true} />
            <tbody className="fs-val-md">
              {loading ? (
                <TableSkeletonLoader cols={headerData.length} />
              ) : null}

              {!loading && deals.length === 0 ? (
                <tr>
                  <td colSpan={headerData.length} className="text-center">
                    <NoDataFound>No Data Found</NoDataFound>
                  </td>
                </tr>
              ) : null}

              {deals.map((deal, index) => (
                <tr key={index}>
                  <td className="text-center">
                    {paginationRef.current.startSlNo + index}
                  </td>
                  <td>
                    <div className="text-wrap">{deal.name}</div>
                  </td>
                  <td>{deal._id}</td>
                  <td>
                    <Amount
                      value={deal.mrp || 0}
                      decimalPlace={2}
                      hideSymbol={true}
                    />
                  </td>
                  <td>
                    <Amount
                      value={deal.price || 0}
                      decimalPlace={2}
                      hideSymbol={true}
                    />
                  </td>
                  <td>
                    {CommonService.roundedByDecimalPlace(deal.margin || 0, 2)}
                  </td>
                  <td>
                    <span
                      className="btn btn-link text-dark text-start fs-val-md p-0 text-wrap"
                      onClick={() => handleFilterClick("menu", deal)}
                    >
                      {deal.menuName}
                    </span>
                  </td>
                  <td>
                    <span
                      className="btn btn-link text-dark text-start fs-val-md p-0 text-wrap"
                      onClick={() => handleFilterClick("brand", deal)}
                    >
                      {deal.brandName}
                    </span>
                  </td>
                  <td>
                    <span
                      className="btn btn-link text-dark text-start fs-val-md p-0 text-wrap"
                      onClick={() => handleFilterClick("category", deal)}
                    >
                      {deal.categoryName}
                    </span>
                  </td>
                  <td style={tdStyles.stickyRight}>
                    {deal.previousSuggest ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <span className="me-1 text-muted">Added</span>
                        <InfoPopover
                          header="Added to Basket"
                          content={
                            <div>
                              <strong>Suggested by:</strong>{" "}
                              {deal.previousSuggest.suggestedBy.name}
                              <br />
                              <strong>Quantity:</strong>{" "}
                              {deal.previousSuggest.quantity}
                              <br />
                              <strong>Date:</strong>{" "}
                              <DateFormatter
                                date={deal.previousSuggest.createdAt}
                                format="dd MMM yyyy"
                              />
                            </div>
                          }
                          placement="top"
                        >
                          <span className="text-success">
                            <i className="bi bi-info-circle"></i>
                          </span>
                        </InfoPopover>
                      </div>
                    ) : (
                      <button
                        className="btn btn-outline-primary btn-sm rounded-pill px-3 fs-val-sm"
                        onClick={() => handleAddToBasket(deal)}
                      >
                        <i className="bi bi-cart-plus me-1"></i>
                        Add
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <PaginationBlock
          loadingTotalRecords={recordsCount.loading}
          paginationCb={onPaginationCb}
          size="sm"
          paginationConfig={paginationRef.current}
        />
      </AppCard>
      <AddToPurchaseBasketModal
        show={basketModal.show}
        dealId={basketModal.dealId}
        storeId={storeId}
        callback={handleBasketModalCallback}
        useDealInfo={basketModal.useDealInfo}
        hideStockSalesData={true}
      />
      <BusyLoader show={busyLoading} />
    </>
  );
};

export default NewlyLaunched;
