import { PageNotFound, ProtectedRoute } from "@sk/uis";
import { memo } from "react";
import { createHashRouter, Navigate, RouterProvider } from "react-router-dom";
import Layout from "./pages/Layout";
import PosMain from "./pages/main/PosMain";
import PosStore from "./pages/store/PosStore";
import PosSalesAnalytics from "./pages/sales-analytics/PosSalesAnalytics";
import StoreDealView from "./pages/deal/StoreDealView";
import PosOrderDetail from "./pages/order-detail/PosOrderDetail";
import ClubRequestDetail from "./pages/request-detail/ClubRequestDetail";
import PurchaseBasket from "./pages/purchase-basket/PurchaseBasket";
import PosRackBin from "./pages/rack-bin/PosRackBin";
import NewlyLaunched from "./pages/newly-launched/NewlyLaunched";

const roles = {
  pos: ["ViewPos"],
  inventory: ["InventoryAgeingStoreDashboard"],
  sales: ["DarkStoreSalesDashboard"],
};

const router = createHashRouter([
  {
    path: "/",
    errorElement: <PageNotFound />,
    children: [
      {
        path: "pos",
        element: <Layout />,
        children: [
          {
            path: "",
            element: <Navigate to="/pos/main"></Navigate>,
          },
          {
            path: "main",
            element: <PosMain />,
          },
          {
            path: "inventory",
            element: (
              <ProtectedRoute roles={roles.inventory}>
                <PosStore />
              </ProtectedRoute>
            ),
          },
          {
            path: "sales-analytics",
            element: (
              <ProtectedRoute roles={roles.sales}>
                <PosSalesAnalytics />
              </ProtectedRoute>
            ),
          },
          {
            path: "store/deal/view",
            element: (
              <ProtectedRoute roles={roles.inventory}>
                <StoreDealView />
              </ProtectedRoute>
            ),
          },
          {
            path: "order/view",
            element: (
              <ProtectedRoute roles={roles.inventory}>
                <PosOrderDetail />
              </ProtectedRoute>
            ),
          },
          {
            path: "request/view",
            element: (
              <ProtectedRoute roles={roles.inventory}>
                <ClubRequestDetail />
              </ProtectedRoute>
            ),
          },
          {
            path: "purchase-basket",
            element: (
              <ProtectedRoute roles={roles.inventory}>
                <PurchaseBasket />
              </ProtectedRoute>
            ),
          },
          {
            path: "rack-bin",
            element: (
              <ProtectedRoute roles={roles.inventory}>
                <PosRackBin />
              </ProtectedRoute>
            ),
          },
          {
            path: "newly-launched",
            element: (
              <ProtectedRoute roles={roles.inventory}>
                <NewlyLaunched />
              </ProtectedRoute>
            ),
          },
        ],
      },
    ],
  },
]);

const Router = () => {
  return <RouterProvider router={router} />;
};

export default memo(Router);
