import { CommonService, NavService, PosService } from "@sk/services";
import { useCallback, useEffect, useRef, useState } from "react";
import { endOfDay, startOfDay, startOfMonth } from "date-fns";
import {
  Amount,
  TableHeader,
  NoDataFound,
  TableSkeletonLoader,
  PaginationBlock,
  AppCard,
  DateInput,
  TextInput,
  DateFormatter,
  PaginationSummary,
  BusyLoader,
  Toaster,
  BtnLink,
} from "@sk/uis";
import PosCustomerAnalyticsSummary from "./PosCustomerAnalyticsSummary";
import { Controller, useForm } from "react-hook-form";
import { debounce } from "lodash";
import produce from "immer";

const defaultSummaryData = [
  {
    title: "Total Customers",
    value: 0,
    template: 1,
    valueColor: "success",
    titleColor: "dark",
    valuePath: "customers",
    loading: true,
  },
  {
    title: "KingCoins Rewarded",
    value: 0,
    template: 2,
    valueColor: "primary",
    titleColor: "dark",
    valuePath: "totalSmartCoinsRewarded",
    loading: true,
  },
  {
    title: "KingCoins Redeemed",
    value: 0,
    template: 1,
    valueColor: "warning",
    titleColor: "dark",
    valuePath: "totalSmartCoinsRedeemed",
    loading: true,
  },
  {
    title: "Total Sales",
    value: 0,
    template: 2,
    valueColor: "danger",
    titleColor: "dark",
    valuePath: "totalSales",
    loading: true,
    isValueAmt: true,
  },
  {
    title: "Total Orders",
    value: 0,
    template: 1,
    valueColor: "info",
    titleColor: "dark",
    valuePath: "totalOrderCount",
    loading: true,
  },
  {
    title: "Total Unique Deals",
    value: 0,
    template: 2,
    valueColor: "secondary",
    titleColor: "dark",
    valuePath: "totalUniqueDeals",
    loading: true,
  },
];

const headerData = [
  { key: "slNo", label: "Sl No", width: "5%" },
  {
    key: "customerInfo.name",
    label: "Customer",
    width: "15%",
    enableSort: true,
  },
  {
    key: "totalOrderCount",
    label: "Total Orders",
    width: "10%",
    enableSort: true,
  },
  {
    key: "totalUniqueDeals",
    label: "Unique Deals",
    width: "10%",
    enableSort: true,
  },
  {
    key: "totalSales",
    label: "Order Value",
    width: "10%",
    enableSort: true,
  },
  {
    key: "contribution",
    label: "Sales Contribution",
    width: "12%",
    enableSort: true,
  },
  {
    key: "latestOrderDate",
    label: "Last Ordered Date",
    width: "12%",
    enableSort: true,
  },
  {
    key: "totalSmartCoinsRewarded",
    label: "KingCoins Earned",
    width: "10%",
    enableSort: true,
    isCentered: true,
  },
  {
    key: "totalSmartCoinsRedeemed",
    label: "KingCoins Redeemed",
    width: "12%",
    enableSort: true,
    isCentered: true,
  },
];

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const getData = async (params) => {
  const r = await PosService.getCustomerWiseOrders(params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const getCount = async (params) => {
  let p = {
    ...params,
    outputType: "count",
  };
  const r = await PosService.getCustomerWiseOrders(p);
  return {
    count: Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0]?.total : 0,
  };
};

const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {},
    franchise: filter.storeId,
    sort: { [sort.key]: sort.value === "asc" ? 1 : -1 },
  };

  if (filter.search) {
    const search = filter.search.trim();
    const searchRegex = {
      $regex: search,
      $options: "gi",
    };

    if (search) {
      p.filter.$or = [
        {
          "customerInfo.id": search,
        },
        {
          "customerInfo.name": searchRegex,
        },
        {
          "customerInfo.mobile": searchRegex,
        },
      ];
    }
  }

  if (filter.date?.length) {
    p.filter.createdAt = {
      $gte: startOfDay(filter.date[0]),
      $lte: endOfDay(filter.date[1]),
    };
  }

  return p;
};

const PosCustomerAnalytics = ({ storeId, callback }) => {
  const { control, register } = useForm({
    defaultValues: {
      date: [startOfMonth(new Date()), new Date()],
      search: "",
    },
  });

  const [summary, setSummary] = useState([...defaultSummaryData]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [recordCount, setRecordCount] = useState({ loading: false, count: 0 });
  const [busyLoader, setBusyLoader] = useState({ show: false });

  const paginationRef = useRef({ ...defaultPaginationConfig });
  const filterDataRef = useRef({
    date: [startOfMonth(new Date()), new Date()],
    storeId: storeId,
    type: "default",
  });
  const sortRef = useRef({ key: "customerInfo.name", value: "asc" });

  const loadList = useCallback(async (totalSales = 0) => {
    setLoading(true);
    setData([]);

    const r = await getData(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      )
    );
    setData(
      r.data.map((x) => {
        x._contribution = CommonService.roundedByDecimalPlace(
          (x.totalSales / totalSales) * 100,
          2
        );
        return x;
      })
    );
    setLoading(false);
  }, []);

  const loadSummary = useCallback(async (total = 0) => {
    const params = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortRef.current
    );
    params.groupBy = { franchiseId: "$franchiseId" };

    setSummary(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );

    const r = await getData(params);
    const s = r.data.length > 0 ? r.data[0] : {};
    s.customers = total;

    setSummary(
      produce((draft) => {
        draft.forEach((x) => {
          x.value = s[x.valuePath] || 0;
          x.loading = false;
        });
      })
    );

    return { summary: s };
  }, []);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    setRecordCount({ loading: true, count: 0 });
    const r = await getCount(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      )
    );
    setRecordCount({ loading: false, count: r.count });
    paginationRef.current.totalRecords = r.count;

    const s = await loadSummary(paginationRef.current.totalRecords);

    loadList(s.summary.totalSales);
  }, [loadList, loadSummary]);

  const init = useCallback(async () => {
    await applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    filterDataRef.current.storeId = storeId;
    init();
  }, [init, storeId]);

  const onPaginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList(summary.find((x) => x.valuePath === "totalSales")?.value || 0);
  };

  const onDateChange = (chngFn) => (data) => {
    chngFn(data);
    filterDataRef.current.date = data;
    applyFilter();
  };

  const onSearchChange = useCallback(
    debounce((e) => {
      filterDataRef.current.search = e.target.value;
      applyFilter();
    }, 500),
    [applyFilter]
  );

  const sortCb = (sort) => {
    sortRef.current = sort;
    applyFilter();
  };

  const onDownload = async () => {
    const params = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortRef.current
    );

    delete params.count;
    delete params.page;
    delete params.sort;

    setBusyLoader({ show: true });
    const r = await PosService.getCustomerWiseOrders({
      ...params,
      outputType: "csv",
    });
    setBusyLoader({ show: false });

    if (r.resp?.downloadLink) {
      const url = r.resp.downloadLink;
      if (url) {
        CommonService.downloadAsset(url);
        Toaster.success("Data downloaded successfully");
      } else {
        Toaster.error("Failed to download data");
      }
    } else {
      Toaster.error("Failed to download data");
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-3">
          <TextInput
            placeholder="Search"
            name="search"
            callback={onSearchChange}
            register={register}
          />
        </div>
        <div className="col-3">
          <Controller
            control={control}
            name="date"
            render={({ field }) => (
              <DateInput
                callback={onDateChange(field.onChange)}
                value={field.value}
                hideClose={true}
                template={2}
              />
            )}
          />
        </div>
        <div className="col text-end">
          <button
            className="btn btn-sm btn-outline-primary"
            onClick={onDownload}
          >
            <i className="bi bi-download me-1" />
            Download
          </button>
        </div>
      </div>
      <PosCustomerAnalyticsSummary summaryData={summary} />

      <AppCard>
        <PaginationSummary
          loadingTotalRecords={recordCount.loading}
          paginationConfig={paginationRef.current}
          className="mb-2"
        />
        <table className="table table-sm table-striped">
          <TableHeader
            data={headerData}
            noBg
            sortCb={sortCb}
            sort={sortRef.current}
          />
          <tbody className="fs-val-md">
            {loading ? (
              <TableSkeletonLoader cols={headerData.length} rows={5} />
            ) : null}

            {!loading && !data.length ? (
              <tr>
                <td colSpan={headerData.length}>
                  <NoDataFound>No data found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {data.map((item, index) => (
              <tr key={index}>
                <td>{paginationRef.current.startSlNo + index}</td>
                <td>
                  <BtnLink
                    className="fs-val-md"
                    onClick={() =>
                      NavService.openInNewTab("/users/customer/view", {
                        id: item.customerInfo.id,
                      })
                    }
                  >
                    {item.customerInfo.name}
                  </BtnLink>
                  <div className="text-muted fs-val-sm">
                    {item.customerInfo.district}, {item.customerInfo.state} -{" "}
                    {item.customerInfo.pincode}
                  </div>
                </td>
                <td>{item.totalOrderCount}</td>
                <td>{item.totalUniqueDeals}</td>
                <td>
                  <Amount value={item.totalSales} />
                </td>
                <td>{item._contribution}%</td>
                <td>
                  <DateFormatter
                    date={item.latestOrderDate}
                    format="dd MMM yyyy"
                  />
                  <div className="text-muted fs-val-sm">
                    <DateFormatter
                      date={item.latestOrderDate}
                      format="hh:mm a"
                    />
                  </div>
                </td>
                <td className="text-center text-success">
                  {item.totalSmartCoinsRewarded}
                </td>
                <td className="text-center text-danger">
                  {item.totalSmartCoinsRedeemed}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="text-end">
          <PaginationBlock
            loadingTotalRecords={recordCount.loading}
            totalRecords={recordCount.count}
            size="sm"
            paginationConfig={paginationRef.current}
            paginationCb={onPaginationCb}
          />
        </div>
      </AppCard>

      <BusyLoader show={busyLoader.show} />
    </>
  );
};

export default PosCustomerAnalytics;
