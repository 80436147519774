import React from "react";
import {
  Amount,
  DateFormatter,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { NavService } from "@sk/services";

const headers = [
  { label: "SL No", width: "5%" },
  { label: "Warehouse", width: "16%" },
  { label: "Ordered On", width: "8%" }, // Removed enableSort
  { label: "Order Time", width: "6%" }, // New column
  { label: "Order ID", width: "10%" },
  { label: "Sold By", width: "10%" },
  { label: "Order Amount (Rs.)", width: "10%" },
  { label: "Total Items", width: "6%" }, // New column
  { label: "Status", width: "10%" },
  { label: "Stock Allocation", width: "10%" },
  { label: "Payment Mode", width: "8%" },
  { label: "Payment Status", width: "8%" },
  { label: "PDP Date", width: "10%" },
  { label: "Action", width: "5%" },
];

const containerStyle = {
  maxHeight: "calc(100vh - 300px)",
  overflowY: "auto",
};

const tableStyle = {
  minWidth: "2000px",
};

const StoreDealPurchaseTable = ({
  data,
  loading,
  paginationConfig,
  paginationCb,
  loadingTotalRecords,
}) => {
  const viewOrder = (orderId) => {
    NavService.openInNewTab(`/oms/orders/details?id=${orderId}`);
  };

  return (
    <>
      <PaginationSummary
        loadingTotalRecords={loadingTotalRecords}
        paginationConfig={paginationConfig}
        className="mb-3"
      />
      <div
        className="tbl-scroll-container custom-scrollbar thin-scrollbar mb-3 fixed-width-table border rounded"
        style={containerStyle}
      >
        <table
          className="table table-sm bg-white table-hover"
          style={tableStyle}
        >
          <TableHeader data={headers} isSticky={true} />
          <tbody className="fs-val-md">
            {loading ? (
              <TableSkeletonLoader rows={10} cols={headers.length} />
            ) : null}

            {!loading && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>
                    <div>No orders found</div>
                  </NoDataFound>
                </td>
              </tr>
            ) : null}
            {data.map((order, index) => (
              <tr
                key={order._id}
                className="cursor-pointer"
                onClick={() => viewOrder(order._id)}
              >
                <td>{paginationConfig.startSlNo + index}</td>
                <td>
                  <div className="text-wrap">
                    {order.warehouseAddress?.name}
                  </div>
                </td>
                <td>
                  <DateFormatter date={order.createdAt} format="dd MMM yyyy" />
                </td>
                <td>
                  <DateFormatter date={order.createdAt} format="hh:mm a" />
                </td>
                <td>
                  <button className="btn btn-link p-0 fs-val-md text-dark text-start">
                    {order._id}
                  </button>
                </td>
                <td>{order.fulfilledBy}</td>
                <td>
                  <Amount value={order.orderAmount} hideSymbol={true} />
                </td>
                <td>{order.subOrders?.length} items</td>
                <td>
                  <HighlightText type={order.statusType} template={2}>
                    {order.status}
                  </HighlightText>
                </td>
                <td className="text-uppercase">{order.stockAllocation}</td>
                <td>{order.paymentMode}</td>
                <td>{order.paymentStatus}</td>
                <td>
                  {order.pdpDate ? (
                    <DateFormatter
                      date={order.pdpDate}
                      format={"dd MMM yyyy"}
                    />
                  ) : (
                    "--"
                  )}
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-sm btn-outline-primary fs-val-sm me-2"
                  >
                    View
                  </button>
                  {/* Add more actions as needed */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <PaginationBlock
        paginationConfig={paginationConfig}
        paginationCb={paginationCb}
        loadingTotalRecords={loadingTotalRecords}
        size="sm"
      />
    </>
  );
};

export default StoreDealPurchaseTable;
