import {
  StockLedgerDetailModal,
  StockLedgerModal,
  AddToPurchaseBasketModal,
} from "@sk/features";
import { CommonService, NavService, PosService } from "@sk/services";
import {
  Amount,
  BtnLink,
  BusyLoader,
  DateFormatter,
  InfoPopover,
  NoDataFound,
  TableHeader,
  TableSkeletonLoader,
  Toaster,
} from "@sk/uis";
import { orderBy } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import PosPriceSlabModal from "../../modals/PosPriceSlabModal";
import PosStoreFilter from "./PosStoreFilter";
import produce from "immer";
import PosRspLogModal from "../modals/PosRspLogModal";

const tableStyles = {
  minWidth: "2300px",
  position: "relative",
};

const circleStyles = {
  width: "10px",
  height: "10px",
  borderRadius: "50%",
  backgroundColor: "orange",
  display: "inline-block",
  marginRight: "5px",
};

const tdStyles = {
  stock: {
    backgroundColor: "rgb(243 255 244)", // Light cyan background for stock
  },
  lastPurchased: {
    backgroundColor: "#fff6e7", // Light orange background for last purchased
  },
  sales: {
    backgroundColor: "#ffffef", // Light green background for sold in last 30
  },
  stickyLeft: {
    position: "sticky",
    left: 0,
    backgroundColor: "#fff",
    zIndex: 99,
  },
  stickyRight: {
    position: "sticky",
    right: 0,
    backgroundColor: "#fff",
    zIndex: 98,
  },
  pnl: {
    backgroundColor: "#e0f7fa",
  },
};

const tableContainerStyles = {
  maxHeight: "500px",
  overflowY: "auto",
};

const defaultPagination = {
  activePage: 1,
  rowsPerPage: 50,
  totalRecords: 0,
  startSlNo: 1,
  endSlNo: 50,
};

const getData = async (params, type, signal) => {
  let dealIdsFromAnalytics = [];
  let dealsData = [];
  let onlyAnalyticsData = false;
  let rawResponse = null;

  let p = { ...params };

  if (type === "topMoving" || type === "topPurchases") {
    const response = await PosService.getTopSellingPosStock(p, signal);
    if (params.showDealIdOnly) {
      dealIdsFromAnalytics = response.resp?.[0]?.dealIds || [];
    } else {
      dealIdsFromAnalytics = (response.resp || []).map((item) => item._id);
    }
    onlyAnalyticsData = true;
  }

  delete p.sellerId;

  if (onlyAnalyticsData) {
    const analyticsParams = {
      page: 1,
      count: dealIdsFromAnalytics.length,
      filter: {
        _id: params.filter._id,
      },
      dealFilter: { _id: { $in: dealIdsFromAnalytics } },
    };

    if (params.showDealIdOnly) {
      analyticsParams.outputType = "csv";
    }

    const response = await PosService.getDealsStock(analyticsParams, signal);
    rawResponse = response.resp;
    dealsData = Array.isArray(response.resp)
      ? dealIdsFromAnalytics.map((id) =>
          response.resp.find((item) => item._id === id)
        )
      : [];
  } else {
    const response = await PosService.getDealsStock(p, signal);
    rawResponse = response.resp;
    dealsData = Array.isArray(response.resp) ? response.resp : [];
  }

  // Collect all _id values
  const dealIds = dealsData.map((item) => item._id);

  let updatedData = dealsData; // Default to original data

  // Fetch deal location details if dealIds length is greater than 0
  if (dealIds.length > 0 && params.outputType !== "csv") {
    const locationResponse = await PosService.getDealLocation(dealIds, {
      page: 1,
      count: 300,
      filter: {
        "sellerInfo._id": params.sellerId,
      },
      signal, // Pass the signal to the service
    });
    const locationData = locationResponse.resp || [];

    // Update the original data with location details
    updatedData = dealsData.map((item) => {
      const locationInfo = orderBy(locationData, "locationName", "desc").find(
        (loc) => loc.dealId === item._id
      );
      if (locationInfo) {
        return {
          ...item,
          locationName: locationInfo.locationName,
          rackName: locationInfo.locationRackName,
          binName: locationInfo.locationBinName,
        };
      }
      return item; // Return original item if no match found
    });
  }

  // Fetch previous suggestions
  const previousSuggestResponse = await PosService.getPurchaseBaskets({
    page: 1,
    count: dealIds.length,
    filter: {
      "dealInfo.id": { $in: dealIds },
      franchiseId: params.filter._id,
      status: "Open",
    },
  });

  const previousSuggest = previousSuggestResponse.resp || [];

  // Attach previousSuggest to each dealData
  updatedData = updatedData.map((item) => {
    const suggestItem = previousSuggest.find(
      (suggest) => suggest.dealInfo.id === item._id
    );
    return {
      ...item,
      previousSuggest: suggestItem ? suggestItem : null, // Attach previousSuggest if exists
    };
  });

  return { data: updatedData, rawResponse };
};

const getCount = async (params, type, signal) => {
  let p = { ...params, showCount: true };

  delete p.page;
  delete p.count;

  // Add logic for type similar to getData
  if (type === "topMoving" || type === "topPurchases") {
    const response = await PosService.getTopSellingPosStock(
      { ...params, outputType: "count" },
      signal
    );

    return {
      count:
        Array.isArray(response.resp) && response.resp.length > 0
          ? response.resp[0].total
          : 0,
    };
  } else {
    const r = await PosService.getDealsStock(p, signal); // Pass the signal to the service
    return {
      count:
        Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0].totalSku : 0,
    };
  }
};

const prepareFilterParams = (filter, pagination, sort) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    dealFilter: {},
    inventoryType: "Available",
    filter: {
      _id: filter.storeId,
    },
    sellerId: filter.sellerId,
  };

  // Map the tab keys to stockType values
  const stockTypeMapping = {
    slowMoving: "slowMoving",
    nonMoving: "unSoldDeals",
    outOfStock: "NotAvailable", // Ensure this matches your backend logic
  };

  // Include the stockType key from the filter
  if (filter.stockType && stockTypeMapping[filter.stockType]) {
    p.stockType = stockTypeMapping[filter.stockType]; // Add stockType to params
  }

  // Check if selectedIds and tabKey are present
  if (filter.category?.length > 0) {
    const categoryIds = filter.category.map((item) => item.value?._id);
    if (
      filter.stockType === "topMoving" ||
      filter.stockType === "topPurchases"
    ) {
      p.dealFilter = {
        category: { $in: categoryIds },
      };
    } else {
      p.categoryFilter = {
        categoryId: { $in: categoryIds },
      };
    }
  }

  if (filter.brand?.length > 0) {
    const brandIds = filter.brand.map((item) => item.value?._id);
    if (
      filter.stockType === "topMoving" ||
      filter.stockType === "topPurchases"
    ) {
      p.dealFilter = {
        brand: { $in: brandIds },
      };
    } else {
      p.brandFilter = {
        brandId: { $in: brandIds },
      };
    }
  }

  if (filter.menu?.length > 0) {
    const menuIds = filter.menu.map((item) => item.value?._id);
    if (
      filter.stockType === "topMoving" ||
      filter.stockType === "topPurchases"
    ) {
      p.dealFilter = {
        menu: { $in: menuIds },
      };
    } else {
      p.menuFilter = {
        menuId: { $in: menuIds },
      };
    }
  }

  if (filter.showInStockOnly && filter.type !== "outOfStock") {
    p.inventoryType = "Available";
  }

  if (!filter.showInStockOnly) {
    delete p.inventoryType;
  }

  if (filter.type === "all") {
    delete p.inventoryType;
  } else if (filter.type === "purchasedFromSk") {
    p.dealFilter.isOwnedByThirdparty = false;
  } else if (filter.type === "notConfiguredRSP") {
    p.type = "notConfiguredRSP";
  } else if (filter.type === "kingSlab") {
    p.kingSlabDealsOnly = true;
  } else if (filter.type === "looseDeals") {
    p.looseDealsOnly = true;
  } else if (filter.type === "reserveEnabled") {
    p.reserveConfig = { $exists: true };
  } else if (filter.type === "outOfStock") {
    // Handle the new out of stock option
    p.inventoryType = "NotAvailable"; // Adjust this based on your backend logic
  }

  if (filter.search) {
    p.dealFilter = {
      $or: [
        { name: { $regex: filter.search, $options: "i" } },
        { _id: filter.search },
        { productId: { $in: [filter.search] } },
      ],
    };
  }

  if (sort.key) {
    p.dealsort = {
      [sort.key]: sort.value == "asc" ? 1 : -1,
    };
  }

  if (filter.download) {
    if (
      filter.stockType === "topMoving" ||
      filter.stockType === "topPurchases"
    ) {
      p.outputType = "list";
      p.showDealIdOnly = true;
    } else {
      p.outputType = "csv";
    }
  }

  if (filter.stockType === "topMoving" || filter.stockType === "topPurchases") {
    p.dealsort = {
      totalUnits: -1,
    };
  }

  if (Object.keys(p.dealFilter).length === 0) {
    delete p.dealFilter;
  }

  return p;
};

const PosStoreTable = ({ type, storeId, storeName, sellerId }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [headers, setHeaders] = useState([]);
  const [busyLoader, setBusyLoader] = useState({ show: false, message: "" });

  const [priceSlabModal, setPriceSlabModal] = useState({
    show: false,
    slabData: [],
  });
  const [stockLedgerModal, setStockLedgerModal] = useState({
    show: false,
    storeId: null,
    dealId: null,
  });
  const [ledgerDetailModal, setLedgerDetailModal] = useState({
    show: false,
    ledgerId: "",
  });
  const [rspLogModal, setRspLogModal] = useState({
    show: false,
    dealId: null,
  });

  const filterDataRef = useRef({
    showInStockOnly: true,
  });
  const paginationRef = useRef(defaultPagination);
  const sortRef = useRef({ key: "name", value: "asc" });
  const abortControllerRef = useRef(new AbortController());

  const [basketModal, setBasketModal] = useState({
    show: false,
    dealId: null,
  });

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPagination };

    loadList();

    const r = await getCount(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      ),
      filterDataRef.current.stockType,
      abortControllerRef.current.signal
    );
    paginationRef.current.totalRecords = r.count;
  }, []);

  const loadList = async () => {
    setLoading(true);
    setData([]);
    const r = await getData(
      prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      ),
      filterDataRef.current.stockType,
      abortControllerRef.current.signal
    );
    setData(r.data);
    setHasMoreData(r.data.length >= paginationRef.current.rowsPerPage);
    setLoading(false);
  };

  const loadMore = async () => {
    setLoadingMore(true);
    paginationRef.current.activePage++;
    try {
      const params = prepareFilterParams(
        filterDataRef.current,
        paginationRef.current,
        sortRef.current
      );
      const fetchedData = await getData(
        params,
        filterDataRef.current.stockType,
        abortControllerRef.current.signal
      );
      setData((prevData) => [...prevData, ...fetchedData.data]);
      setHasMoreData(
        fetchedData.data.length >= paginationRef.current.rowsPerPage
      );
    } catch (error) {
      console.error("Error fetching more data:", error);
    } finally {
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    setHeaders(getHeaders(type));

    filterDataRef.current = {
      ...filterDataRef.current,
      stockType: type,
      storeId,
      sellerId,
    };
    applyFilter();

    // Cleanup function to abort ongoing requests
    return () => {
      abortControllerRef.current.abort(); // Abort the ongoing requests
      abortControllerRef.current = new AbortController(); // Create a new instance for future requests
    };
  }, [applyFilter, type, storeId, sellerId]);

  const sortCb = (data) => {
    sortRef.current = data;
    applyFilter();
  };

  const filterCb = async (data) => {
    filterDataRef.current = {
      ...filterDataRef.current,
      ...data.formData,
      selectedItems: data.selectedItems,
      selectedMenu: data.selectedMenu, // Add selectedMenu to filterDataRef
    };

    filterDataRef.current.download = false;
    applyFilter();
  };

  const download = async () => {
    filterDataRef.current.download = true;
    setBusyLoader({ show: true, message: "Downloading data..." });
    let params = prepareFilterParams(
      filterDataRef.current,
      paginationRef.current,
      sortRef.current
    );
    params.count = 100000000000000;
    const r = await getData(
      params,
      filterDataRef.current.stockType,
      abortControllerRef.current.signal
    );
    setBusyLoader({ show: false, message: "" });
    if (r.rawResponse) {
      CommonService.downloadAsset(r.rawResponse?.downloadLink);
    } else {
      Toaster.error("Failed to download CSV");
    }
  };

  const showPriceSlabModal = (item) => {
    setPriceSlabModal({
      show: true,
      slabData: item.posPriceSlab,
      dealName: item.name,
      dealId: item._id,
      mrp: item.mrp,
    });
  };

  const showStockLedgerModal = (item) => {
    setStockLedgerModal({
      show: true,
      storeId: item.storeId,
      dealId: item._id,
    });
  };

  const handleStockLedgerCb = (data) => {
    if (data.action === "close") {
      setStockLedgerModal({ show: false, storeId: null, dealId: null });
    } else if (data.action === "openLedgerDetail") {
      setLedgerDetailModal({
        show: true,
        ledgerId: data.ledgerId,
        dealId: stockLedgerModal.dealId,
      });
    }
  };

  const handleLedgerDetailCallback = (data) => {
    if (data.action === "close") {
      setLedgerDetailModal({
        show: false,
        ledgerId: "",
      });
    }
  };

  const onLinkClick = (type, item) => {
    if (type === "menu") {
      const input = {
        label: item.menuName,
        value: { _id: item.menuId, name: item.menuName },
      };
      filterDataRef.current = {
        ...filterDataRef.current,
        menu: [input],
      };
      applyFilter();
    }

    if (type === "category") {
      const input = {
        label: item.categoryName,
        value: { _id: item.categoryId, name: item.categoryName },
      };
      filterDataRef.current = {
        ...filterDataRef.current,
        category: [input],
      };
      applyFilter();
    }

    if (type === "brand") {
      const input = {
        label: item.brandName,
        value: { _id: item.brandId, name: item.brandName },
      };
      filterDataRef.current = {
        ...filterDataRef.current,
        brand: [input],
      };
      applyFilter();
    }
  };

  const handleAddToBasket = (item) => {
    setBasketModal({
      show: true,
      dealId: item._id,
    });
  };

  const handleBasketModalCallback = (data) => {
    if (data.action === "addToBasket") {
      setData(
        produce((draft) => {
          const itemToUpdate = draft.find(
            (item) => item._id === data.purchaseDetails.dealInfo.id
          );
          if (itemToUpdate) {
            itemToUpdate.previousSuggest = {
              suggestedBy: { name: data.purchaseDetails.suggestedBy.name }, // Use the name from purchaseDetails
              quantity: data.purchaseDetails.quantity,
              createdAt: data.purchaseDetails.createdAt, // Current date
            };
          }
        })
      );

      Toaster.success(
        `Added ${data.purchaseDetails.quantity} ${data.purchaseDetails.dealInfo.name} to basket`
      );
      setBasketModal({ show: false, dealId: null });
    } else if (data.action === "close") {
      setBasketModal({ show: false, dealId: null });
    }
  };

  const viewRspLog = (item) => {
    setRspLogModal({
      show: true,
      dealId: item._id,
    });
  };

  const rspLogModalCallback = () => {
    setRspLogModal({ show: false, dealId: null });
  };

  return (
    <>
      <div className="px-3 pb-3">
        <PosStoreFilter
          callback={filterCb}
          filterData={filterDataRef.current}
        />
      </div>

      <div className="px-3 border-top">
        <div className="mb-3 mt-3 d-flex justify-content-between align-items-center">
          <div className="fs-val-md text-muted">
            Showing{" "}
            <span className="fw-semibold text-dark">
              {paginationRef.current.totalRecords}
            </span>{" "}
            deals
          </div>
          <div>
            <button
              className="btn btn-sm btn-outline-primary"
              onClick={download}
            >
              <i className="bi bi-download me-1"></i>
              Download Deal Summary
            </button>
          </div>
        </div>
        <div
          className="tbl-scroll-container custom-scrollbar thin-scrollbar mb-3 border rounded-3"
          style={tableContainerStyles}
        >
          <table className="table table-sm table-hover" style={tableStyles}>
            <TableHeader
              data={headers}
              sort={sortRef.current}
              sortCb={sortCb}
              isSticky={true}
            />
            <tbody className="fs-val-md">
              {loading && (
                <TableSkeletonLoader cols={headers.length} rows={15} />
              )}

              {!loading && data.length === 0 && (
                <tr>
                  <td colSpan={headers.length}>
                    <NoDataFound>No data found</NoDataFound>
                  </td>
                </tr>
              )}

              {data.map((item, index) => (
                <tr key={item._id} className="table-row">
                  <td>{paginationRef.current.startSlNo + index}</td>
                  <td
                    className="position-sticky start-0 bg-white"
                    style={tdStyles.stickyLeft}
                  >
                    <div
                      className="text-wrap d-inline-flex align-items-center"
                      style={{ width: "350px" }}
                    >
                      {item.salesTrend && (
                        <InfoPopover
                          content="Based on 90 days of sales, the system has recommended this deal for purchase."
                          header="Sales Trend"
                        >
                          <span
                            className="animate__animated animate__pulse animate__infinite"
                            style={circleStyles}
                          ></span>
                        </InfoPopover>
                      )}
                      <BtnLink
                        className="text-dark"
                        onClick={() =>
                          NavService.openInNewTab("/pos/store/deal/view", {
                            id: item._id,
                            storeId: storeId,
                            storeName,
                          })
                        }
                      >
                        {item.name}
                      </BtnLink>
                    </div>
                  </td>
                  <td>{item._id}</td>
                  <td>
                    <Amount value={item.mrp} />
                  </td>
                  <td>
                    <Amount value={item.b2bprice} decimalPlace={2} />
                  </td>
                  <td>
                    <BtnLink
                      className="fs-val-md"
                      onClick={() => viewRspLog(item)}
                    >
                      <Amount value={item.retailerSellingPrice} />
                    </BtnLink>
                    {item.hasPriceSlab && (
                      <button
                        className="btn btn-link p-0 fs-val-xs text-dark"
                        onClick={() => showPriceSlabModal(item)}
                      >
                        Slab
                      </button>
                    )}
                  </td>
                  <td style={tdStyles.pnl}>
                    <Amount value={item._pnl.value} decimalPlace={2} />
                    <span>
                      {item._pnl.value > 0 ? (
                        <i
                          className="bi bi-arrow-up text-success"
                          title="Positive"
                        ></i>
                      ) : item._pnl.value < 0 ? (
                        <i
                          className="bi bi-arrow-down text-danger"
                          title="Negative"
                        ></i>
                      ) : (
                        <i
                          className="bi bi-dash-circle text-muted"
                          title="Neutral"
                        ></i>
                      )}
                    </span>
                  </td>
                  <td style={tdStyles.stock}>
                    <button
                      className="btn btn-link p-0 fs-val-md text-dark text-start"
                      onClick={() => showStockLedgerModal(item)}
                    >
                      {item._qty}{" "}
                      <span className="fs-val-sm">
                        {item.sellInLooseQty ? "kg" : "units"}
                      </span>
                    </button>
                  </td>

                  <td>{item.reserveConfig?.qty || 0}</td>
                  <td>{item.reserveConfig?.status || "--"}</td>

                  <td>
                    {item.lastOrderDate ? (
                      <>
                        <DateFormatter
                          date={item.lastOrderDate}
                          format="dd MMM yyyy"
                        />
                        <span>,</span>
                        <span className="ms-1 fs-val-sm">
                          {item.lastOrderQty}{" "}
                          {item.sellInLooseQty ? "kg" : "units"}
                        </span>
                      </>
                    ) : (
                      "--"
                    )}
                  </td>

                  <td style={tdStyles.lastPurchased}>
                    {item.lastInwardedDate ? (
                      <>
                        <DateFormatter
                          date={item.lastInwardedDate}
                          format="dd MMM yyyy"
                        />
                        <span>,</span>
                        <span className="ms-1 fs-val-sm">
                          {item.purchaseData?.qty || 0}{" "}
                          {item.sellInLooseQty ? "kg" : "units"}
                        </span>
                      </>
                    ) : item.subscriptionDate ? (
                      <div className="d-flex align-items-center">
                        <DateFormatter
                          date={item.subscriptionDate}
                          format="dd MMM yyyy"
                        />
                        <InfoPopover
                          header="Subscribed"
                          content={
                            <div>
                              This deal is subscribed on{" "}
                              <DateFormatter
                                date={item.subscriptionDate}
                                format="dd MMM yyyy"
                              />
                            </div>
                          }
                        >
                          <span className="bi bi-info-circle mx-1" />
                        </InfoPopover>
                      </div>
                    ) : (
                      "--"
                    )}
                  </td>

                  <td>
                    {item.lastCustomerOrderDate ? (
                      <>
                        <DateFormatter
                          date={item.lastCustomerOrderDate}
                          format="dd MMM yyyy"
                        />
                        <span>,</span>
                        <span className="ms-1 fs-val-sm">
                          {item._lastCustomerOrderQty}{" "}
                          {item.sellInLooseQty ? "kg" : "units"}
                        </span>
                      </>
                    ) : (
                      "--"
                    )}
                  </td>

                  <td style={tdStyles.sales}>
                    {item.salesData?.sevenday?._qty || 0}{" "}
                    <span className="fs-val-sm">
                      {item.sellInLooseQty ? "kg" : "units"}
                    </span>
                  </td>
                  <td style={tdStyles.sales}>
                    {item.salesData?.fifteenday?._qty || 0}{" "}
                    <span className="fs-val-sm">
                      {item.sellInLooseQty ? "kg" : "units"}
                    </span>
                  </td>
                  <td style={tdStyles.sales}>
                    {item.salesData?.thirtyday?._qty || 0}{" "}
                    <span className="fs-val-sm">
                      {item.sellInLooseQty ? "kg" : "units"}
                    </span>
                  </td>
                  <td style={tdStyles.sales}>
                    {item.salesData?.ninetyday?._qty || 0}{" "}
                    <span className="fs-val-sm">
                      {item.sellInLooseQty ? "kg" : "units"}
                    </span>
                  </td>
                  <td>
                    <button
                      className="btn btn-link p-0 fs-val-md text-dark text-start"
                      onClick={() => onLinkClick("menu", item)}
                    >
                      {item.menuName}
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-link p-0 fs-val-md text-dark text-start"
                      onClick={() => onLinkClick("category", item)}
                    >
                      {item.categoryName}
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn-link p-0 fs-val-md text-dark text-start"
                      onClick={() => onLinkClick("brand", item)}
                    >
                      {item.brandName}
                    </button>
                  </td>
                  <td>{item.locationName || "--"}</td>
                  <td>{item.rackName || "--"}</td>
                  <td>{item.binName || "--"}</td>
                  <td style={tdStyles.stickyRight}>
                    {item.previousSuggest ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <span className="me-1 text-muted">Added</span>
                        <InfoPopover
                          header="Added to Basket"
                          content={
                            <div>
                              <strong>Suggested by:</strong>{" "}
                              {item.previousSuggest.suggestedBy.name}
                              <br />
                              <strong>Quantity:</strong>{" "}
                              {item.previousSuggest.quantity}
                              <br />
                              <strong>Date:</strong>{" "}
                              <DateFormatter
                                date={item.previousSuggest.createdAt}
                                format="dd MMM yyyy"
                              />
                            </div>
                          }
                          placement="top"
                        >
                          <span className="text-success">
                            <i className="bi bi-info-circle"></i>
                          </span>
                        </InfoPopover>
                      </div>
                    ) : (
                      <button
                        className="btn btn-outline-primary btn-sm rounded-pill px-3 fs-val-sm"
                        onClick={() => handleAddToBasket(item)}
                      >
                        <i className="bi bi-cart-plus me-1"></i>
                        Add
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {hasMoreData && !loading && (
          <div className="text-center my-3">
            <button
              onClick={loadMore}
              disabled={loadingMore}
              className="btn btn-sm fs-val-sm bg-light"
            >
              {loadingMore
                ? "Loading..."
                : `Load More (${data.length}/${paginationRef.current.totalRecords})`}
            </button>
          </div>
        )}
      </div>

      <PosPriceSlabModal
        show={priceSlabModal.show}
        callback={() => setPriceSlabModal({ show: false, slabData: [] })}
        slabData={priceSlabModal.slabData}
        dealName={priceSlabModal.dealName}
        dealId={priceSlabModal.dealId}
        mrp={priceSlabModal.mrp}
      />

      <StockLedgerModal
        show={stockLedgerModal.show}
        callback={handleStockLedgerCb}
        storeId={storeId}
        dealId={stockLedgerModal.dealId}
        noView={ledgerDetailModal.show}
      />

      <StockLedgerDetailModal
        show={ledgerDetailModal.show}
        ledgerId={ledgerDetailModal.ledgerId}
        callback={handleLedgerDetailCallback}
        storeId={storeId}
        dealId={ledgerDetailModal.dealId}
      />

      <BusyLoader show={busyLoader.show} message={busyLoader.message} />

      <AddToPurchaseBasketModal
        show={basketModal.show}
        dealId={basketModal.dealId}
        storeId={storeId}
        callback={handleBasketModalCallback}
      />

      <PosRspLogModal
        show={rspLogModal.show}
        dealId={rspLogModal.dealId}
        storeId={storeId}
        callback={rspLogModalCallback}
      />
    </>
  );
};

function getHeaders(type) {
  const enableSort = type !== "topMoving"; // Disable sorting if type is topMoving
  return [
    { label: "Sl No", key: "slNo", width: "3%" },
    {
      label: "Deal Name",
      key: "name",
      enableSort: enableSort,
      width: "15%",
      isSticky: true,
      stickyPosition: "left",
    },
    { label: "Deal ID", key: "dealId", enableSort: enableSort, width: "5%" },
    { label: "MRP", key: "mrp", enableSort: enableSort, width: "5%" },
    {
      label: "B2B Price",
      key: "b2bprice",
      enableSort: enableSort,
      width: "5%",
    },
    {
      label: "RSP",
      key: "retailerSellingPrice",
      enableSort: enableSort,
      width: "5%",
    },
    {
      label: "Profit",
      key: "pnl",
      enableSort: false,
      width: "5%",
    },
    { label: "Stock", key: "qty", enableSort: enableSort, width: "5%" },
    {
      label: "Reserved Qty",
      key: "reservedQty",
      width: "5%",
    },
    {
      label: "Reserve Enabled",
      key: "reserveEnabled",
      width: "5%",
    },
    {
      label: "Last Ordered (B2B)",
      key: "lastOrderDate",
      width: "5%",
    },
    {
      label: "Last Inwarded On",
      key: "lastPurchased",
      width: "8%",
    },
    {
      label: "Last Ordered (B2C)",
      key: "lastCustomerOrderDate",
      width: "5%",
    },
    {
      label: "Sold In 7d",
      key: "soldInLast7",
      width: "5%",
    },
    {
      label: "Sold In 15d", // Split label
      key: "soldInLast15", // Key for 15 days data
      width: "5%", // Increased width for Sold In 15 Days
    },
    {
      label: "Sold In 30d", // Split label
      key: "soldInLast30", // Key for 30 days data
      width: "5%", // Increased width for Sold In 30 Days
    },
    {
      label: "Sold In 90d",
      key: "soldInLast90",
      width: "5%",
    },
    { label: "Menu", key: "menuName", width: "15%" },
    {
      label: "Category",
      key: "categoryName",
      width: "15%",
    },
    {
      label: "Brand",
      key: "brandName",
      width: "10%",
    },

    { label: "Location", key: "location", width: "10%" },
    { label: "Rack", key: "rack", width: "8%" },
    { label: "Bin", key: "bin", width: "8%" },
    {
      label: "",
      key: "addToBasket",
      width: "12%",
      sortable: false,
      isSticky: true,
      stickyPosition: "right",
      style: tdStyles.stickyRight,
    },
  ];
}

export default PosStoreTable;
