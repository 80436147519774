import { ExpenseService } from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  DateInput,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  TableHeader,
  TextInput,
} from "@sk/uis";
import { set } from "date-fns";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ExpenseDetailModal from "./modals/ExpenseDetailModal";

const ExpenseList = ({ storeId }) => {
  const { control, register, getValues } = useForm({
    defaultValues: {
      dateRange: [],
      search: "",
    },
  });

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [totalExpense, setTotalExpense] = useState(0);
  const filterRef = useRef({
    dateRange: [],
    search: "",
  });
  const [expenseDetailModal, setExpenseDetailModal] = useState({
    show: false,
    expenseId: null,
  });

  const paginationRef = useRef({ ...defaultPaginationConfig });
  const sortRef = useRef({
    key: "createdAt",
    value: "desc",
  });

  useEffect(() => {
    filterRef.current = {
      ...filterRef.current,
      storeId,
    };
    applyFilter();
  }, [applyFilter, storeId]);

  const applyFilter = useCallback(async () => {
    paginationRef.current = {
      ...defaultPaginationConfig,
    };

    loadList();

    setTotalExpense(0);

    const params = getParams(
      filterRef.current,
      paginationRef.current,
      sortRef.current
    );
    const r = await getCount(params);
    paginationRef.current.totalRecords = r.count;
    setTotalExpense(r.totalExpense);
  }, []);

  const onPaginationCb = (data) => {
    paginationRef.current.activePage = data.activePage;
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    loadList();
  };

  const loadList = async () => {
    setLoading(true);
    const params = getParams(
      filterRef.current,
      paginationRef.current,
      sortRef.current
    );
    const r = await getData(params);
    setData(r.data);
    setLoading(false);
  };

  const onDateChange = (chng) => (val) => {
    chng(val);
    filterRef.current = {
      ...filterRef.current,
      dateRange: val,
    };
    applyFilter();
  };

  const debounceSearch = useCallback(debounce(applyFilter, 800), []);

  const onSearch = () => {
    filterRef.current = {
      ...filterRef.current,
      search: getValues("search"),
    };
    debounceSearch();
  };

  const onViewExpense = (expenseId) => {
    setExpenseDetailModal({
      show: true,
      expenseId,
    });
  };

  const expenseDetailModalCb = () => {
    setExpenseDetailModal({
      show: false,
      expenseId: null,
    });
  };

  return (
    <>
      <AppCard>
        <div className="row">
          <div className="col-3">
            <TextInput
              name="search"
              label="Search"
              placeholder="Search by name"
              register={register}
              callback={onSearch}
              size="sm"
              gap={0}
            />
          </div>
          <div className="col-3">
            <Controller
              name="dateRange"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DateInput
                  callback={onDateChange(onChange)}
                  value={value}
                  label="Expense Date"
                  placeholder="Select Date"
                  size="sm"
                  template={2}
                  gap={0}
                />
              )}
            />
          </div>
        </div>
      </AppCard>

      <AppCard>
        <div className="mb-3 row">
          <div className="col-auto">
            <PaginationSummary
              loadingTotalRecords={loading}
              paginationConfig={paginationRef.current}
            />
          </div>
          <div className="col-auto">
            <HighlightText type="primary" template={2}>
              Total Expense:
              <span className="fw-bold ms-2">
                <Amount value={totalExpense} />
              </span>
            </HighlightText>
          </div>
        </div>
        <table className="table table-sm table-hover">
          <TableHeader data={headers} noBg />
          <tbody className="fs-val-md">
            {!loading && data.length === 0 && (
              <tr>
                <td colSpan={headers.length} className="text-center">
                  <NoDataFound>No data found</NoDataFound>
                </td>
              </tr>
            )}

            {data.map((e, index) => (
              <tr key={e._id}>
                <td>{paginationRef.current.startSlNo + index}</td>
                <td>{e.groupName}</td>
                <td>
                  <Amount value={e.totalExpense} />
                </td>
                <td>
                  {e.expenseData?.length}{" "}
                  <span className="text-muted fs-val-sm">
                    {e.expenseData?.length > 1 ? "items" : "item"}
                  </span>{" "}
                </td>
                <td>
                  <DateFormatter date={e.expenseDate} format="dd MMM yyyy" />
                </td>
                <td>
                  <DateFormatter date={e.createdAt} />
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-outline-primary fs-val-sm"
                    onClick={() => onViewExpense(e._id)}
                  >
                    View
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="text-right">
          <PaginationBlock
            loadingTotalRecords={loading}
            paginationCb={onPaginationCb}
            paginationConfig={paginationRef.current}
            size="sm"
          />
        </div>
      </AppCard>

      <ExpenseDetailModal
        show={expenseDetailModal.show}
        expenseId={expenseDetailModal.expenseId}
        callback={expenseDetailModalCb}
      />
    </>
  );
};

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const headers = [
  { label: "#", width: "3%" },
  { label: "Category", width: "15%" },
  { label: "Amount", width: "10%" },
  { label: "Items", width: "12%" },
  { label: "Expense Date", width: "15%", hideInQuick: true },
  { label: "Created On", width: "15%", showInQuick: true },
  { label: "Action", width: "10%" },
];

const getData = async (params) => {
  const r = await ExpenseService.getExpensesList(params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const getCount = async (params) => {
  const p = { ...params, outputType: "count" };

  delete p.page;
  delete p.count;

  const r = await ExpenseService.getExpensesList(p);
  const t = Array.isArray(r.resp) ? r.resp?.[0] : {};
  return {
    count: t?.total || 0,
    totalExpense: t?.totalExpense || 0,
  };
};

const getParams = (filter, pagination, sort) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      franchiseId: filter.storeId,
    },
    sort: { [sort.key]: sort.value == "asc" ? 1 : -1 },
  };

  if (filter.dateRange.length > 0) {
    p.filter.expenseDate = {
      $gte: set(filter.dateRange[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filter.dateRange[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filter.search) {
    p.filter.groupName = { $regex: filter.search, $options: "i" };
  }

  return p;
};

export default ExpenseList;
