import { AppCard, SelectInput, TextInput } from "@sk/uis";
import { debounce } from "lodash";
import { useCallback } from "react";
import { useForm } from "react-hook-form";

const statusOptions = [
  { label: "Settlement Pending", value: "SettlementInitiated" },
  { label: "Settled", value: "Settled" },
];

const defaultFormData = {
  search: "",
  status: "SettlementPending",
};

const PosEmployeeSettlementFilter = ({ callback }) => {
  const { register, getValues } = useForm({
    defaultValues: {
      ...defaultFormData,
    },
  });

  const onStatusChange = () => {
    triggerCb();
  };

  const triggerCb = () => {
    let f = getValues();
    callback({ formData: { ...f } });
  };

  const onSearchChange = useCallback(
    debounce(() => {
      triggerCb();
    }, 500),
    [triggerCb]
  );

  return (
    <>
      <AppCard>
        <div className="row">
          <div className="col-md-3">
            <SelectInput
              name="status"
              options={statusOptions}
              callback={onStatusChange}
              gap={0}
              register={register}
            />
          </div>
          <div className="col-md-3">
            <TextInput
              name="search"
              placeholder="Search by Employee Name"
              register={register}
              gap={0}
              callback={onSearchChange}
            />
          </div>
        </div>
      </AppCard>
    </>
  );
};

export default PosEmployeeSettlementFilter;
