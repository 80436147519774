import { StoreCard, StoresModal } from "@sk/features";
import { useFetchUrlQueryString } from "@sk/hooks";
import {
  CommonService,
  FranchiseService,
  NavService,
  PosService,
  RolesService,
} from "@sk/services"; // Import FranchiseService
import {
  AppCard,
  BusyLoader,
  PageInfo,
  PageLoader,
  Tabs,
  Toaster,
} from "@sk/uis";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom"; // Import useLocation to access URL parameters
import PosStoreNewlyLaunched from "./components/newly-launched/PosStoreNewlyLaunched";
import PosStoreSummary from "./components/PosStoreSummary";
import PosStoreTable from "./components/PosStoreTable";
import PosStoreTabs from "./components/PosStoreTabs";
import NonSellable from "./components/non-sellable/NonSellable";

const breadcrumbs = [
  { name: "Home", link: "/" }, // Link to the home page
  { name: "Store Inventory" }, // Current page
];

const mainTabData = [
  { key: "inventory", tabName: "Inventory" },
  { key: "rackAndBin", tabName: "Rack & Bin" },
  { key: "newlyLaunched", tabName: "Newly Launched" }, // Add newly launched tab
];

const PosStore = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams(); // Get the current location
  const queryParams = useFetchUrlQueryString(searchParams); // Use the custom hook

  const [activeTab, setActiveTab] = useState({ key: "all" });
  const [showModal, setShowModal] = useState(false);
  const [selectedStore, setSelectedStore] = useState(null); // State for selected store
  const [loading, setLoading] = useState(true); // State for loading franchise details
  const [noAccess, setNoAccess] = useState(false);

  const [mainTab, setMainTab] = useState(mainTabData[0]);

  const [accessibleStores, setAccessibleStores] = useState([]);

  const [busyLoader, setBusyLoader] = useState({ show: false, msg: "" });

  useEffect(() => {
    const fetchFranchise = async () => {
      const fid = queryParams.fid;

      // Redirect to /dashboard/inventory-ageing if view is not old and fid exists
      if (fid && queryParams.view !== "old") {
        NavService.replace(navigate, `/dashboard/inventory-ageing`, {
          fid: fid,
          fName: queryParams.fName,
          view: "store",
        });
        return;
      }

      const { stores } = await RolesService.getDashboardEnabledStores(
        "InventoryAgeingStoreDashboard"
      );

      setAccessibleStores(stores);

      if (fid && stores.indexOf(fid) === -1) {
        setNoAccess(true);
        setSelectedStore(null);
        setLoading(false);
        return;
      } else {
        setNoAccess(false);
      }

      if (fid) {
        setLoading(true); // Set loading to true before fetching
        try {
          const params = {
            select: "name,town,district,state,pincode,sk_franchise_details",
          };
          const response = await FranchiseService.getFranchise(fid, params);
          if (response.statusCode === 200) {
            setSelectedStore(response.resp); // Update selectedStore with the response
          } else {
            console.error("Failed to fetch franchise:", response);
          }
        } catch (error) {
          console.error("Error fetching franchise:", error);
        } finally {
          setLoading(false); // Set loading to false after fetching
        }
      } else {
        setSelectedStore(null); // Reset selectedStore if no fid is present
        setLoading(false); // Ensure loading is false if no fid
      }
    };

    fetchFranchise(); // Call the async function
  }, [queryParams.fid, queryParams.view, queryParams.fName, navigate]);

  const handleModalCallback = (e) => {
    if (e?.action === "selectedStore") {
      setSelectedStore(e?.store?.[0]); // Update the selected store
      NavService.replace(navigate, "/pos/inventory", {
        fid: e?.store?.[0]?._id,
        fName: e?.store?.[0]?.name,
      }); // Navigate to store with selected store ID
    }
    setShowModal(false); // Close the modal after handling
  };

  const chooseStore = () => {
    if (!accessibleStores.length) {
      Toaster.error("You are not authorized to access any store");
      return;
    }
    setShowModal(true);
  };

  const onMainTabChange = ({ value }) => {
    setMainTab(value);
  };

  const downloadInventorySummary = async () => {
    setBusyLoader({ show: true, msg: "Downloading inventory summary..." });
    const r = await PosService.getInventorySummary({
      franchiseId: selectedStore._id,
      isDownload: true,
      outputType: "list",
      page: 1,
      count: 10000000,
    });
    setBusyLoader({ show: false, msg: "" });

    if (r.resp) {
      const downloadLink = r.resp.downloadLink || "";
      if (downloadLink) {
        CommonService.downloadAsset(downloadLink);
        Toaster.success("Inventory summary downloaded successfully.");
      } else {
        Toaster.error("Failed to download inventory summary.");
      }
    } else {
      Toaster.error("Failed to download inventory summary.");
    }
  };

  return (
    <>
      <PageInfo
        title="Store Inventory"
        breadcrumbs={breadcrumbs}
        navigate={navigate}
      />

      {loading ? ( // Show PageLoader while fetching franchise details
        <PageLoader />
      ) : noAccess ? (
        <div className="store-selection text-center my-5 p-5 border rounded shadow-sm bg-light">
          <div className="container py-4 px-3 max-w-md mx-auto">
            <i className="bi bi-shop fs-1 text-primary mb-4 d-block"></i>
            <h4 className="mb-3 fw-bold text-dark">
              You are not authorized to access any store
            </h4>
          </div>
        </div>
      ) : selectedStore === null ? ( // Show store selection UI if no store is selected
        <div className="store-selection text-center my-5 p-5 border rounded shadow-sm bg-light">
          <div className="container py-4 px-3 max-w-md mx-auto">
            <i className="bi bi-shop fs-1 text-primary mb-4 d-block"></i>
            <h4 className="mb-3 fw-bold text-dark">Select Your Store</h4>
            <p className="text-muted mb-4 fs-6">
              To view inventory and manage your store, please select a store
              from the available options.
            </p>
            <button
              className="btn btn-primary btn-lg px-4 py-2 shadow-sm"
              onClick={chooseStore}
            >
              <i className="bi bi-building me-2"></i>
              Choose Store
            </button>
          </div>
        </div>
      ) : (
        <>
          <StoreCard
            fid={queryParams.fid}
            navigate={navigate}
            rbacKey="InventoryAgeingStoreDashboard"
          />

          {/* <div className="mb-3">
            <Tabs
              activeTab={mainTab}
              callback={onMainTabChange}
              data={mainTabData}
              template={3}
            />
          </div> */}

          {mainTab.key === "inventory" && (
            <>
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <PosStoreSummary
                    storeId={selectedStore._id}
                    storeName={selectedStore.name}
                  />
                </div>
                <div>
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={downloadInventorySummary}
                  >
                    <i className="bi bi-download me-2"></i>
                    Download Inventory Summary
                  </button>
                </div>
              </div>

              <div>
                <NonSellable
                  storeId={selectedStore._id}
                  storeName={selectedStore.name}
                />
              </div>

              <AppCard noPad={true}>
                <div className="border-bottom mb-3">
                  <PosStoreTabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </div>
                <div>
                  <div className="fs-val-sm text-muted mb-3 px-3">
                    {activeTab.description}
                  </div>

                  {activeTab.key === "newlyLaunched" ? (
                    <PosStoreNewlyLaunched />
                  ) : (
                    <PosStoreTable
                      type={activeTab.key}
                      storeId={selectedStore._id}
                      sellerId={selectedStore.sellerId}
                      storeName={selectedStore.name}
                    />
                  )}
                </div>
              </AppCard>
            </>
          )}

          {mainTab.key === "newlyLaunched" && (
            <PosStoreNewlyLaunched storeId={selectedStore._id} />
          )}
        </>
      )}

      <StoresModal
        show={showModal}
        callback={handleModalCallback}
        onlyAssignedStore={true}
        accessibleStoreIds={accessibleStores}
        rbackKey="InventoryAgeingStoreDashboard"
      />

      <BusyLoader show={busyLoader.show} message={busyLoader.msg} />
    </>
  );
};

export default PosStore;
