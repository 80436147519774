import { CommonService, CustomerService, NavService } from "@sk/services";
import {
  Amount,
  AppCard,
  AppliedFilterLabel,
  BtnLink,
  DateFormatter,
  DateInput,
  HighlightText,
  NoDataFound,
  PaginationBlock,
  PaginationSummary,
  SelectInput,
  TableHeader,
  TableSkeletonLoader,
  TextInput,
  Toaster,
} from "@sk/uis"; // Add necessary imports
import { endOfDay, set, startOfDay, sub } from "date-fns";
import produce from "immer";
import { debounce, orderBy } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react"; // Ensure these hooks are imported
import { Controller, useForm } from "react-hook-form";
import ClubReqDeliverySummary from "./ClubReqDeliverySummary";
import ClubRequestsSummary from "./ClubRequestsSummary";
import { statusOptions } from "./constant";
import ClubRequestDetailModal from "./modals/detail-modal/ClubRequestDetailModal";

const headers = [
  { label: "Sl No", width: "2%" },
  { label: "ID", width: "8%" },
  { label: "Partner Order ID", width: "12%", showInQuick: true },
  { label: "Customer", width: "12%" },
  { label: "Requested On", width: "10%" },
  { label: "Status", width: "10%" },
  { label: "Items", width: "4%" },
  { label: "Amount", width: "6%" },
  { label: "Delivery Time Slot", width: "12%", hideInQuick: true },
  { label: "Delivery Location", width: "40%" },
  { label: "Picker Info", width: "12%", showInQuick: true },
];

const ClubRequests = ({ storeId, callback, type }) => {
  const { register, getValues, control, setValue } = useForm({
    defaultValues: { ...defaultFormData },
  });
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState([]);

  const [filterLabels, setFilterLabels] = useState([]);

  const [detailModal, setDetailModal] = useState({
    show: false,
    id: null,
  });

  const paginationRef = useRef({ ...defaultPaginationConfig });

  const filteredStatusOptions = useMemo(() => getStatusOptions(type), [type]);

  const loadSummary = useCallback(async () => {
    const promises = getDefaultSummaryOnType(type).map(async (status) => {
      const params = prepareFilterParams(
        { ...getValues(), status: status.statusKey },
        paginationRef.current
      );
      return getCount(params);
    });

    setSummary(
      produce((draft) => {
        draft.forEach((status, index) => {
          draft[index].loading = true;
        });
      })
    );

    const summaryData = await Promise.all(promises);

    setSummary(
      produce((draft) => {
        draft.forEach((status, index) => {
          draft[index].value = summaryData[index].count;
          draft[index].loading = false;
        });
      })
    );
  }, [getValues, type]);

  const prepareFilterLabels = useCallback(() => {
    const v = { ...getValues() };

    delete v.storeId;
    delete v.type;

    if (v.selectedSlot?.displayFrom && v.selectedSlot?.displayTo) {
      v.selectedSlot = [v.selectedSlot.displayFrom, v.selectedSlot.displayTo];
    } else {
      v.selectedSlot = [];
    }

    if (v.selectedSlot.length > 0) {
      delete v.dateRange;
    }

    const l = CommonService.prepareAppliedFilterLabels(formLabels, v);

    const statusIndex = l.findIndex((label) => label.key === "status");
    if (statusIndex !== -1) {
      l[statusIndex].value = statusOptions.find(
        (option) => option.value === v.status
      )?.label;
    }

    setFilterLabels(l);
  }, [getValues]);

  const handleRemoveFilterLabel = (labelToRemove) => {
    if (labelToRemove.data.key in defaultFormData) {
      setValue(labelToRemove.data.key, defaultFormData[labelToRemove.data.key]);
    }

    if (labelToRemove.data.key === "status") {
      setValue("status", "");
    }

    applyFilter();
  };

  const applyFilter = useCallback(async () => {
    setLoading(true);

    prepareFilterLabels();

    loadSummary();

    loadList();

    const r = await getCount(
      prepareFilterParams(getValues(), paginationRef.current)
    );
    paginationRef.current.totalRecords = r.count;
    // setRecordsCount({ loading: false, count: r.count });
  }, [loadSummary, loadList, getValues, prepareFilterLabels]);

  const loadList = useCallback(async () => {
    setLoading(true);
    const r = await getData(
      prepareFilterParams(getValues(), paginationRef.current)
    );
    setRequests(r.data);
    setLoading(false);
  }, [getValues]);

  const onPaginationCb = (data) => {
    paginationRef.current.activePage = data.activePage;
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    loadList();
  };

  useEffect(() => {
    if (type === "quick") {
      setSummary((prevSummary) =>
        prevSummary.filter((item) => item.statusKey !== "PENDING")
      );
      const summaryData = getDefaultSummaryOnType(type);
      setSummary(summaryData);
      console.log("summaryData", summaryData);
      setValue("status", summaryData[0].statusKey);
    }
    if (storeId) {
      setValue("storeId", storeId);
      setValue("type", type);
      applyFilter(); // Call applyFilter on component mount
    }
  }, [applyFilter, storeId, setValue, type]);

  const onDetailModalCb = (data) => {
    setDetailModal({ show: false, id: null });
    if (data.action !== "close") {
      callback(data);
    }
  };

  const onViewDetail = (id) => {
    // setDetailModal({ show: true, id });
    NavService.openInNewTab("/pos/request/view", {
      id,
    });
  };

  const onStatusChange = () => {
    applyFilter();
  };

  const handleSearchChange = useCallback(
    debounce(() => {
      const s = getValues("search");
      if (!s.trim()) {
        setValue("dateRange", []);
      } else {
        setValue("dateRange", [sub(new Date(), { months: 1 }), new Date()]);
      }
      applyFilter();
    }, 800),
    []
  );

  const onSearchChange = () => {
    handleSearchChange();
  };

  const onDateChange = (onChange) => {
    return (value) => {
      onChange(value);
      setValue("selectedSlot", null);
      applyFilter();
    };
  };

  const viewOnMap = (request) => {
    const f = {
      lat: request.franchiseInfo.geolocation.coordinates[0],
      lng: request.franchiseInfo.geolocation.coordinates[1],
    };
    const source = { lat: f.lat, lng: f.lng };
    const dest = {
      lat: 1 * request.shippingAddress?.geolocation?.coordinates[0],
      lng: 1 * request.shippingAddress?.geolocation?.coordinates[1],
    };

    if (source.lat && source.lng && dest.lat && dest.lng) {
      window.open(
        `https://www.google.com/maps/dir/?api=1&origin=${source.lat},${source.lng}&destination=${dest.lat},${dest.lng}&travelmode=driving`
      );
    } else {
      Toaster.error("Location details not found");
    }
  };

  const handleTimeSlotSelection = (slotData) => {
    if (slotData.from && slotData.to) {
      setValue("selectedSlot", {
        from: slotData.from,
        to: slotData.to,
        displayFrom: slotData.displayFrom,
        displayTo: slotData.displayTo,
      });
      if (slotData.status) {
        setValue("status", slotData.status);
      } else {
        setValue("status", "");
      }
      applyFilter();
    } else {
      setValue("selectedSlot", null);
      applyFilter();
    }
  };

  return (
    <>
      {type !== "quick" && (
        <ClubReqDeliverySummary
          storeId={storeId}
          callback={handleTimeSlotSelection}
        />
      )}
      <AppCard>
        {/* Filter Section */}
        <div className="row mb-3">
          <div className="col-3">
            <TextInput
              label="Search by Request ID/Customer"
              placeholder="Search..."
              callback={onSearchChange}
              register={register}
              name="search"
              gap={0}
            />
          </div>
          <div className="col-3">
            <Controller
              name="dateRange"
              control={control}
              render={({ field }) => (
                <DateInput
                  label="Date Range"
                  onChange={field.onChange}
                  value={field.value}
                  callback={onDateChange(field.onChange)}
                  config={dateConfig}
                  gap={0}
                  template={2}
                  hideClose={true}
                />
              )}
            />
          </div>
          <div className="col-3">
            <SelectInput
              options={filteredStatusOptions}
              label="Filter by Status"
              callback={onStatusChange}
              register={register}
              name="status"
              gap={0}
            />
          </div>
        </div>
      </AppCard>

      <ClubRequestsSummary summary={summary} />

      <AppCard>
        <div className="row mb-3">
          <div className="col-auto">
            <PaginationSummary
              loadingTotalRecords={loading}
              paginationConfig={paginationRef.current}
            />
          </div>
          <div className="col-auto">
            {filterLabels.length > 0 ? (
              <AppliedFilterLabel
                labels={filterLabels}
                callback={handleRemoveFilterLabel}
              />
            ) : null}
          </div>
        </div>

        {/* New table container with scrolling */}
        <div
          className="tbl-scroll-container custom-scrollbar thin-scrollbar"
          style={tableContainerStyle}
        >
          <table className="table table-sm table-hover" style={tableStyle}>
            <TableHeader data={filteredHeaders(type)} noBg isSticky />
            <tbody className="fs-val-md">
              {loading ? (
                <TableSkeletonLoader cols={headers.length} rows={5} />
              ) : requests.length === 0 ? (
                <tr>
                  <td colSpan={headers.length}>
                    <NoDataFound>No requests found</NoDataFound>
                  </td>
                </tr>
              ) : (
                requests.map((request, index) => (
                  <tr
                    key={request._id}
                    onClick={() => onViewDetail(request?._id)}
                  >
                    <td>{paginationRef.current.startSlNo + index}</td>
                    <td>
                      <BtnLink className="fs-val-md text-dark text-start">
                        {request._id}
                      </BtnLink>
                    </td>
                    {type === "quick" && (
                      <td>
                        <div>{request.thirdPartyPartnerOrderId || "--"}</div>
                      </td>
                    )}
                    <td>
                      <div className="fw-medium mb-1">
                        <BtnLink
                          className="fs-val-md text-dark text-start"
                          onClick={(e) => {
                            e.stopPropagation();
                            NavService.openInNewTab("/users/customer/view", {
                              id: request.customerInfo?.id,
                            });
                          }}
                        >
                          {request.customerInfo?.name}
                        </BtnLink>
                      </div>
                    </td>
                    <td>
                      <DateFormatter
                        date={request.createdAt}
                        format="dd MMM yyyy"
                      />
                    </td>
                    <td>
                      <HighlightText type={request._bgCss} template={2}>
                        {request._statusLbl}
                      </HighlightText>
                    </td>
                    <td>{request.listingPayload.length}</td>
                    <td>
                      <Amount value={request._displayFinalAmt} />
                    </td>
                    {type !== "quick" && (
                      <td>
                        <div>
                          <DateFormatter
                            date={request.deliveryTimeSlot?._from}
                            format="hh:mm a"
                          />{" "}
                          -{" "}
                          <DateFormatter
                            date={request.deliveryTimeSlot?._to}
                            format="hh:mm a"
                          />
                        </div>
                      </td>
                    )}
                    <td>
                      <div className="text-wrap mb-2">
                        {request.shippingAddress?.address?.full_address ||
                          [
                            request.shippingAddress?.address?.line1,
                            request.shippingAddress?.address?.line2,
                          ]
                            .filter(Boolean)
                            .join(", ")}
                      </div>
                      <div className="d-flex align-items-center">
                        <span className="text-danger fs-val-xs">
                          {request.deliveryDistance || 0} km away
                        </span>
                        <BtnLink
                          className="text-secondary fs-val-xs ms-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            viewOnMap(request);
                          }}
                        >
                          View on Map
                        </BtnLink>
                      </div>
                    </td>
                    {type === "quick" && (
                      <td>
                        <div>
                          {request.pickerInfo?.name || "--"} -{" "}
                          <span className="text-muted fs-val-sm">
                            {request.pickerInfo?.id}
                          </span>
                        </div>
                        <div className="text-muted fs-val-sm">
                          {request.pickerInfo?.mobile}
                        </div>
                      </td>
                    )}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        <PaginationBlock
          loadingTotalRecords={loading}
          paginationCb={onPaginationCb}
          paginationConfig={paginationRef.current}
        />
      </AppCard>

      <ClubRequestDetailModal
        show={detailModal.show}
        callback={onDetailModalCb}
        requestId={detailModal.id}
      />
    </>
  );
};

function getDefaultSummaryOnType(type) {
  const defaultSummaryData = [...statusOptions].map((s) => ({
    ...s,
    title: s.label === "All" ? "Total Requests" : s.label,
    value: 0,
    loading: false,
    statusKey: s.value,
    valueColor: s.color,
  }));

  // defaultSummaryData.pop();

  if (type === "quick") {
    return defaultSummaryData.filter(
      (item) =>
        item.statusKey !== "PENDING" && item.statusKey !== "YET_TO_PROCESS"
    );
  }

  // Filter out picker related status if type != 'quick'
  return defaultSummaryData.filter(
    (item) =>
      item.statusKey !== "YET_TO_ASSIGN_PICKER" &&
      item.statusKey !== "PICKER_ASSIGNED"
  );
}

const dateConfig = {
  maxDate: new Date(),
};

const defaultFormData = {
  dateRange: [new Date(), new Date()],
  search: "",
  status: "PENDING",
  selectedSlot: null,
  type: "", // Add type to defaultFormData
};

const formLabels = {
  dateRange: {
    label: "Date Range",
    type: "dateRange",
  },
  search: {
    label: "Search",
    type: "text",
    canRemove: true,
  },
  status: {
    label: "Status",
    type: "select",
    canRemove: true,
  },
  selectedSlot: {
    label: "Time Slot",
    type: "dateRange",
    dateFormat: "dd MMM yyyy, hh:mm a",
    canRemove: true,
  },
};

const getData = async (params) => {
  const r = await CustomerService.getClubRequests(params);
  return {
    data: CustomerService.formatCustomerServiceRequests(
      Array.isArray(r.resp) ? r.resp : []
    ),
  };
};

const getCount = async (params) => {
  const r = await CustomerService.getClubRequestsCount(params); // Assuming this method exists
  return { count: r.resp };
};

const prepareFilterParams = (filterData, pagination) => {
  const p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      type: { $in: ["buyandsell", "wyp"] },
    },
  };

  if (filterData.type === "quick") {
    p.filter.isPartnerOrder = true;
  }

  if (filterData.dateRange.length > 0) {
    p.filter.createdAt = {
      $gte: set(filterData.dateRange[0], {
        hours: 0,
        minutes: 0,
        seconds: 0,
      }),
      $lte: set(filterData.dateRange[1], {
        hours: 23,
        minutes: 59,
        seconds: 59,
      }),
    };
  }

  if (filterData.selectedSlot) {
    p.filter["deliveryTimeSlot.date"] = {
      $gte: startOfDay(new Date(filterData.selectedSlot.displayFrom)),
      $lte: endOfDay(new Date(filterData.selectedSlot.displayFrom)),
    };
    p.filter["deliveryTimeSlot.from"] = filterData.selectedSlot.from;
    p.filter["deliveryTimeSlot.to"] = filterData.selectedSlot.to;

    delete p.filter.createdAt;
  }

  const search = filterData.search;

  if (search.trim()) {
    p.filter.$or = [
      {
        _id: {
          $regex: search,
          $options: "gi",
        },
      },
      {
        "customerInfo.name": {
          $regex: search,
          $options: "gi",
        },
      },
      {
        "customerInfo.mobile": {
          $regex: search,
          $options: "gi",
        },
      },
      {
        _id: {
          $regex: search,
          $options: "gi",
        },
      },
      {
        "listingPayload.name": {
          $regex: search,
          $options: "gi",
        },
      },
      {
        "pickerInfo.name": {
          $regex: search,
          $options: "gi",
        },
      },
      {
        thirdPartyPartnerOrderId: {
          $regex: search,
          $options: "gi",
        },
      },
    ];
  }

  if (filterData.storeId) {
    p.filter.franchiseId = filterData.storeId;
    // p.filter["franchiseInfo.id"] = filterData.storeId;
  }

  if (filterData.search) {
    p.search = filterData.search;
  }

  if (filterData.status) {
    const statusOption = statusOptions.find(
      (option) => option.value === filterData.status
    );
    if (statusOption) {
      if (filterData.status === "YET_TO_ASSIGN_PICKER") {
        p.filter["pickerInfo.status"] = "NotAssigned";
        p.filter.status = { $in: statusOption.filterStatus };
      } else if (filterData.status === "PICKER_ASSIGNED") {
        p.filter["pickerInfo.status"] = "Assigned";
        p.filter.status = { $in: statusOption.filterStatus };
      } else {
        p.filter.status = {
          $in: statusOption.filterStatus,
        };
      }
    }
  }

  return p;
};

// Default pagination configuration
const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const getStatusOptions = (type) => {
  let statusList = [];
  if (type === "quick") {
    statusList = statusOptions.filter(
      (item) => item.value !== "PENDING" && item.value !== "YET_TO_PROCESS"
    );
  } else {
    statusList = statusOptions.filter(
      (item) =>
        item.value !== "YET_TO_ASSIGN_PICKER" &&
        item.value !== "PICKER_ASSIGNED"
    );
  }
  return orderBy(statusList, ["label"], ["asc"]);
};

const filteredHeaders = (type) => {
  return headers.filter(
    (header) => !(type === "quick" ? header.hideInQuick : header.showInQuick)
  );
};

const tableContainerStyle = {
  maxHeight: "calc(100vh - 300px)",
};

const tableStyle = {
  minWidth: "1800px",
};

export default ClubRequests;
