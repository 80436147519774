import { AppCard, DateFormatter, KeyVal } from "@sk/uis";

const StoreDealPurchaseSummary = ({ data }) => {
  return (
    <AppCard>
      <div className="fs-val-md fw-semibold mb-2">Purchase Summary</div>
      <div className="row">
        <div className="col-6">
          <div className="bg-light p-2 rounded border">
            <KeyVal
              label="Last Ordered (B2B)"
              template="col"
              noBottomSpace={true}
            >
              {data.lastPurchasedDate ? (
                <>
                  <DateFormatter
                    date={data.lastPurchasedDate}
                    format={"dd MMM yyyy"}
                  />
                  <span className="ms-1 fs-val-sm text-muted">
                    <DateFormatter
                      date={data.lastPurchasedDate}
                      format={"hh:mm a"}
                    />
                  </span>
                </>
              ) : (
                "N/A"
              )}
              {data.lastPurchasedUnits
                ? `, ${data.lastPurchasedUnits} units`
                : ""}
            </KeyVal>
          </div>
        </div>
        <div className="col-6">
          <div className="bg-light p-2 rounded border">
            <KeyVal
              label="Last Inwarded Date"
              template="col"
              noBottomSpace={true}
            >
              {data.lastInwardedDate ? (
                <>
                  <DateFormatter
                    date={data.lastInwardedDate}
                    format={"dd MMM yyyy"}
                  />
                  <span className="ms-1 fs-val-sm text-muted">
                    <DateFormatter
                      date={data.lastInwardedDate}
                      format={"hh:mm a"}
                    />
                  </span>
                </>
              ) : (
                "N/A"
              )}
              {data.lastIntakeUnits ? `, ${data.lastIntakeUnits} units` : ""}
            </KeyVal>
          </div>
        </div>
        <div className="col-6 mt-3">
          <div className="bg-light p-2 rounded border">
            <KeyVal
              label="Open Purchase from Store"
              template="col"
              noBottomSpace={true}
            >
              {data.openOrders || 0}
            </KeyVal>
          </div>
        </div>
      </div>
    </AppCard>
  );
};

export default StoreDealPurchaseSummary;
