import { PosService } from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  NoDataFound,
  Pagination,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import { endOfDay, startOfDay, startOfMonth } from "date-fns";
import produce from "immer";
import { useCallback, useEffect, useRef, useState } from "react";
import PosCashCollectFilter from "./PosCashCollectFilter";
import PosCashCollectSummary from "./PosCashCollectSummary";
import PosCashCollectOrdersModal from "./modals/PosCashCollectOrdersModal";
import { PosOrderDetailModal } from "@sk/features";

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "Delivery Person", width: "30%" },
  { label: "Amount", width: "15%" },
  { label: "Total Orders", width: "15%" },
  { label: "Collected On", width: "30%" },
];

const defaultSummaryCards = [
  {
    title: "Pending to Collect",
    value: 0, // Replace with actual value
    isValueAmt: true,
    valueColor: "danger",
    icon: "bi bi-currency-rupee",
    loading: true,
    type: "Pending",
  },
  {
    title: "Collected Amount",
    value: 0, // Replace with actual value
    isValueAmt: true,
    template: 1,
    valueColor: "success",
    icon: "bi bi-currency-rupee",
    loading: true,
    type: "Collected",
  },
  {
    title: "Delivery Persons",
    value: 0, // Replace with actual value
    template: 1,
    icon: "bi bi-person",
    loading: true,
  },
  {
    title: "Total Orders",
    value: 0, // Replace with actual value
    template: 1,
    icon: "bi bi-box",
    loading: true,
  },
];

const getData = async (params) => {
  const r = await PosService.getPosSettlement(params);
  const d = Array.isArray(r.resp) ? r.resp : [];

  const empIds = d.map((x) => x._id);

  if (empIds.length) {
    const empList = await PosService.getEmpList({
      page: 1,
      count: empIds.length,
      filter: {
        _id: { $in: empIds },
      },
    });
    d.forEach((x) => {
      const emp = empList.resp.find((e) => e._id === x._id);
      x.deliveryPerson = emp;
    });
  }

  return { data: d };
};

const getCount = async (params) => {
  const p = { ...params };
  p.outputType = "count";

  delete p.count;
  delete p.page;
  delete p.sort;

  const r = await PosService.getPosSettlement(p);
  return { count: r.statusCode == 200 && r.resp[0] ? r.resp[0].total : 0 };
};

const getPendingToCollect = async (params) => {
  let p = { ...params };
  p.filter.status = "SettlementInitiated";
  p.groupbycond = "status";

  delete p.count;
  delete p.page;
  delete p.sort;

  const r = await PosService.getPosSettlement(p);

  const d = Array.isArray(r.resp) ? r.resp : [];

  return {
    amount: r.statusCode == 200 && d[0] ? d[0].totalAmt : 0,
    orders: r.statusCode == 200 && d[0] ? d[0].data?.length : 0,
  };
};

const getCollectedAmount = async (params) => {
  let p = { ...params };
  p.filter.status = "Settled";
  p.groupbycond = "status";

  delete p.count;
  delete p.page;
  delete p.sort;

  const r = await PosService.getPosSettlement(p);

  return {
    amount: r.statusCode == 200 && r.resp[0] ? r.resp[0].totalAmt : 0,
    orders: r.statusCode == 200 && r.resp[0] ? r.resp[0].data?.length : 0,
  };
};

const prepareParams = (filter, pagination) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      "senderInfo.id": filter.storeId,
      createdAt: {
        $gte: startOfDay(new Date(filter.dateRange[0])),
        $lte: endOfDay(new Date(filter.dateRange[1])),
      },
    },
    groupbycond: "shipmentUserId",
    sort: { shipmentUserName: 1 },
  };

  const search = filter.search?.trim();

  if (search) {
    p.filter.$or = [
      {
        "shipmentUserInfo.name": {
          $regex: search,
          $options: "gi",
        },
      },
      {
        orderId: {
          $regex: search,
          $options: "gi",
        },
      },
    ];
  }

  if (filter.type === "Pending") {
    p.filter.status = "SettlementInitiated";
    delete p.filter.createdAt;
  }

  if (filter.type === "Collected") {
    p.filter.status = "Settled";
  }

  return p;
};

const PosCashCollect = ({ storeId }) => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [records, setRecords] = useState({ loading: true, value: 0 });
  const [type, setType] = useState("Pending");
  const [summaryCards, setSummaryCards] = useState([...defaultSummaryCards]);
  const [modalState, setModalState] = useState({
    show: false,
    userId: null,
    dateRange: [],
  });
  const [orderDetailModal, setOrderDetailModal] = useState({
    show: false,
    orderId: null,
  });

  const filterDataRef = useRef({
    type: "Pending",
    dateRange: [startOfMonth(new Date()), new Date()],
  });
  const paginationRef = useRef({ ...defaultPaginationConfig });

  const loadSummaryCards = useCallback(async () => {
    const promises = [
      getPendingToCollect(
        prepareParams(
          { ...filterDataRef.current, type: "Pending" },
          paginationRef.current
        )
      ),
      getCount(prepareParams(filterDataRef.current, paginationRef.current)),
      getCollectedAmount(
        prepareParams(
          { ...filterDataRef.current, type: "Collected" },
          paginationRef.current
        )
      ),
    ];

    setSummaryCards(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );

    const [pendingToCollect, totalCount, collectedAmount] = await Promise.all(
      promises
    );

    setSummaryCards(
      produce((draft) => {
        draft[0].loading = false;
        draft[1].loading = false;
        draft[2].loading = false;
        draft[3].loading = false;

        draft[0].value = pendingToCollect.amount;
        draft[1].value = collectedAmount.amount;
        draft[2].value = totalCount.count;
        draft[3].value =
          filterDataRef.current.type === "Pending"
            ? pendingToCollect.orders
            : collectedAmount.orders;
      })
    );
  }, []);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };

    loadList();

    loadSummaryCards();

    setRecords({ loading: true, value: 0 });
    const r = await getCount(
      prepareParams(filterDataRef.current, paginationRef.current)
    );
    setRecords({ loading: false, value: r.count });
  }, [loadList, loadSummaryCards]);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    setData([]);

    const r = await getData(
      prepareParams(filterDataRef.current, paginationRef.current)
    );
    const d = r.data || [];
    setData(d);
    setLoadingData(false);
  }, []);

  useEffect(() => {
    filterDataRef.current = {
      ...filterDataRef.current,
      storeId,
    };
    applyFilter();
  }, [applyFilter, storeId]);

  const paginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
  };

  const onFilterCb = (data) => {
    filterDataRef.current = {
      ...filterDataRef.current,
      ...data.formData,
    };

    setType(data.formData.type);

    applyFilter();
  };

  const openOrdersModal = (id) => {
    setModalState({
      show: true,
      userId: id,
      dateRange: filterDataRef.current.dateRange,
    });
  };

  const closeOrdersModal = (data) => {
    setModalState({ show: false, userId: null, dateRange: [] });

    if (data.action === "viewOrder") {
      setOrderDetailModal({
        show: true,
        orderId: data.orderId,
      });
    }
  };

  const handleOrderDetailClose = (data) => {
    if (data.action === "close") {
      setOrderDetailModal({
        show: false,
        orderId: null,
      });
    }
  };

  return (
    <>
      <PosCashCollectFilter callback={onFilterCb} />
      <PosCashCollectSummary summaryCards={summaryCards} type={type} />
      <AppCard>
        <PaginationSummary
          loadingTotalRecords={records.loading}
          paginationConfig={paginationRef.current}
          className="mb-1"
        />
        <table className="table bg-white table-striped table-sm table-hover">
          <TableHeader data={headers} noBg={true} />
          <tbody className="fs-val-md">
            {!loadingData && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Data found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {loadingData ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={10}
                height={40}
              />
            ) : null}

            {data.map((x, index) => (
              <tr key={x._id} className="fs-val-md">
                <td>{paginationRef.current.startSlNo + index}</td>
                <td>{x.deliveryPerson?.name}</td>
                <td
                  className={
                    type === "Pending" ? "text-danger" : "text-success"
                  }
                >
                  <Amount value={x.totalAmt || 0} decimal={2} />
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-link fs-val-md text-start text-dark p-0"
                    onClick={() => openOrdersModal(x._id)}
                  >
                    {x.data?.length} orders
                  </button>
                </td>
                <td>
                  {type === "Pending" ? (
                    "Yet to collect"
                  ) : (
                    <DateFormatter date={x.data?.[0]?.createdAt} />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="text-end">
          <Pagination
            callback={paginationCb}
            activePage={paginationRef.current.activePage}
            rowsPerPage={paginationRef.current.rowsPerPage}
            totalRecords={paginationRef.current.totalRecords}
          />
        </div>
      </AppCard>

      <PosCashCollectOrdersModal
        userId={modalState.userId}
        callback={closeOrdersModal}
        show={modalState.show}
        dateRange={modalState.dateRange}
        storeId={storeId}
        status={type}
      />

      <PosOrderDetailModal
        show={orderDetailModal.show}
        callback={handleOrderDetailClose}
        orderId={orderDetailModal.orderId}
      />
    </>
  );
};

export default PosCashCollect;
