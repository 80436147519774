import { Amount, AppCard, KeyVal, TableHeader } from "@sk/uis";
import { Modal } from "react-bootstrap";

const headerData = [
  { label: "Quantity", key: "quantity", width: "33%" },
  {
    label: "Price per Unit",
    key: "pricePerUnit",
    width: "33%",
  },
  { label: "Discount", key: "discount", width: "34%" },
];

const PosPriceSlabModal = ({
  show,
  callback,
  slabData,
  dealName,
  dealId,
  mrp,
}) => {
  const handleClose = () => {
    callback && callback();
  };

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="bg-white">
          <Modal.Title className="mb-0 text-dark">Price Slab Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-bg">
          <AppCard>
            <div className="mb-1 fs-val-md fw-semibold">{dealName}</div>
            <div className="row">
              <div className="col-6">
                <KeyVal
                  label="Deal ID"
                  labelCol="col-auto"
                  contentCol="col"
                  fwSize="sm"
                >
                  <span className="text-muted">{dealId}</span>
                </KeyVal>
              </div>
              <div className="col-6">
                <KeyVal
                  label="MRP"
                  labelCol="col-auto"
                  contentCol="col"
                  fwSize="sm"
                >
                  <Amount value={mrp} />
                </KeyVal>
              </div>
            </div>
          </AppCard>

          <AppCard>
            <table className="table table-sm table-striped">
              <TableHeader data={headerData} noBg />
              <tbody className="fs-val-md">
                {slabData?.map((item) => (
                  <tr key={item._id}>
                    <td>
                      {item._min} to {item._max}
                    </td>
                    <td>
                      <Amount value={item._offerPrice} />
                    </td>
                    <td>{item._displayDisc}%</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </AppCard>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PosPriceSlabModal;
