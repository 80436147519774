import { Amount, ImgRender, KeyVal } from "@sk/uis";

const PosOrderItem = ({ order }) => {
  return (
    <div className="row" key={order._id}>
      <div className="col-auto">
        <div className="border rounded p-1" style={{ width: "80px" }}>
          <ImgRender assetId={order.images?.[0]} />
        </div>
      </div>
      <div className="col">
        <div className="fs-val-md mb-2">{order.name}</div>
        <div className="row">
          <div className="col-6">
            <KeyVal
              label="Price"
              labelCol="col-5"
              contentCol="col-7"
              fwSize="sm"
              noBottomSpace={true}
              className="mb-1"
            >
              :{" "}
              {order.isFree ? (
                <span className="text-danger fw-bold">FREE</span>
              ) : (
                <Amount
                  value={
                    order.sellInLooseQty ? order.packB2bPrice : order.b2bPrice
                  }
                  decimalPlace={2}
                />
              )}
            </KeyVal>
          </div>
          <div className="col-6">
            <KeyVal
              label="MRP"
              labelCol="col-5"
              contentCol="col-7"
              fwSize="sm"
              noBottomSpace={true}
              className="mb-1"
            >
              : <Amount value={order.mrp} decimalPlace={2} />
            </KeyVal>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <KeyVal
              label="Quantity"
              labelCol="col-5"
              contentCol="col-7"
              fwSize="sm"
              noBottomSpace={true}
            >
              : {order.quantity} {order.unitType}
            </KeyVal>
          </div>
          <div className="col-6">
            <KeyVal
              label="Sub Total"
              labelCol="col-5"
              contentCol="col-7"
              fwSize="sm"
              noBottomSpace={true}
            >
              :{" "}
              {order.isFree ? (
                <span className="text-danger fw-bold">FREE</span>
              ) : (
                <Amount value={order._totalPrice} decimalPlace={2} />
              )}
            </KeyVal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PosOrderItem;
