import { DashboardService, NavService } from "@sk/services";
import {
  Amount,
  AppCard,
  BtnLink,
  DateFormatter,
  KeyVal,
  Spinner,
} from "@sk/uis";
import { useEffect, useState } from "react";

const NonSellable = ({ storeId, storeName }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    totalProducts: 0,
    totalQuantity: 0,
    totalValue: 0,
    batchDate: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const res = await getData(storeId);
      setData(res);
      setLoading(false);
    };
    if (storeId) {
      fetchData();
    }
  }, [storeId]);

  const viewProducts = () => {
    NavService.openInNewTab("/dashboard/inventory-ageing/products", {
      fid: storeId,
      fName: storeName,
      view: "store",
      tab: "nonSellableInventory",
    });
  };

  return (
    <AppCard title="Non Sellable Inventory">
      <div className="fs-val-sm text-muted">
        Last synced on : <DateFormatter date={data.batchDate} />
      </div>
      <div className="row">
        <div className="col-4">
          <div className="bg-light p-2 rounded border">
            <KeyVal
              label="Total Products"
              labelCol="col-auto"
              contentCol="col"
              noBottomSpace={true}
            >
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {data.totalProducts > 0 ? (
                    <BtnLink className="fs-val-md" onClick={viewProducts}>
                      {data.totalProducts}
                    </BtnLink>
                  ) : (
                    0
                  )}
                </>
              )}
            </KeyVal>
          </div>
        </div>
        <div className="col-4">
          <div className="bg-light p-2 rounded border">
            <KeyVal
              label="Total Quantity"
              labelCol="col-auto"
              contentCol="col"
              noBottomSpace={true}
            >
              {loading ? <Spinner /> : data.totalQuantity}
            </KeyVal>
          </div>
        </div>
        <div className="col-4">
          <div className="bg-light p-2 rounded border">
            <KeyVal
              label="Total Value"
              labelCol="col-auto"
              contentCol="col"
              noBottomSpace={true}
            >
              {loading ? <Spinner /> : <Amount value={data.totalValue} />}
            </KeyVal>
          </div>
        </div>
      </div>
    </AppCard>
  );
};

const getData = async (storeId) => {
  const res = await DashboardService.getInventoryAgeingBatchId({
    outputType: "storeInventoryAgening",
  });
  const batchId = res?.resp?.batchId;
  const batchDate = res?.resp?.batchTimestamp;

  if (!batchId) {
    return {
      totalProducts: 0,
      totalQuantity: 0,
      totalValue: 0,
    };
  }
  const response = await DashboardService.fetchStoreInventoryAnalytics({
    filter: {
      franchiseId: storeId,
      "inventoryInfo.inventoryStatus": "NonSellable",
    },
    batchId,
    queryType: "getStoreInventoryStatusSummary",
  });
  return {
    totalProducts: response?.resp?.[0]?.pIdCount,
    totalQuantity: response?.resp?.[0]?.totalQty,
    totalValue: response?.resp?.[0]?.totalValue,
    batchDate,
  };
};

export default NonSellable;
