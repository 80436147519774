import { DateFormatter, KeyVal } from "@sk/uis";

const ClubReqDeliveryDetail = ({ order }) => {
  return (
    <>
      <KeyVal label="Distance" labelCol="col-5" contentCol="col-7">
        <div className="d-flex flex-row gap-1">
          <span>:</span>
          <div>
            <span className="badge badge-soft-danger">
              {order.deliveryDistance || "N/A"} km
            </span>
            <span className="text-muted fs-val-sm ms-1">
              from {order.franchiseInfo?.name}
            </span>
          </div>
        </div>
      </KeyVal>
      <KeyVal label="Time Slot" labelCol="col-5" contentCol="col-7">
        <div className="d-flex flex-row gap-1">
          <span>:</span>
          <div>
            <div>
              <DateFormatter
                date={order.deliveryTimeSlot?._from}
                format="hh:mm a"
              />
              <span className="mx-1">-</span>
              <DateFormatter
                date={order.deliveryTimeSlot?._to}
                format="hh:mm a"
              />
            </div>
            <div className="text-muted fs-val-sm">
              <DateFormatter
                date={order.deliveryTimeSlot?._from}
                format="dd MMM yyyy"
              />
            </div>
          </div>
        </div>
      </KeyVal>
      <KeyVal label="Address" labelCol="col-5" contentCol="col-7">
        <div className="d-flex flex-row gap-1">
          <div>:</div>
          <div>
            <div>{order.shippingAddress?.address?.full_address}</div>
            <div className="mb-2 fs-val-sm text-muted">
              {order.shippingAddress?.district},{order.shippingAddress?.state} -{" "}
              {order.shippingAddress?.pincode}
            </div>
            {order.lat && order.lng && (
              <a
                href={`https://www.google.com/maps/search/?api=1&query=${order.lat},${order.lng}`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary fs-val-sm"
              >
                View on Map
              </a>
            )}
          </div>
        </div>
      </KeyVal>
    </>
  );
};

export default ClubReqDeliveryDetail;
