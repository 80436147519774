import classNames from "classnames";
import styles from "./PosMain.module.scss";
import { useCallback, useEffect, useState } from "react";
import {
  CommonService,
  FranchiseService,
  SellerService,
  StorageService,
} from "@sk/services";
import { PageLoader } from "@sk/uis";
import PosFranModal from "./modals/PosFranModal";

function PosMain() {
  const [loading, setLoading] = useState(true);

  const [selectedFran, setSelectedFran] = useState({});

  const [showModal, setShowmodal] = useState(false);

  const init = useCallback(async () => {
    setLoading(true);

    const r = await FranchiseService.getList({
      filter: {
        groupType: "COCO",
      },
    });

    const d = Array.isArray(r.resp) ? r.resp : [];

    if (d.length > 0) {
      updateSelectedFran(d[0]);
    } else {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    CommonService.updatePageTitle("POS ERP");
    init();
  }, [init]);

  const updateSelectedFran = async (fran) => {
    setLoading(true);
    setSelectedFran(fran);
    const sellerId = fran?.sk_franchise_details?.linkedSellerId || "";
    if (sellerId) {
      const r = await SellerService.getSeller(sellerId);
      const s =
        r.resp?.sellers && r.resp?.sellers?.length > 0
          ? r.resp?.sellers[0]
          : {};
      StorageService.set("_pserpf", fran);
      StorageService.set("_pserps", s);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const modalCb = (e) => {
    if (e.action == "selected") {
      updateSelectedFran(e.data);
    }

    setShowmodal(false);
  };

  return (
    <>
      <div className={classNames(styles["pos-erp-frame"])}>
        {!loading && selectedFran?._id ? (
          <>
            <div className="p-2 d-flex justify-content-between">
              <div>
                <span>Selected Store: </span>
                <div className="d-inline-block">
                  <span className="fw-bold">
                    {selectedFran.name} ({selectedFran._id})
                  </span>
                  <span>
                    , {selectedFran.town}, {selectedFran.district} -{" "}
                    {selectedFran.pincode}
                  </span>
                </div>
              </div>
              <div>
                <button
                  onClick={() => setShowmodal(true)}
                  className="btn btn-xs btn-primary"
                >
                  CHANGE
                </button>
              </div>
            </div>
            <iframe
              src="https://uat.storeking.in/app_v5/#/pos/sales/analytics"
              className="h-100 w-100"
              name="erp-pos"
              title="POS ERP"
            ></iframe>
          </>
        ) : (
          <PageLoader />
        )}
      </div>
      <PosFranModal show={showModal} callback={modalCb} />
    </>
  );
}

export default PosMain;
