import { DateFormatter } from "@sk/uis";

const PosOrderTimeline = ({ statusLog }) => {
  return (
    <>
      <div className="activity">
        {statusLog.map((x, i) => (
          <div className="activity-info" key={i}>
            <div className="icon-info-activity">
              {x.log ? (
                <i className="bi bi-check-circle bg-soft-success" />
              ) : (
                <i className="bi bi-hourglass-split bg-soft-warning" />
              )}
            </div>
            <div className="activity-info-text">
              <div className="fs-val-md">{x.name}</div>
              <div className="text-muted d-block fs-val-sm">
                <DateFormatter date={x.log?.loggedOn} />
              </div>
              <p className="text-muted mt-3 fs-val-sm">{x.log?.remark}</p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default PosOrderTimeline;
