import { ExpenseService } from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  ImgPreviewModal,
  KeyVal,
  NoDataFound,
  PageLoader,
  TableHeader,
} from "@sk/uis";
import { useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";

const canvasStyle = {
  width: "50%",
};

const ExpenseDetailModal = ({ show, callback, expenseId }) => {
  const [loading, setLoading] = useState(true);
  const [expense, setExpense] = useState(null);
  const [showImgPreviewModal, setShowImgPreviewModal] = useState(false);
  const [imgPreviewData, setImgPreviewData] = useState([]);

  useEffect(() => {
    if (expenseId && show) {
      const loadExpense = async () => {
        setLoading(true);
        const r = await ExpenseService.getExpensesList({
          filter: {
            _id: expenseId,
          },
        });
        setExpense(Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0] : {});
        setLoading(false);
      };
      loadExpense();
    }
  }, [expenseId, show]);

  const onViewAttachment = (e) => {
    setImgPreviewData(e.attachment.map((a) => ({ id: a.assetId })));
    setShowImgPreviewModal(true);
  };

  const closeImgPreviewModal = () => {
    setImgPreviewData([]);
    setShowImgPreviewModal(false);
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={callback}
        style={canvasStyle}
        placement="end"
      >
        <Offcanvas.Header closeButton className="bg-white">
          <Offcanvas.Title>Expense Detail</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="modal-bg">
          {loading ? <PageLoader /> : null}

          {!loading && !expense?._id ? (
            <NoDataFound>No data found</NoDataFound>
          ) : null}

          {!loading && expense?._id ? (
            <>
              <AppCard title="Basic Detail">
                <KeyVal
                  label="Expense Category"
                  labelCol="col-4"
                  contentCol="col-8"
                >
                  : {expense.groupName}
                </KeyVal>
                <KeyVal
                  label="Expense Date"
                  labelCol="col-4"
                  contentCol="col-8"
                >
                  :{" "}
                  <DateFormatter
                    date={expense.expenseDate}
                    format="dd MMM yyyy"
                  />
                </KeyVal>
                <KeyVal
                  label="Total Expense"
                  labelCol="col-4"
                  contentCol="col-8"
                  noBottomSpace={true}
                >
                  :{" "}
                  <span className="text-danger fw-bold">
                    <Amount value={expense.totalExpense} />
                  </span>
                </KeyVal>
              </AppCard>

              <AppCard title={`Expense Items (${expense.expenseData?.length})`}>
                <table className="table table-sm table-hover table-striped">
                  <TableHeader data={headers} noBg />
                  <tbody className="fs-val-md">
                    {expense.expenseData?.map((e, index) => (
                      <tr key={e._id}>
                        <td>{index + 1}</td>
                        <td>{e.name}</td>
                        <td>
                          <Amount value={e.price} />
                        </td>
                        <td>{e.quantity}</td>
                        <td>
                          <Amount value={e.total} />
                        </td>
                        <td>
                          {e.attachment?.length > 0 ? (
                            <button
                              className="btn btn-link p-0 text-primary fs-val-sm"
                              onClick={() => onViewAttachment(e)}
                            >
                              <i className="bi bi-paperclip align-middle"></i>{" "}
                              {e.attachment.length}
                            </button>
                          ) : (
                            "--"
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </AppCard>
            </>
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>

      <ImgPreviewModal
        show={showImgPreviewModal}
        handleClose={closeImgPreviewModal}
        assets={imgPreviewData}
      />
    </>
  );
};

const headers = [
  { label: "#", width: "3%" },
  { label: "Item", width: "25%" },
  { label: "Price", width: "8%" },
  { label: "Qty", width: "8%" },
  { label: "Total", width: "10%" },
  { label: "", width: "3%" },
];

export default ExpenseDetailModal;
