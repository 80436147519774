import { AppCard, DateFormatter, KeyVal } from "@sk/uis";

const StoreDealSalesSummary = ({ data }) => {
  return (
    <AppCard>
      <div className="fs-val-md fw-semibold mb-2 d-flex justify-content-between">
        Sales Summary
        {data.lastOrderQty > 0 && (
          <div className="fs-val-sm">
            Last Ordered (B2C) {" - "}{" "}
            <span className="text-primary">
              <DateFormatter date={data.lastOrderDate} />
            </span>
            , {data.lastOrderQty} units
          </div>
        )}
      </div>
      <div className="row">
        <div className="col-6">
          <div className="bg-light p-2 rounded border">
            <KeyVal label="Sold in 7 days" template="col" noBottomSpace={true}>
              {data.salesData?.sevenday?._qty || 0}{" "}
              {data.sellInLooseQty ? "kg" : "units"}
            </KeyVal>
          </div>
        </div>
        <div className="col-6">
          <div className="bg-light p-2 rounded border">
            <KeyVal label="Sold in 15 days" template="col" noBottomSpace={true}>
              {data.salesData?.fifteenday?._qty || 0}{" "}
              {data.sellInLooseQty ? "kg" : "units"}
            </KeyVal>
          </div>
        </div>
        <div className="col-6 mt-3">
          <div className="bg-light p-2 rounded border">
            <KeyVal label="Sold in 30 days" template="col" noBottomSpace={true}>
              {data.salesData?.thirtyday?._qty || 0}{" "}
              {data.sellInLooseQty ? "kg" : "units"}
            </KeyVal>
          </div>
        </div>
        <div className="col-6 mt-3">
          <div className="bg-light p-2 rounded border">
            <KeyVal label="Sold in 90 days" template="col" noBottomSpace={true}>
              {data.salesData?.ninetyday?._qty || 0}{" "}
              {data.sellInLooseQty ? "kg" : "units"}
            </KeyVal>
          </div>
        </div>
      </div>
    </AppCard>
  );
};

export default StoreDealSalesSummary;
