import { AppCard, DateInput, SelectInput, TextInput } from "@sk/uis";
import { format, startOfMonth } from "date-fns";
import { debounce } from "lodash";
import { useCallback } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";

const endDate = new Date();
const startDate = startOfMonth(endDate);

const statusOptions = [
  { label: "All Status", value: "" },
  {
    label: "Deposit Pending",
    value: "Created",
    badge: "warning",
  },
  {
    label: "Approval Pending",
    value: "PendingApproval",
    badge: "secondary",
  },
  {
    label: "Rejected",
    value: "ProofPending",
    badge: "danger",
  },
  {
    label: "Approved",
    value: "Deposited",
    badge: "success",
  },
];

const paymentModeOptions = [
  { label: "All Payment Modes", value: "" },
  { label: "Cash", value: "CASH" },
  { label: "UPI", value: "UPI" },
];

const defaultFormData = {
  search: "",
  dateRange: [format(startDate, "yyyy-MM-dd"), format(endDate, "yyyy-MM-dd")],
  status: "",
  paymentMode: "",
};

const dtConfig = {
  mode: "range",
  maxDate: new Date(),
};

const PosCashDepositsFilter = ({ callback }) => {
  const { control, register, getValues, setValue } = useForm({
    defaultValues: {
      ...defaultFormData,
    },
  });

  const [type] = useWatch({
    control,
    name: ["type"],
  });

  const onInpChange = (chngFn) => (val) => {
    chngFn(val);
    triggerCb();
  };

  const onStatusChange = () => {
    triggerCb();
  };

  const triggerCb = () => {
    let f = getValues();
    callback({ formData: { ...f } });
  };

  const onSearchChange = useCallback(
    debounce(() => {
      triggerCb();
    }, 500),
    [triggerCb]
  );

  return (
    <>
      <AppCard>
        <div className="row">
          <div className="col-md-3">
            <Controller
              name="dateRange"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DateInput
                  placeholder="Select start date"
                  value={value}
                  callback={onInpChange(onChange)}
                  config={dtConfig}
                  hideClose={true}
                  gap={0}
                  template={2}
                  disabled={type === "Pending"}
                />
              )}
            />
          </div>
          <div className="col-md-3">
            <TextInput
              name="search"
              placeholder="Search by Reference ID"
              register={register}
              gap={0}
              callback={onSearchChange}
            />
          </div>
          <div className="col-md-3">
            <SelectInput
              name="status"
              options={statusOptions}
              callback={onStatusChange}
              gap={0}
              register={register}
            />
          </div>
          <div className="col-md-3">
            <SelectInput
              name="paymentMode"
              options={paymentModeOptions}
              callback={onStatusChange}
              gap={0}
              register={register}
            />
          </div>
        </div>
      </AppCard>
    </>
  );
};

export default PosCashDepositsFilter;
