import { NavService } from "@sk/services";
import { KeyVal } from "@sk/uis";

const ClubReqStore = ({ storeInfo }) => {
  return (
    <>
      <KeyVal label="Store Name" labelCol="col-4" contentCol="col-8">
        <span className="d-flex">
          <span className="me-1">:</span>
          <button
            className="btn btn-link p-0 fs-val-md text-dark text-start"
            onClick={() => {
              NavService.openInNewTab(
                "/pos/sales-analytics?fid=" + storeInfo.id
              );
            }}
          >
            {storeInfo.name}
          </button>
        </span>
      </KeyVal>
      <KeyVal label="Location" labelCol="col-4" contentCol="col-8">
        <span className="d-flex">
          <span className="me-1">:</span>
          <span>
            {storeInfo.town}, {storeInfo.district}, {storeInfo.state} -{" "}
            {storeInfo.pincode}
          </span>
        </span>
      </KeyVal>
    </>
  );
};

export default ClubReqStore;
