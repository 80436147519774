import { PosService } from "@sk/services";
import { SummaryCard } from "@sk/uis";
import produce from "immer"; // Import produce from immer
import { useEffect, useState } from "react";

const PosStoreSummary = ({ storeId }) => {
  // Default summary data with loading key
  const [summaryData, setSummaryData] = useState([
    {
      title: "Total In Stock SKU",
      value: 0,
      icon: "bi-box",
      loading: true,
      valueColor: "success",
      display: true,
      dataKey: "totalPids",
    },
    {
      title: "Total Stock in Units",
      value: 0,
      icon: "bi-box",
      loading: true,
      valueColor: "success",
      display: true,
      dataKey: "totalNonLooseInventoryQty",
    },
    {
      title: "Total Loose Units",
      value: 0,
      icon: "bi-box",
      loading: true,
      valueColor: "danger",
      display: true,
      dataKey: "totalLooseInventoryQty",
    },
    {
      title: "Inventory Value",
      value: 0,
      icon: "bi-cash",
      loading: true,
      valueColor: "success",
      display: true,
      dataKey: "totalInventoryValue",
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setSummaryData(
          produce((draft) => {
            draft.forEach((item) => (item.loading = true));
          })
        );

        const r = await PosService.getInventorySummary({
          franchiseId: storeId,
        });
        const d =
          Array.isArray(r.resp) && r.resp.length > 0 ? r.resp[0] : r.resp;

        setSummaryData(
          produce((draft) => {
            draft.forEach((item) => {
              if (item.dataKey === "totalLooseInventoryQty") {
                item.value = `${(d[item.dataKey] / 1000).toFixed(2)} kg`;
              } else {
                item.value = d[item.dataKey];
              }
              item.loading = false;
            });
          })
        );
      } catch (error) {
        console.error("Error fetching summary data:", error);
      }
    };

    fetchData();
  }, [storeId]);

  return (
    <div className="row">
      {summaryData.map(
        (data, index) =>
          data.display && (
            <div className="col-auto" key={index}>
              <SummaryCard
                title={data.title}
                value={data.value}
                loading={data.loading}
                icon={data.icon}
                template={3}
                isValueAmt={index === 3}
                noValParse={index === 2}
                valueColor={data.valueColor}
                amtDecimalPlace={0}
              />
            </div>
          )
      )}
    </div>
  );
};

export default PosStoreSummary;
