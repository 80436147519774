import { PosService } from "@sk/services";
import {
  Amount,
  AppCard,
  DateFormatter,
  NoDataFound,
  Pagination,
  PaginationSummary,
  TableHeader,
  TableSkeletonLoader,
} from "@sk/uis";
import produce from "immer";
import { useCallback, useEffect, useRef, useState } from "react";
import PosEmployeeSettlementFilter from "./PosEmployeeSettlementFilter";

const defaultPaginationConfig = {
  totalRecords: 0,
  rowsPerPage: 15,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 15,
};

const headers = [
  { label: "Sl No", width: "5%" },
  { label: "Employee Name", width: "30%" },
  { label: "Amount", width: "15%" },
  { label: "Settlement Date", width: "30%" },
];

const defaultSummaryCards = [
  {
    title: "Settlement Pending",
    value: 0,
    isValueAmt: true,
    valueColor: "danger",
    icon: "bi bi-currency-rupee",
    loading: true,
    type: "SettlementPending",
  },
  {
    title: "Settled Amount",
    value: 0,
    isValueAmt: true,
    template: 1,
    valueColor: "success",
    icon: "bi bi-currency-rupee",
    loading: true,
    type: "Settled",
  },
  {
    title: "Total Employees",
    value: 0,
    template: 1,
    icon: "bi bi-person",
    loading: true,
  },
];

const getData = async (params) => {
  const r = await PosService.getEmployeeSettlement(params);
  return { data: Array.isArray(r.resp) ? r.resp : [] };
};

const getCount = async (params) => {
  const p = { ...params };
  p.outputType = "count";
  delete p.count;
  delete p.page;
  delete p.sort;

  const r = await PosService.getEmployeeSettlement(p);
  return { count: r.statusCode === 200 && r.resp[0] ? r.resp[0].total : 0 };
};

const getPendingSettlement = async (params) => {
  let p = { ...params };
  p.filter.status = "SettlementPending";
  p.groupbycond = "status";

  delete p.count;
  delete p.page;
  delete p.sort;

  const r = await PosService.getEmployeeSettlement(p);
  return { amount: r.statusCode === 200 && r.resp[0] ? r.resp[0].totalAmt : 0 };
};

const getSettledAmount = async (params) => {
  let p = { ...params };
  p.filter.status = "Settled";
  p.groupbycond = "status";

  delete p.count;
  delete p.page;
  delete p.sort;

  const r = await PosService.getPosSettlement(p);
  return { amount: r.statusCode === 200 && r.resp[0] ? r.resp[0].totalAmt : 0 };
};

const prepareParams = (filter, pagination) => {
  let p = {
    page: pagination.activePage,
    count: pagination.rowsPerPage,
    filter: {
      status: filter.status,
    },
  };

  const search = filter.search?.trim();
  if (search) {
    p.filter.name = { $regex: search, $options: "i" };
  }

  return p;
};

const PosEmployeeSettlement = () => {
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [records, setRecords] = useState({ loading: true, value: 0 });
  const [status, setStatus] = useState("SettlementPending");
  const [summaryCards, setSummaryCards] = useState([...defaultSummaryCards]);

  const filterDataRef = useRef({
    status: "SettlementPending",
    search: "",
  });
  const paginationRef = useRef({ ...defaultPaginationConfig });

  const loadSummaryCards = useCallback(async () => {
    const promises = [
      getPendingSettlement(
        prepareParams(
          { ...filterDataRef.current, status: "SettlementPending" },
          paginationRef.current
        )
      ),
      getSettledAmount(
        prepareParams(
          { ...filterDataRef.current, status: "Settled" },
          paginationRef.current
        )
      ),
      getCount(prepareParams(filterDataRef.current, paginationRef.current)),
    ];

    setSummaryCards(
      produce((draft) => {
        draft.forEach((x) => {
          x.loading = true;
        });
      })
    );

    const [pendingSettlement, settledAmount, totalCount] = await Promise.all(
      promises
    );

    setSummaryCards(
      produce((draft) => {
        draft[0].loading = false;
        draft[1].loading = false;
        draft[2].loading = false;

        draft[0].value = pendingSettlement.amount;
        draft[1].value = settledAmount.amount;
        draft[2].value = totalCount.count;
      })
    );
  }, []);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    setData([]);

    const r = await getData(
      prepareParams(filterDataRef.current, paginationRef.current)
    );
    const d = r.data || [];
    setData(d);
    setLoadingData(false);
  }, []);

  const applyFilter = useCallback(async () => {
    paginationRef.current = { ...defaultPaginationConfig };
    loadList();
    loadSummaryCards();

    setRecords({ loading: true, value: 0 });
    const r = await getCount(
      prepareParams(filterDataRef.current, paginationRef.current)
    );
    setRecords({ loading: false, value: r.count });
  }, [loadList, loadSummaryCards]);

  useEffect(() => {
    applyFilter();
  }, [applyFilter]);

  const paginationCb = (data) => {
    paginationRef.current.startSlNo = data.startSlNo;
    paginationRef.current.endSlNo = data.endSlNo;
    paginationRef.current.activePage = data.activePage;
    loadList();
  };

  const onFilterCb = (data) => {
    filterDataRef.current = {
      ...filterDataRef.current,
      ...data.formData,
    };
    setStatus(data.formData.status);
    applyFilter();
  };

  return (
    <>
      <PosEmployeeSettlementFilter callback={onFilterCb} />
      <AppCard>
        <PaginationSummary
          loadingTotalRecords={records.loading}
          paginationConfig={paginationRef.current}
          className="mb-1"
        />
        <table className="table bg-white table-striped table-sm table-hover">
          <TableHeader data={headers} noBg={true} />
          <tbody className="fs-val-md">
            {!loadingData && !data.length ? (
              <tr>
                <td colSpan={headers.length}>
                  <NoDataFound>No Data found</NoDataFound>
                </td>
              </tr>
            ) : null}

            {loadingData ? (
              <TableSkeletonLoader
                cols={headers.length}
                rows={10}
                height={40}
              />
            ) : null}

            {data.map((item, index) => (
              <tr key={item._id} className="fs-val-md">
                <td>{paginationRef.current.startSlNo + index}</td>
                <td>{item.name}</td>
                <td
                  className={
                    status === "SettlementPending"
                      ? "text-danger"
                      : "text-success"
                  }
                >
                  <Amount value={item.amount || 0} decimal={2} />
                </td>
                <td>
                  {status === "SettlementPending" ? (
                    "Yet to settle"
                  ) : (
                    <DateFormatter date={item.settlementDate} />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="text-end">
          <Pagination
            callback={paginationCb}
            activePage={paginationRef.current.activePage}
            rowsPerPage={paginationRef.current.rowsPerPage}
            totalRecords={records.value}
          />
        </div>
      </AppCard>
    </>
  );
};

export default PosEmployeeSettlement;
