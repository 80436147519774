import { PosService } from "@sk/services";
import { SummaryCard } from "@sk/uis";
import { endOfDay, startOfDay } from "date-fns";
import { useEffect, useRef, useState } from "react";

const headers = [
  { label: "Name", key: "name", width: "50%" },
  { label: "Value", key: "value", width: "50%" },
];

const prepareFilterParams = (filter) => {
  return {
    filter: {
      $or: [
        { "franchiseInfo.id": filter.storeId },
        { franchise: filter.storeId },
      ],
      createdAt: {
        $gte: startOfDay(new Date(filter.fromDate)), // Start of day
        $lte: endOfDay(new Date(filter.toDate)), // End of day
      },
    },
    sort: { value: -1 },
    groupbycond: "amountReceivedVia",
    groupbycondName: "amountReceivedVia",
  };
};

// Function to get data from the API
const getData = async (params) => {
  const response = await PosService.getPosTopProducts(params);
  return Array.isArray(response.resp) ? response.resp : [];
};

// Mapping of payment methods to their corresponding colors
const valueColorMap = {
  Cash: "success",
  Gpay: "primary",
  Paytm: "warning",
  PhonePe: "info",
  Card: "danger",
  UPI: "secondary",
};

const PosAnalyticsPayment = ({ storeId, fromDate, toDate }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // State for loading
  const filterDataRef = useRef({ storeId, fromDate, toDate });

  useEffect(() => {
    filterDataRef.current.storeId = storeId;
    filterDataRef.current.fromDate = fromDate;
    filterDataRef.current.toDate = toDate;

    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      const filterParams = prepareFilterParams(filterDataRef.current);
      const result = await getData(filterParams);
      const apiData = result;

      // Default payment methods
      const defaultMethods = [
        "Cash",
        "Gpay",
        "Paytm",
        "PhonePe",
        "Card",
        "UPI",
      ];

      // Map API response to include default methods
      const mappedData = defaultMethods.map((method) => {
        const totalValue = apiData
          .filter((item) => item.name.toLowerCase() === method.toLowerCase())
          .reduce((sum, item) => sum + item.value, 0); // Sum up values for the same name
        return {
          name: method.toUpperCase(),
          value: Math.round(totalValue), // Round off the summed value
          valueColor: valueColorMap[method] || "default", // Get color from map
        };
      });

      setData(mappedData);
      setLoading(false); // Set loading to false after fetching
    };

    if (storeId && fromDate && toDate) {
      fetchData();
    }
  }, [storeId, fromDate, toDate]);

  return (
    <div className="row">
      <div className="col-12 fs-val-md fw-semibold mb-1">
        Payment Mode Summary
      </div>
      {data.map((item, index) => (
        <div className="col" key={index}>
          <SummaryCard
            title={item.name}
            value={item.value}
            isValueAmt={true}
            template={3}
            valueColor={item.valueColor} // Use dynamic valueColor
            icon="bi bi-cash"
          />
        </div>
      ))}
    </div>
  );
};

export default PosAnalyticsPayment;
