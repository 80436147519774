import { OmsService } from "@sk/services";
import { AppCard } from "@sk/uis";
import { set } from "date-fns";
import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// import OrderFilter from "../../orders/list/components/OrderFilter";
// import OrderSummary from "../../orders/list/components/OrderSummary";
// import OrderTable from "../../orders/list/components/OrderTable";
import { useFetchUrlQueryString } from "@sk/hooks";
import StoreDealPurchaseFilter from "./StoreDealPurchaseFilter";
import StoreDealPurchaseTable from "./StoreDealPurchaseTable";

const defaultFilter = {};
const statusOptions = OmsService.getOmsOrderStatus();

const StoreDealPurchases = ({ dealId, storeId }) => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const queryParams = useFetchUrlQueryString(searchParams);

  const [orders, setOrders] = useState([]);

  const [loadingData, setLoadingData] = useState(true);
  const [loadingTotalRecords, setLoadingTotalRecords] = useState(true);

  const [summaryData, setSummaryData] = useState([
    { label: "Total", value: 0 },
    ...statusOptions
      .filter((option) => option.value !== "")
      .map((option) => ({ ...option, value: 0 })),
  ]);

  const filterRef = useRef({
    ...{},
  });
  const paginationRef = useRef({ ...defaultPagination });

  const loadSummary = useCallback(async () => {
    const fetchSummaryData = async (params, label, color) => {
      const countData = await getCount(params);
      return {
        label,
        value:
          countData.statusCode === 200 && countData.resp ? countData.resp : 0,
        color,
      };
    };

    const baseParams = {
      ...getFilterParams(filterRef.current, paginationRef.current),
    };
    delete baseParams.filter.status;
    delete baseParams.sort;

    const summaryPromises = [
      fetchSummaryData(baseParams, "Total", "primary"),
      ...statusOptions
        .filter((option) => option.value !== "")
        .map((option) => {
          const params = {
            ...getFilterParams(filterRef.current, paginationRef.current),
            filter: { ...baseParams.filter, status: option.value },
          };
          delete params.sort;
          return fetchSummaryData(params, option.label, option.color);
        }),
    ];

    const summaryResults = await Promise.all(summaryPromises);
    setSummaryData(summaryResults);
  }, []);

  const applyFilter = useCallback(async () => {
    paginationRef.current = {
      ...defaultPagination,
    };
    loadList();
    loadSummary();

    // for total records
    setLoadingTotalRecords(true);
    const c = await getCount(
      getFilterParams(filterRef.current, paginationRef.current)
    );
    const t = c.statusCode == 200 && c.resp ? c.resp : 0;
    paginationRef.current.totalRecords = t;
    setLoadingTotalRecords(false);
  }, [loadList, loadSummary]);

  const init = useCallback(async () => {
    applyFilter();
  }, [applyFilter]);

  useEffect(() => {
    filterRef.current = {
      ...defaultFilter,
      franchise: storeId,
      dealId,
      storeId,
    };
    init();
  }, [dealId, init, storeId]);

  const loadList = useCallback(async () => {
    setLoadingData(true);
    setOrders([]);
    const r = await getData(
      getFilterParams(filterRef.current, paginationRef.current)
    );
    const d = r.resp || [];
    setOrders(d);
    setLoadingData(false);
  }, []);

  const paginationCb = useCallback(
    (data) => {
      paginationRef.current.startSlNo = data.startSlNo;
      paginationRef.current.endSlNo = data.endSlNo;
      paginationRef.current.activePage = data.activePage;
      loadList();
    },
    [loadList]
  );

  const filterCb = useCallback(
    (filter) => {
      filterRef.current = {
        ...filterRef.current,
        ...filter.formData,
      };
      applyFilter();
    },
    [applyFilter]
  );

  return (
    <>
      <AppCard>
        <StoreDealPurchaseFilter
          callback={filterCb}
          view={queryParams.fid ? "franchise" : "order"}
        />
      </AppCard>

      {/* {summaryData.length > 0 && <OrderSummary data={summaryData} />} */}
      <AppCard>
        <StoreDealPurchaseTable
          data={orders}
          loading={loadingData}
          paginationConfig={paginationRef.current}
          paginationCb={paginationCb}
          loadingTotalRecords={loadingTotalRecords}
        />
      </AppCard>
    </>
  );
};

const defaultPagination = {
  totalRecords: 0,
  rowsPerPage: 50,
  activePage: 1,
  startSlNo: 1,
  endSlNo: 50,
};

const getData = (params) => {
  return OmsService.getOrderList(params);
};

const getCount = (params) => {
  delete params.page;
  delete params.count;
  return OmsService.getOrderCount(params);
};

const getFilterParams = (filter, pagination) => {
  let p = {
    page: pagination?.activePage,
    count: pagination?.rowsPerPage,
    filter: {
      "subOrders.id": filter.dealId,
      "franchise.id": filter.franchise,
    },
    sort: { createdAt: -1 },
  };

  if (filter?.orderedOn?.[0] && filter?.orderedOn?.[1]) {
    p.filter.createdAt = {};
    p.filter.createdAt.$gte = set(new Date(filter.orderedOn[0]), {
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
    p.filter.createdAt.$lte = set(new Date(filter.orderedOn[1]), {
      hours: 23,
      minutes: 59,
      seconds: 59,
    });
  }

  if (filter?.status) {
    p.filter.status = filter.status;
  }

  if (filter?.openSearch) {
    p.filter._id = filter.openSearch;
  }

  if (filter?.paymentStatus) {
    p.filter.paymentStatus = filter.paymentStatus;
  }

  if (filter?.showOnlyNoStock) {
    p.filter.stockAllocation = {
      $in: ["PartiallyAllocated", "NotAllocated"],
    };
  }

  return p;
};

export default StoreDealPurchases;
